export const INVALID_INDEX = -1;
export const FIRST_INDEX = 0;
export const EMPTY_LIST: any[] = [];

export class ArrayUtil {
  static arraysMatch(list1: any[], list2: any[]): boolean {
    return list1.length === list2.length
      && ArrayUtil.contains(list1, list2);
  }

  static contains(list, elements): boolean {
    return elements.every((value) => list.includes(value));
  }

  static equals<T>(array1: T[], array2: T[]): boolean {
    return array1.length === array2.length
      && array1.every((value) => array2.includes(value));
  }

  static removeByIndex<T>(array: T[], index: number): T[] {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  }
}

export function getOrDefault<T>(value: T, defaultValue: T): T {
  return value !== null && value !== undefined
    ? value
    : defaultValue;
}

export function getById(list: { id: string }[], id: string): any {
  return list.find((item: { id: string }): boolean => item.id === id);
}

export function uniqueBy<T, O>(
  extractProperty: (value: T) => O,
): (value: T, index: number, array: T[]) => boolean {
  return (value, index, array) => unique(
    extractProperty(value),
    index,
    array.map(extractProperty),
  );
}

export function unique<T>(value: T, index: number, array: T[]): boolean {
  return array.indexOf(value) === index;
}
