import { NgModule } from '@angular/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { LongNameComponent } from './long-name.component';

@NgModule({
  declarations: [
    LongNameComponent,
  ],
  imports: [
    NzToolTipModule,
    CommonModule,

  ],
  exports: [
    LongNameComponent,
  ],
})
export class LongNameModule {}
