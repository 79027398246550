import { NzModalService } from 'ng-zorro-antd/modal';
import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class LeaveSettingsConfirmationModal {
  constructor(
    private modal: NzModalService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  show(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.modal.confirm({
        nzTitle: this.translationService.t('settings.leave_settings_confirmation_modal.question'),
        nzContent: this.translationService.t('settings.leave_settings_confirmation_modal.warning'),
        nzOkText: this.translationService.t('settings.leave_settings_confirmation_modal.leave_without_saving'),
        nzCancelText: this.translationService.t('cancel'),

        nzOkType: 'primary',
        nzClosable: false,
        nzCentered: true,
        nzOkDanger: true,
        nzIconType: 'exclamation-circle',
        nzWidth: '417px',

        nzOnOk: () => proceedDeactivateEvent(subscriber),
        nzOnCancel: () => preventDeactivateEvent(subscriber),
      });
    });
  }
}

function proceedDeactivateEvent(subscriber: Subscriber<boolean>): void {
  subscriber.next(true);
  subscriber.complete();
}

function preventDeactivateEvent(subscriber: Subscriber<boolean>): void {
  subscriber.next(false);
  subscriber.complete();
}
