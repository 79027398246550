import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'htmlToPlaintext',
})
export class HtmlToPlaintextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): string {
    const tempDiv = document.createElement('div');

    tempDiv.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, html) || '';

    return tempDiv.textContent || tempDiv.innerText || '';
  }
}
