import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { SentryService } from './sentry.service';

@Injectable()
export class SentryGraphqlInterceptor implements HttpInterceptor {
  private graphQlEndpoint = '/graphql';

  constructor(private sentryService: SentryService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes(this.graphQlEndpoint)) {
      this.sentryService.info(`GraphQl request = ${request.body.operationName}`);
    }

    return next.handle(request);
  }
}
