import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CommonModule } from '@angular/common';
import { InfoMessageCardComponent } from './info-message-card.component';

@NgModule({
  declarations: [
    InfoMessageCardComponent,
  ],
  imports: [
    NzIconModule,
    NzButtonModule,
    CommonModule,
  ],
  exports: [
    InfoMessageCardComponent,
  ],
  providers: [],
})
export class InfoMessageCardModule {
}
