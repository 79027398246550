import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  ConnectSelectedWidgetToShopifyGQL,
  ConnectToShopifyGQL,
  CreateShopifySubscriptionGQL,
  CreateWidgetWithShopifyConnectionGQL,
  DisconnectFromShopifyGQL,
  ShopifyConnectionInput,
  ShopifyStore,
  ShopifyStoresGQL,
  ShopifySubscriptionCreation,
  ShopifySubscription,
  ShopifySubscriptionGQL,
  RenewShopifySubscriptionGQL,
  AllChannelsDocument,
  AllChannelsQuery, UpdateAdditionalOperatorSeatsUsingShopifyGQL,
  ShopifyAdditionalOperatorSeatsOutput, AdditionalOperatorSeatsInput,
} from '../graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class ShopifyGqlService {
  constructor(
      private connectToShopifyGQL: ConnectToShopifyGQL,
      private disconnectFromShopifyGQL: DisconnectFromShopifyGQL,
      private connectSelectedWidgetToShopifyGQL:
        ConnectSelectedWidgetToShopifyGQL,
      private createWidgetWithShopifyConnectionGQL:
        CreateWidgetWithShopifyConnectionGQL,
      private shopifyStoresGQL: ShopifyStoresGQL,
      private createShopifySubscriptionGQL: CreateShopifySubscriptionGQL,
      private renewShopifySubscriptionGQL: RenewShopifySubscriptionGQL,
      private shopifySubscriptionGQL: ShopifySubscriptionGQL,
      private updateAdditionalOperatorSeatsUsingShopifyGQL:
        UpdateAdditionalOperatorSeatsUsingShopifyGQL,
  ) {}

  public connectToShopify(input: ShopifyConnectionInput): Observable<string> {
    return this.connectToShopifyGQL.mutate(
      {
        input,
      },
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((value) => value.data.connectToShopify as string),
    );
  }

  public connectSelectedWidgetToShopify(
    channelId: string,
  ): Observable<string> {
    return this.connectSelectedWidgetToShopifyGQL.mutate(
      {
        channelId,
      },
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((value) => value.data.connectSelectedWidgetToShopify as string),
    );
  }

  public createWidgetWithShopifyConnection(): Observable<string> {
    return this.createWidgetWithShopifyConnectionGQL.mutate(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((value) => value.data.createWidgetWithShopifyConnection as string),
    );
  }

  disconnectFromShopify(channelId: string): Observable<boolean> {
    return this.disconnectFromShopifyGQL.mutate(
      {
        channelId,
      },
      {
        fetchPolicy: 'network-only',
        update: (cache) => {
          cache.updateQuery({
            query: AllChannelsDocument,
          },
          (data: AllChannelsQuery) => deleteShopifyStoreFromCache(
            data,
            channelId,
          ));
        },
      },
    ).pipe(map((value) => value.data.disconnectFromShopify as boolean));
  }

  getShopifyStores(): Observable<ShopifyStore[]> {
    return this.shopifyStoresGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((result) => result.data.shopifyStores as ShopifyStore[]),
      shareReplay(),
    );
  }

  createShopifySubscription(
    subscriptionId: string,
    shopifyStoreId: string,
  ): Observable<ShopifySubscriptionCreation> {
    return this.createShopifySubscriptionGQL.mutate(
      {
        subscriptionId,
        shopifyStoreId,
      },
    ).pipe(
      map((value) => value.data
        .createShopifySubscription as ShopifySubscriptionCreation),
    );
  }

  renewShopifySubscription(
    shopifyStoreId: string,
  ): Observable<ShopifySubscriptionCreation> {
    return this.renewShopifySubscriptionGQL.mutate(
      {
        shopifyStoreId,
      },
    ).pipe(
      map((value) => value.data
        .renewShopifySubscription as ShopifySubscriptionCreation),
    );
  }

  getShopifySubscription(): Observable<ShopifySubscription> {
    return this.shopifySubscriptionGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((value) => value.data.shopifySubscription as ShopifySubscription),
    );
  }

  updateAdditionalOperatorSeatsUsingShopify(
    input: AdditionalOperatorSeatsInput,
  ): Observable<ShopifyAdditionalOperatorSeatsOutput> {
    return this.updateAdditionalOperatorSeatsUsingShopifyGQL.mutate(
      {
        input,
      },
    ).pipe(
      map((value) => value.data
        // eslint-disable-next-line max-len
        .updateAdditionalOperatorSeatsUsingShopify as ShopifyAdditionalOperatorSeatsOutput),
    );
  }
}

function deleteShopifyStoreFromCache(
  data: AllChannelsQuery,
  channelId: string,
): AllChannelsQuery {
  const channelToUpdate = data.channels.find((c) => c.id === channelId);
  const updatedChannel = {
    ...channelToUpdate,
    widgetProperty: {
      ...channelToUpdate.widgetProperty,
      shopifyStore: null,
    },
  };

  return {
    channels: [
      ...data.channels.filter((c) => c.id !== channelId),
      updatedChannel,
    ],
  };
}
