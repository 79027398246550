<nz-spin class="spinner" [nzSize]="'large'" nzSimple *ngIf="loading"></nz-spin>
<p>
  {{ 'settings.billing.delete_payment_method_modal.details' | i18next }}
</p>
<span nz-typography class="error_msg" [hidden]="hideErrorMessage" nzType="danger">{{ errorMessage }}</span>
<div *nzModalFooter>
  <button nz-button nzType="default" (click)="onNoClick()">
    {{ 'settings.billing.delete_payment_method_modal.cancel' | i18next }}
  </button>
  <button nz-button nzType="primary" nzDanger (click)="onYesClick()">
    {{ 'settings.billing.delete_payment_method_modal.confirm_btn' | i18next }}
  </button>
</div>
