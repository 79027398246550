import { NgModule } from '@angular/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { LongTextComponent } from './long-text.component';

@NgModule({
  declarations: [
    LongTextComponent,
  ],
  imports: [
    NzToolTipModule,
    CommonModule,
  ],
  exports: [
    LongTextComponent,
  ],
})
export class LongTextModule {}
