<div
  class="container"
  *ngIf="isOpen$ | async"
  @openCloseAnimation
>
  <div class="content">
    <div class="focusout site-bar">
      <app-attachment-view-button
        class="interface-element"
        [disabled]="isLastFromLeft() | async"
        (click)="moveToPrevious()"
      >
        <img src="assets/images/icons/attachment/prev.svg" alt="prev">
      </app-attachment-view-button>
    </div>

    <div class="focusout view" *ngIf="file$ | async as file">
      <img
        class="view__attachment"
        *ngIf="isImageFile(file)"
        appAttachmentViewMovable
        appAttachmentViewModifiable
        [modification]="modification$ | async"
        (positionChanges)="updatePosition($event)"
        [src]="file.fileUrl"
        [alt]="file.fileName"
        (dblclick)="zoomIn()"
      />

      <app-video-player
        class="view__attachment"
        *ngIf="isVideoFile(file)"
        [file]="file"
        appAttachmentViewMovable
        appAttachmentViewModifiable
        [modification]="modification$ | async"
        (positionChanges)="updatePosition($event)"
      ></app-video-player>
    </div>

    <div class="focusout site-bar">
      <app-attachment-view-button
        class="site-bar__close-button interface-element"
        (click)="close()"
      >
        <img src="assets/images/icons/attachment/close.svg" alt="close">
      </app-attachment-view-button>

      <app-attachment-view-button
        class="interface-element"
        [disabled]="isLastFromRight() | async"
        (click)="moveToNext()"
      >
        <img src="assets/images/icons/attachment/next.svg" alt="next">
      </app-attachment-view-button>
    </div>
  </div>

  <div class="focusout footer">
    <div class="attachment-preview interface-element" appAdaptive="attachment-preview">
      <div *ngFor="let file of files$ | async">
        <img
          class="attachment-preview__attachment"
          *ngIf="isImageFile(file)"
          [class.attachment-preview__attachment--selected]="isSelected(file) | async"
          [src]="file.fileUrl"
          [alt]="file.fileName"
          (click)="select(file)"
        />

        <video
          class="attachment-preview__attachment"
          *ngIf="isVideoFile(file)"
          [class.attachment-preview__attachment--selected]="isSelected(file) | async"
          [src]="file.fileUrl"
          (click)="select(file)"
        ></video>
      </div>
    </div>

    <div class="utility-bar interface-element" appAdaptive="utility-bar">
      <div
        class="utility-bar__button"
        *ngFor="let button of utilityButtons"
        (click)="button.handleClick()"
      >
        <img src="assets/images/icons/attachment/util/{{button.iconName}}.svg" [alt]="button.iconName">
      </div>
    </div>
  </div>
</div>
