import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { LocalStorageService } from '@app/local-storage-service';
import { differenceInCalendarDays } from '@app/util/date.util';

const PATTERN = {
  date: 'dd.MM.yy',
  weekDay: 'EE',
  time: 'HH:mm',
};

const DAYS_DIFFERENCE = {
  today: 0,
  weekAgo: 7,
};

@Pipe({ name: 'dateTime' })
export class DateTimePipe implements PipeTransform {
  private readonly locale: string
    = this.localStorageService.getLanguage();

  constructor(
    private localStorageService: LocalStorageService,
  ) {
  }

  transform(date: Date): string | any {
    const thatDate: Date = new Date(date);
    const differenceInDays: number
      = differenceInCalendarDays(thatDate, new Date());

    if (differenceInDays === DAYS_DIFFERENCE.today) {
      return formatDate(date, PATTERN.time, this.locale);
    }

    if (differenceInDays <= DAYS_DIFFERENCE.weekAgo) {
      return formatDate(date, PATTERN.weekDay, this.locale);
    }

    return formatDate(date, PATTERN.date, this.locale);
  }
}
