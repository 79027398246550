import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService } from '@app/local-storage-service';
import { exists } from '@app/util/object.util';

const REFERRAL_CODE_QUERY_PARAM = 'ref';

@Injectable({
  providedIn: 'root',
})
export class ReferralCodeDetectionService {
  private referralCode: string;

  constructor(
    private localStorageService: LocalStorageService,
  ) {
  }

  registerIfExist(routeSnapshot: ActivatedRouteSnapshot): void {
    const referralCode: string
      = routeSnapshot.queryParams[REFERRAL_CODE_QUERY_PARAM];

    if (referralCode) {
      this.referralCode = referralCode;
      this.localStorageService.set(REFERRAL_CODE_QUERY_PARAM, referralCode);
    }
  }

  has(): boolean {
    return exists(this.get());
  }

  get(): string {
    return this.referralCode
      || this.localStorageService.get(REFERRAL_CODE_QUERY_PARAM);
  }
}
