import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  GetMessengerProvidersGQL, MessengerProvider,
  MessengerProviderFragment, MessengerProviderName,
  MessengerType,
} from './graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class MessengerGqlService {
  constructor(
    private getMessengerProvidersGQL: GetMessengerProvidersGQL,
  ) {
  }

  getMessengerProviders(
    messengerType: MessengerType,
  ): Observable<MessengerProviderFragment[]> {
    return this.getMessengerProvidersGQL
      .watch({ messengerType })
      .valueChanges
      .pipe(
        map((res) => res.data.messengerProviders),
      );
  }

  getMessengerProviderByNameAndMessengerType(
    messengerProviderName: MessengerProviderName,
    messengerType: MessengerType,
  ): Observable<MessengerProvider> {
    return this.getMessengerProviders(messengerType)
      .pipe(
        map(
          (providers) => providers.find(
            (provider) => provider.name === messengerProviderName,
          ),
        ),
      );
  }
}
