export class PaymentMethodInfo {
  title: string;

  details: string;

  iconPath: string;

  iconAlt?: string;

  constructor(
    title: string,
    details: string,
    iconPath: string,
    iconAlt?: string,
  ) {
    this.title = title;
    this.details = details;
    this.iconPath = iconPath;
    this.iconAlt = iconAlt;
  }
}
