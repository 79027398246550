import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SideWidgetService } from '@app/side-widget/side-widget.service';
import { Tab } from '@app/graphql/graphql';
import {
  ALL_TAB_LINK,
} from '@app/conversation-page/chat-details/breakpoints/common/chat-link-copy-button/chat-link-copy-button.component';
import { ROUTE_URL } from './router-url.constants';
import { RouterService } from './router.service';

const USER_REQUEST_ID_REGEX = /\/chats\/all\/\d+\/request\/(\d+)/;
const USER_REQUEST_ID_INDEX = 1;

@Injectable({ providedIn: 'root' })
export class PageRouteService {
  private readonly rootUrl = '/';

  private readonly tabUrlMap: Map<Tab, string> = new Map([
    [Tab.All, ROUTE_URL.all],
    [Tab.New, ROUTE_URL.inbox],
    [Tab.MyTasks, ROUTE_URL.dialog],
  ]);

  constructor(
    private router: Router,
    private routerService: RouterService,
    private sideWidgetService: SideWidgetService,
  ) {}

  moveToPaymentPage(transactionId: string): void {
    this.moveTo(`${ROUTE_URL.payment}/${transactionId}`);
  }

  isCurrentChatPage(chatId: number | string): boolean {
    return this.router.url.includes(`${chatId}`);
  }

  moveToCurrentChatsPage(): void {
    this.moveTo(this.getCurrentChatUrl());
  }

  getCurrentChatUrl(): string {
    if (this.router.url.includes(ROUTE_URL.all)) {
      return ROUTE_URL.all;
    }

    if (this.router.url.includes(ROUTE_URL.dialog)) {
      return ROUTE_URL.dialog;
    }

    if (this.router.url.includes(ROUTE_URL.inbox)) {
      return ROUTE_URL.inbox;
    }

    return this.rootUrl;
  }

  getUserRequestUrl(
    chatId: string,
    requestId: string,
  ): string {
    return `${ALL_TAB_LINK}/${chatId}/request/${requestId}`;
  }

  getUserRequestIdFromUrl(): string {
    const match = this.router.url.match(USER_REQUEST_ID_REGEX);

    return match
      ? match[USER_REQUEST_ID_INDEX]
      : null;
  }

  moveToIntegrationsPage(): void {
    this.moveTo(ROUTE_URL.settingsIntegrations);
  }

  moveToSettingsDepartmentsPage(): void {
    this.moveTo(ROUTE_URL.settingsTeamDepartments);
  }

  moveToInboxPage(): void {
    this.moveTo(ROUTE_URL.inbox);
  }

  moveToChannelsPage(): void {
    this.moveTo(ROUTE_URL.settingsChannels);
  }

  moveToTemplatesSettingsPage(): void {
    this.moveTo(ROUTE_URL.settingsTemplateMessages);
  }

  moveToBasePageIfOnChat(chatId: string): void {
    const currentLink = this.router.url;
    const baseUrl = this.routerService.getBaseUrl(currentLink);

    if (currentLink.includes(chatId)) {
      this.router.navigateByUrl(baseUrl);
    }
  }

  moveToChannelInstallationPage(channelId: string) {
    this.moveTo(
      `${ROUTE_URL.settingsChannels}`
      + `/${channelId}/`
      + `${ROUTE_URL.settingsChannelsInstallation}`,
    );
  }

  moveToLoginPage(): void {
    this.moveTo(ROUTE_URL.login);
  }

  moveToChatGptIntegrationPage(): void {
    this.moveTo(`${ROUTE_URL.settingsIntegrationsSetup}/${ROUTE_URL.openAiChatGptPath}`);
  }

  moveToChat(chatId: string, tab: Tab = Tab.All): void {
    this.moveTo(`${this.tabUrlMap.get(tab)}/${chatId}`);
  }

  moveToTab(tab: Tab) {
    this.moveTo(`${this.tabUrlMap.get(tab)}`);
  }

  isTabActive(tab: Tab): boolean {
    return this.router.url.includes(this.tabUrlMap.get(tab));
  }

  private moveTo(uri: string) {
    this.router.navigateByUrl(uri);
  }

  goToOpenAiChatGptBotSetupPage(): void {
    this.router.navigateByUrl(
      `${ROUTE_URL.settingsIntegrationsSetup}/${ROUTE_URL.openAiChatGptPath}/${ROUTE_URL.newBotPath}`,
    );
  }

  updateLocation(url: string): void {
    if (this.sideWidgetService.isInExtension()) {
      this.sideWidgetService.reload(url);
    } else {
      window.location.href = url;
    }
  }

  reload(): void {
    if (this.sideWidgetService.isInExtension()) {
      this.sideWidgetService.reload();
    } else {
      window.location.reload();
    }
  }
}
