import {
  Component, Inject,
} from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  BehaviorSubject, debounceTime, map, Observable,
} from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormControl, FormGroup } from '@angular/forms';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { LoadingService } from '../../../../../loading/loading.service';
import { ChannelEventService } from '../../../../../amplitude-event-service/channel-event.service';
import { MessengerProviderFragment, MessengerType } from '../../../../../graphql/graphql';
import { ErrorService } from '../../../../../error.service';
import { SentryService } from '../../../../../sentry.service';
import { MessengerGqlService } from '../../../../../messenger-gql.service';
import { SettingsFieldsValidationService } from '../../../../../settings-fields-validation.service';
import { ErrorTipProviderService } from '../../../../../service/error-tip-provider.service';
import { ActionStatus } from '../../../../../amplitude-event-service/action.status';
import { FeatureFlagsService } from '../../../../../feature-flags.service';
import { PageRouteService } from '../../../../../page-route.service';
import {
  MessengerProviderDataField,
} from './model/messenger-provider-data-field';
import {
  WhatsAppChannelCreationResponse,
} from './model/whats-app-channel-creation-response';
import {
  MessengerProviderFieldsService,
} from './service/messenger-provider-fields.service';
import {
  WhatsAppChannelCreationStrategy,
} from './service/whats-app-channel-creation.strategy';
import { ModalWindow } from '../../modal-window';

const INITIAL_STEP_INDEX = 0;
const DEBOUNCE_TIME = 0;

export const CONTROL_NAME = {
  assignAllMembersToChannel: 'assignAllMembersToChannel',
  channelName: 'channelName',
  phoneNumber: 'phoneNumber',
  accountSid: 'accountSid',
  authToken: 'authToken',
  messagingServiceSid: 'messagingServiceSid',
};

@Component({
  selector: 'app-create-whatsapp-channel',
  templateUrl: './create-whats-app-channel.component.html',
  styleUrls: ['./create-whats-app-channel.component.scss'],
  providers: [
    LoadingService,
  ],
})
export class CreateWhatsAppChannelComponent implements ModalWindow {
  private readonly messengerType: MessengerType = MessengerType.Whatsapp;

  private readonly selectedProvider: BehaviorSubject<MessengerProviderFragment>
    = new BehaviorSubject<MessengerProviderFragment>(null);

  readonly selectedProvider$: Observable<MessengerProviderFragment>
    = this.selectedProvider.asObservable();

  readonly selectedProviderId$: Observable<string>
    = this.toMessengerProviderId(this.selectedProvider$);

  readonly isProviderNotSelected$: Observable<boolean>
    = this.isProviderNotSelected(this.selectedProviderId$);

  readonly messengerProviders$: Observable<MessengerProviderFragment[]>
    = this.getMessengerProviders();

  readonly generalChannelDataStepFields: MessengerProviderDataField[]
    = this.messengerProviderFieldsService.generalChannelFields;

  currentStepIndex: number = INITIAL_STEP_INDEX;

  generalChannelDataStepFormGroup!: FormGroup;

  providerCredentialDataStepFromGroup!: FormGroup;

  providerCredentialDataStepFields!: MessengerProviderDataField[];

  creationResponse!: WhatsAppChannelCreationResponse;

  constructor(
    private loadingService: LoadingService,
    private nzModalService: NzModalService,
    private nzMessageService: NzMessageService,
    private notificationService: NzNotificationService,
    private messengerGqlService: MessengerGqlService,
    private messengerProviderFieldsService: MessengerProviderFieldsService,
    private settingsFieldsValidationService: SettingsFieldsValidationService,
    private errorTipProviderService: ErrorTipProviderService,
    private whatsAppChannelCreationStrategy: WhatsAppChannelCreationStrategy,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private errorService: ErrorService,
    private sentryService: SentryService,
    private channelEventService: ChannelEventService,
    public featureFlagsService: FeatureFlagsService,
    private pageRouteService: PageRouteService,
  ) {
  }

  showModal(): void {
    this.nzModalService.closeAll();
    this.nzModalService.create({
      nzTitle: this.translationService.t('create-channel.whats-app.modal.title'),
      nzWidth: '40%',
      nzMaskClosable: false,
      nzOnCancel: () => this.channelEventService
        .sendChannelAddingCanceledEvent(this.messengerType),
      nzContent: CreateWhatsAppChannelComponent,
    });
    this.channelEventService.sendChannelAddingStartedEvent(this.messengerType);
  }

  setSelectedProvider(provider: MessengerProviderFragment): void {
    if (this.selectedProvider.value?.id === provider.id) {
      this.selectedProvider.next(null);

      return;
    }

    this.selectedProvider.next(provider);
  }

  handleSelectProviderButtonClick(): void {
    if (!this.generalChannelDataStepFormGroup) {
      this.generalChannelDataStepFormGroup
        = this.settingsFieldsValidationService
          .init(this.generalChannelDataStepFields);
    }

    this.nextStep();
  }

  handleConnectWithGeneralDataButtonClick(): void {
    if (!this.providerCredentialDataStepFromGroup) {
      this.providerCredentialDataStepFields
        = this.messengerProviderFieldsService
          .getFieldsByMessengerProviderName(
            this.selectedProvider.value.name,
          );
      this.providerCredentialDataStepFromGroup
        = this.settingsFieldsValidationService
          .init(this.providerCredentialDataStepFields);

      this.providerCredentialDataStepFromGroup.addControl(
        CONTROL_NAME.assignAllMembersToChannel,
        new FormControl(true),
      );
    }

    this.nextStep();
  }

  handleConnectButtonClick(): void {
    this.loadingService.showLoaderUntilCompleted(
      this.whatsAppChannelCreationStrategy.createChannelNew(
        {
          ...this.generalChannelDataStepFormGroup.value,
          ...this.providerCredentialDataStepFromGroup.value,
        },
        this.selectedProvider.value,
      ),
    ).subscribe(
      {
        next: this.handleSuccess.bind(this),
        error: (err) => this.handleError(err),
      },
    );
  }

  handleDoneButtonClick(): void {
    this.nzModalService.closeAll();
  }

  handleSuccess(
    creationResponse: WhatsAppChannelCreationResponse,
  ): void {
    this.setCreationResponse(creationResponse);
    this.nzMessageService.success(
      this.translationService.t('whatsapp.channel.created'),
    );
    this.channelEventService.sendChannelAddingCompletedEvent(
      this.messengerType,
      ActionStatus.SUCCESS,
    );
    this.nextStep();
  }

  handleError(error): void {
    this.sentryService.warn(`Could not create WhatsApp channel: ${error}`);
    const { value, status } = JSON.parse(error?.message);
    const errorSlug = this.errorService
      .getErrorInfoSlugsGql({ value, status });

    this.nzModalService.closeAll();
    this.notificationService.create(
      'error',
      this.translationService.t(errorSlug.title),
      this.translationService.t(errorSlug.description),
    );
    this.channelEventService.sendChannelAddingCompletedEvent(
      MessengerType.Whatsapp,
      status < 500
        ? ActionStatus.BAD_REQUEST
        : ActionStatus.API_ERROR,
    );
  }

  toMessengerProviderIconName(provider: MessengerProviderFragment): string {
    const messengerProviderName: string
      = this.toMessengerProviderName(provider);

    return messengerProviderName
      ? `${messengerProviderName}.svg`
      : null;
  }

  toMessengerProviderName(provider: MessengerProviderFragment): string {
    return provider.name?.toLowerCase();
  }

  getErrorTip(controlName: string, formGroup: FormGroup): string {
    return this.errorTipProviderService.getErrorTip(
      formGroup,
      controlName,
    );
  }

  previousStep(): void {
    this.currentStepIndex -= 1;
  }

  nextStep(): void {
    this.currentStepIndex += 1;
  }

  handleAddTemplatesButtonClick(): void {
    this.nzModalService.closeAll();
    this.pageRouteService.moveToTemplatesSettingsPage();
  }

  get ControlName(): typeof CONTROL_NAME {
    return CONTROL_NAME;
  }

  private isProviderNotSelected(
    selectedProviderId$: Observable<string>,
  ): Observable<boolean> {
    return selectedProviderId$.pipe(
      map((providerId) => !providerId),
    );
  }

  private toMessengerProviderId(
    messengerProvider: Observable<MessengerProviderFragment>,
  ): Observable<string> {
    return messengerProvider.pipe(
      debounceTime(DEBOUNCE_TIME),
      map((provider) => provider?.id),
    );
  }

  private setCreationResponse(
    creationResponse: WhatsAppChannelCreationResponse,
  ): void {
    this.creationResponse = creationResponse;
  }

  private getMessengerProviders(): Observable<MessengerProviderFragment[]> {
    return this.loadingService
      .showLoaderUntilFirstResult(
        this.messengerGqlService.getMessengerProviders(this.messengerType),
      );
  }
}
