import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FeatureFlagsService } from '@app/feature-flags.service';
import { CustomValidators } from '../../../../util/custom-validators';
import { ChannelLink } from '../../../../channel-link-generation.service';
import { WidgetChannel } from '../../../../model/widget-properties';
import { MessengerType } from '../../../../graphql/graphql';
import { SWITCH_CONTROL_APPENDIX, SWITCH_CONTROL_NAME, UpdatedDataDto } from './widget-channels-page.component';

@Injectable({ providedIn: 'root' })
export class WidgetChannelsMapperService {
  constructor(
    public featureFlagsService: FeatureFlagsService,
  ) {

  }

  toUpdatedDataDto(form: FormGroup): UpdatedDataDto {
    const channelIds = Object.keys(form.controls)
      .filter((key) => isApplicableInput(form.controls[key]))
      .map((key) => form.controls[key].value);

    return {
      channelIds,
      isLiveChatEnabled: form.controls[SWITCH_CONTROL_NAME].value,
    };
  }

  toWidgetChannels(
    links: ChannelLink[],
    formGroup: FormGroup,
  ): WidgetChannel[] {
    return links.map((channel) => ({
      id: channel.id,
      messenger: channel.messenger,
      link: channel.link,
      active: this.isActive(channel.messenger, formGroup),
    }));
  }

  private isActive(messenger: MessengerType, formGroup: FormGroup): boolean {
    const controlName = Object.keys(formGroup.controls)
      .find((key) => key.includes(`${messenger}${SWITCH_CONTROL_APPENDIX}`));

    return formGroup.controls[controlName].value;
  }
}

function isApplicableInput(control: AbstractControl): boolean {
  return CustomValidators.notNull(control)
    && CustomValidators.isNumber(control);
}
