export function debounce(func, timeout = 0) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
}

// eslint-disable-next-line func-names,@typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
export const EMPTY_FUNCTION = function (args?: any): void { };
