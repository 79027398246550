import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '@app/local-storage-service';
import { SentryService } from '@app/sentry.service';
import { TokenStorageService } from './auth/token-storage.service';
import { environment } from '../environments/environment';
import { WsEventBody } from './model/ws-event-body';

@Injectable({ providedIn: 'root' })
export class WebsocketIoService {
  socket: any;

  readonly socketContext: string = '/api-socket';

  constructor(
    private tokenStorage: TokenStorageService,
    private httpClient: HttpClient,
    private sentryService: SentryService,
    private localStorageService: LocalStorageService,
  ) {
    this.socket = io(environment.ip, { path: this.socketContext });
    this.socket.on('connect', () => this.buildOperatorSessionPair());
    this.socket.on('connect_error', (err) => {
      sentryService.warn(`connect_error due to ${err.message}`);
    });
    this.socket.on('connect_failed', (err) => {
      sentryService.warn(`connect_failed due to ${err.message}`);
    });
    this.socket.on('reconnect_failed', (err) => {
      sentryService.warn(`reconnect_failed due to ${err.message}`);
    });
  }

  public listen(event: string): Observable<WsEventBody> {
    return new Observable((subscriber) => {
      this.socket.on(event, (data: WsEventBody) => {
        subscriber.next(data);
      });
    });
  }

  public send(event: string, body: WsEventBody): void {
    this.socket.emit(
      event,
      {
        ...body,
        event,
        operatorId: this.localStorageService.getCurrentOperatorIdString(),
      },
    );
  }

  sendToServer(event: string, body: any): void {
    this.socket.emit(
      event,
      {
        ...body,
        operatorId: this.localStorageService.getCurrentOperatorIdString(),
      },
    );
  }

  public buildOperatorSessionPair(): void {
    if (!this.tokenStorage.getAccessToken()) {
      return;
    }

    const operatorId = this.localStorageService.getCurrentOperatorIdString();
    const { ip, apiBaseUrl } = environment;
    const buildPairUrl = `${ip}${apiBaseUrl}operator-session-pair/`;

    this.httpClient.post(buildPairUrl, {
      operatorId,
      sessionId: this.socket.id,
    }).subscribe();
  }
}
