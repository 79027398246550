import { Observable } from 'rxjs';
import { ValidatorFn } from '@angular/forms';
import { BadgeView } from '@app/common/model/badgeView';
import { PageHeaderButton } from '@app/service/page-header-control.service';

export interface AbstractInputField {
  name?: string;
  sourceValue?: number | string | boolean;
  disabled?: boolean;
  validators?: ValidatorFn[];
}

export interface ChannelsPageInputField extends AbstractInputField {
  title: string;
  isActive?: boolean;
  placeHolder?: string;
  type?: FieldType;
  switch?: Switch;
  channelOptions?: ChannelOption[];
}

export type Switch = AbstractInputField;

export interface ChannelOption {
  id?: number;
  name: string;
}

export enum FieldType {
  SELECT_PLUS_SWITCH='SELECT_PLUS_SWITCH',
  SWITCH_ONLY='SWITCH_ONLY',
  COMING_SOON='COMING_SOON',
  NO_OPTION='NO_OPTION',
}

export interface InputField extends AbstractInputField {
  title?: string;
  description?: string;
  placeHolder?: string;
  display?: Observable<boolean>;
  type?: InputFieldType;
  values?: Observable<ViewableOption[]>;
  staticValues?: ViewableOption[] | string[] | number[];
  children?: string[];
  testId?: string;
  getTooltip?(): string;
  handleStateChange?(
    value: string | number | boolean,
  );
  handleStateChangeManual?(
    value: string | number | boolean,
  );
  button?: PageHeaderButton;
}

export interface ViewableOption {
  labelSlug: string;
  name?: string;
  value: string | number;
  badge?: BadgeView;
  isHidden$?: Observable<boolean>;
}

export interface SelectViewableOption extends ViewableOption {
  isChecked: boolean;
}

export enum InputFieldType {
  TEXT='TEXT',
  RADIO='RADIO',
  SELECT='SELECT',
  TEXTAREA='TEXTAREA',
  PASSWORD='PASSWORD',
  COLOR_PICKER='COLOR_PICKER',
  SEGMENTED='SEGMENTED',
  CHILD_SWITCH='CHILD_SWITCH',
  PARENT_SWITCH='PARENT_SWITCH',
  CHECKBOX='CHECKBOX',
  WARNING='WARNING',
}
