import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormsModule } from '@angular/forms';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { I18NextModule } from 'angular-i18next';
import { LogotypeModule } from '@app/logotype/logotype.module';
import { AppUtilModule } from '@app/app-util.module';
import { LoadingModule } from '@app/loading/loading.module';
import {
  ShopifyStoreListComponent,
} from '@app/elements-list/shopify-store-list/shopify-store-list.component';
import { SettingsBillingPageModule } from '@app/settings/billing/settings-billing-page.module';
import { InfoMessageCardModule } from '@app/info-message-card/info-message-card.module';
import { PaymentPageModule } from '@app/payment/payment-page/payment-page.module';
import { ShopifyPaymentPageComponent } from './shopify-payment-page.component';
import { ShopifyPaymentPageRoutingModule } from './shopify-payment-page.routing.module';

@NgModule({
  declarations: [
    ShopifyPaymentPageComponent,
    ShopifyStoreListComponent,
  ],
  imports: [
    ShopifyPaymentPageRoutingModule,
    CommonModule,
    NzLayoutModule,
    LogotypeModule,
    NzGridModule,
    NzTypographyModule,
    NzDividerModule,
    NzIconModule,
    AppUtilModule,
    LoadingModule,
    NzButtonModule,
    NzCardModule,
    NzRadioModule,
    FormsModule,
    NzStepsModule,
    SettingsBillingPageModule,
    I18NextModule,
    InfoMessageCardModule,
    PaymentPageModule,
  ],
})
export class ShopifyPaymentPageModule { }
