import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(user: User): string | any {
    return FullNamePipe.getFullNameByUser(user);
  }

  static getFullNameByUser(user: User): string {
    if (!user) {
      return '';
    }

    return FullNamePipe.getFullName(user.firstName, user.lastName);
  }

  static getFullName(firstName: string, lastName: string): string {
    return firstName && lastName
      ? `${firstName} ${lastName}`
      : firstName || '';
  }
}

export interface User {
  firstName?: string;
  lastName?: string;
}
