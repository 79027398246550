import { Injectable } from '@angular/core';
import { Tab } from '@app/graphql/graphql';
import { LocalStorageService } from '@app/local-storage-service';
import {
  FiltrationParameterValidationService,
} from './filtration-parameter-validation.service';
import {
  FiltrationParameterMapper,
} from './filtration-parameter.mapper';
import { ChatPreviewFilterInputParameters } from '../model/chat-preview-filter-input-parameters';

const PARAMETER_PREFIX = 'tp_';
const FIRST_LETTER_INDEX = 0;

@Injectable({
  providedIn: 'root',
})
export class FiltrationParameterStorage {
  constructor(
    private filtrationParameterMapper: FiltrationParameterMapper,
    private validationService: FiltrationParameterValidationService,
    private localStorageService: LocalStorageService,
  ) {
  }

  getAllOrDefault(
    inputParameters: ChatPreviewFilterInputParameters,
    tab: Tab,
  ): ChatPreviewFilterInputParameters {
    return Object.keys(inputParameters)
      .reduce(
        (parameters, inputParameterName) => ({
          ...parameters,
          [inputParameterName]: this.getOrDefault(
            inputParameters,
            inputParameterName,
            tab,
          ),
        }),
        inputParameters,
      );
  }

  save(
    inputParameters: ChatPreviewFilterInputParameters,
    tab: Tab,
  ): void {
    Object.keys(inputParameters)
      .forEach((parameterName) => this.set(
        toStorageParameterName(parameterName, tab),
        this.filtrationParameterMapper.toStringValue(
          parameterName,
          inputParameters[parameterName],
        ),
      ));
  }

  private getOrDefault(
    inputParameters: ChatPreviewFilterInputParameters,
    inputParameterName: string,
    tab: Tab,
  ): any {
    const value: string
      = this.getIfValid(toStorageParameterName(inputParameterName, tab));

    return value
      ? this.filtrationParameterMapper.toFormValue(inputParameterName, value)
      : inputParameters[inputParameterName];
  }

  private getIfValid(
    name: string,
  ): string {
    const value: string = this.get(name);

    if (this.validationService.isValid(name, value)) {
      return value;
    }

    return null;
  }

  private get(name: string): string {
    return this.localStorageService.get(name);
  }

  private set(name: string, value: string): void {
    this.localStorageService.set(name, value);
  }
}

function toStorageParameterName(controlName: string, tab: Tab): string {
  const firstLetterOfTabName = tab.toString()
    .charAt(FIRST_LETTER_INDEX)
    .toLowerCase();

  return `${firstLetterOfTabName}${PARAMETER_PREFIX}${controlName}`;
}
