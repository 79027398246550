import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FIELD_NAME } from '@app/settings/billing/details/form/billing-details-form.component';
import {
  BillingDetail,
  BillingDetailsDocument,
  BillingDetailsGQL,
  BillingDetailType,
  CreateBillingDetailInput, CreateBillingDetailsGQL,
  UpdateBillingDetailInput, UpdateBillingDetailsGQL,
} from '../graphql/graphql';

const REQUIRED_NUMBER_OF_BILLING_DETAILS = 3;

@Injectable({
  providedIn: 'root',
})
export class BillingDetailService {
  constructor(
    private billingDetailsGql: BillingDetailsGQL,
    private createBillingDetailsGql: CreateBillingDetailsGQL,
    private updateBillingDetailsGql: UpdateBillingDetailsGQL,
  ) {
  }

  // TODO: use default fetchPolicy (cache-first) instead of cache-and-network
  public getBillingDetails(): Observable<BillingDetail[]> {
    return this.billingDetailsGql.watch(
      {},
      {
        fetchPolicy: 'cache-and-network',
      },
    ).valueChanges.pipe(
      map(
        (result) => result.data.billingDetails,
      ),
      shareReplay(),
    );
  }

  public createBillingDetails(
    createBillingDetailInputs: CreateBillingDetailInput[],
  ): Observable<BillingDetail[]> {
    return this.createBillingDetailsGql.mutate(
      {
        inputs: createBillingDetailInputs,
      },
      {
        update: (cache, { data: createBillingDetailsMutation }) => {
          this.updateCache(
            cache,
            () => createBillingDetailsMutation.createBillingDetails,
          );
        },
      },
    ).pipe(
      map(
        (result) => result.data.createBillingDetails,
      ),
    );
  }

  public updateBillingDetails(
    updateBillingDetailInputs: UpdateBillingDetailInput[],
  ): Observable<BillingDetail[]> {
    return this.updateBillingDetailsGql.mutate(
      {
        inputs: updateBillingDetailInputs,
      },
      {
        update: (cache, { data: updateBillingDetailsMutation }) => {
          this.updateCache(
            cache,
            () => updateBillingDetailsMutation.updateBillingDetails,
          );
        },
      },
    ).pipe(
      map(
        (result) => result.data.updateBillingDetails,
      ),
    );
  }

  public getBillingDetailValueByTypeIn(
    type: BillingDetailType,
    billingDetails: BillingDetail[],
  ): string {
    return billingDetails.find(
      (billingDetail) => billingDetail.type === type,
    )?.value;
  }

  public getBillingDetailIdByTypeIn(
    type: BillingDetailType,
    billingDetails: BillingDetail[],
  ): string {
    return billingDetails.find(
      (billingDetail) => billingDetail.type === type,
    )?.id;
  }

  private updateCache(
    cache,
    mergeFunction: () => BillingDetail[],
  ) {
    cache.writeQuery({
      query: BillingDetailsDocument,
      data: {
        billingDetails: mergeFunction(),
      },
    });
  }

  hasAllBillingDetails(): Observable<boolean> {
    return this.getBillingDetails().pipe(
      map(
        (
          billingDetails,
        ) => billingDetails?.length === REQUIRED_NUMBER_OF_BILLING_DETAILS,
      ),
    );
  }

  public createOrUpdateBillingDetails(
    formGroup: FormGroup,
    billingDetails: BillingDetail[],
  ): Observable<BillingDetail[]> {
    if (
      this.getBillingDetailIdByTypeIn(
        BillingDetailType.Address,
        billingDetails,
      )) {
      return this.updateBillingDetailsByFormGroup(
        formGroup,
        billingDetails,
      );
    }

    return this.createBillingDetailsByFormGroup(formGroup);
  }

  private createBillingDetailsByFormGroup(formGroup: FormGroup) {
    return this.createBillingDetails([
      {
        type: BillingDetailType.Address,
        value: formGroup.get(FIELD_NAME.address).value.trim(),
      },
      {
        type: BillingDetailType.Email,
        value: formGroup.get(FIELD_NAME.email).value,
      },
      {
        type: BillingDetailType.Country,
        value: formGroup.get(FIELD_NAME.country).value,
      },
    ]);
  }

  private updateBillingDetailsByFormGroup(
    formGroup: FormGroup,
    billingDetails: BillingDetail[],
  ) {
    return this.updateBillingDetails([
      {
        id: this.getBillingDetailIdByTypeIn(
          BillingDetailType.Email,
          billingDetails,
        ),
        value: formGroup.get(FIELD_NAME.email).value.trim(),
      },
      {
        id: this.getBillingDetailIdByTypeIn(
          BillingDetailType.Country,
          billingDetails,
        ),
        value: formGroup.get(FIELD_NAME.country).value,
      },
      {
        id: this.getBillingDetailIdByTypeIn(
          BillingDetailType.Address,
          billingDetails,
        ),
        value: formGroup.get(FIELD_NAME.address).value,
      },
    ]);
  }
}
