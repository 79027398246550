import { Injectable } from '@angular/core';
import {
  BehaviorSubject, map, Observable, of,
} from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { doOnSubscribe } from '@app/util/rxjs.util';

@Injectable()
export class LoadingService {
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);

  readonly loading$: Observable<boolean> = this.loadingSubject.asObservable();

  readonly isNotLoading$: Observable<boolean> = this.loading$.pipe(
    map((loading: boolean) => !loading),
  );

  showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null)
      .pipe(
        tap(() => this.loadingOn()),
        concatMap(() => obs$),
        finalize(() => this.loadingOff()),
      );
  }

  showLoaderUntilFirstResult<T>(obs$: Observable<T>): Observable<T> {
    return obs$.pipe(
      doOnSubscribe(() => this.loadingOn()),
      tap(() => this.loadingOff()),
    );
  }

  loadingOn(): void {
    this.loadingSubject.next(true);
  }

  loadingOff(): void {
    this.loadingSubject.next(false);
  }

  isLoading(): boolean {
    return this.loadingSubject.getValue();
  }
}
