import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WEBSOCKET_EVENT_TYPE } from '@src/constants/websocket-event-type.constants';
import { LocalStorageService } from '@app/local-storage-service';
import { Message } from '@app/graphql/graphql';
import { ChatTransferFromOperatorWs } from '@app/model/dto/chat.transfer/chat-transfer-from-operator-ws';
import { SentryService } from '@app/sentry.service';
import { WebsocketIoService } from '../websocket-io.service';
import { WsEventBody } from '../model/ws-event-body';
import { ChatTransfer } from '../model/chat-transfer';

@Injectable({ providedIn: 'root' })
export class TransferChatWsService {
  constructor(private websocketService: WebsocketIoService,
              private sentryService: SentryService,
              private localStorageService: LocalStorageService) {
  }

  onChatTransferRequested(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferRequested);

    return event.pipe(
      map((dto: WsEventBody) => {
        this.sentryService.info(`OnChatTransferRequested
        Chat id: ${dto.data.chatId}
        ChatTransfer id: ${dto.data.id}
        Current operator ${this.localStorageService.getCurrentOperatorId()}`);

        return dto.data;
      }),
    );
  }

  onAutomaticChatTransfer(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.automaticChatTransfer);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTransferAccepted(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferAccepted);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTransferAcceptedByCurrentOperator(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferAcceptedByCurrentOperator);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTransferByAdmin(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferByAdmin);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTransferFromAdmin(): Observable<ChatTransferFromOperatorWs> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferFromAdmin);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onAutomaticChatTransferCancelation(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.automaticChatTransferCancelation);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTransferCancelled(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferCanceled);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTransferRejected(): Observable<ChatTransfer> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatTransferRejected);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onNewMessageInTransferredChat(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.transferredChatMessageNew);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onMessageUpdatedInTransferredChat(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.transferredChatMessageUpd);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onMessageReadInTransferredChat(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.transferredChatMessageRead);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }
}
