import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import {
  AdditionalOperatorSeatsPrice, AdditionalOperatorSeatsPriceGQL,
  CancelSubscriptionGQL,
  CompanyPaymentCardGQL,
  CompanyPaymentHistoryGQL,
  CompanySubscription, CompanySubscriptionDocument,
  CompanySubscriptionGQL, CompanySubscriptionQuery,
  CreateNewPaymentBasedOnTheFailedGQL,
  CreatePaymentTransactionForNewCardGQL,
  CreateSubscriptionGQL,
  CreateSubscriptionInput,
  DeleteCompanyPaymentCardGQL,
  DeviceDetails,
  GenerateMerchantDataGQL,
  GetRecalculationInfoGQL,
  HasCurrentSubscriptionStatusFailedTransactionGQL,
  LastActiveSubscriptionGQL,
  MerchantData,
  PaymentTransaction,
  PaymentTransactionGQL,
  PaymentTransactionInfo,
  PaymentTransactionStatusFragment,
  PayWithExistingCardGQL,
  RenewCanceledSubscriptionGQL,
  SubscriptionPlan,
  SubscriptionPlansGQL,
  SubscriptionRecalculationInfo,
  UpdateAdditionalOperatorSeatsGQL,
  UserCard,
} from '@app/graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(
    private subscriptionPlansGQL: SubscriptionPlansGQL,
    private generateMerchantDataGql: GenerateMerchantDataGQL,
    private createSubscriptionGQL: CreateSubscriptionGQL,
    private paymentTransactionGQL: PaymentTransactionGQL,
    private createNewPaymentBasedOnTheFailedGQL:
      CreateNewPaymentBasedOnTheFailedGQL,
    private companySubscriptionGQL: CompanySubscriptionGQL,
    private companyPaymentCardGQL: CompanyPaymentCardGQL,
    private lastActiveSubscriptionGQL: LastActiveSubscriptionGQL,
    private companyPaymentHistoryGQL: CompanyPaymentHistoryGQL,
    private deleteCompanyPaymentCardGQL: DeleteCompanyPaymentCardGQL,
    private createPaymentTransactionForNewCardGQL
      : CreatePaymentTransactionForNewCardGQL,
    private payWithExistingCardGQL: PayWithExistingCardGQL,
    private hasCurrentSubscriptionStatusFailedTransactionGQL:
      HasCurrentSubscriptionStatusFailedTransactionGQL,
    private cancelSubscriptionGQL: CancelSubscriptionGQL,
    private renewCanceledSubscriptionGQL: RenewCanceledSubscriptionGQL,
    private getRecalculationInfoGQL: GetRecalculationInfoGQL,
    private updateAdditionalOperatorSeatsGQL: UpdateAdditionalOperatorSeatsGQL,
    private additionalOperatorSeatsPriceGQL: AdditionalOperatorSeatsPriceGQL,
  ) {
  }

  getAllCompanyPaymentHistory(
    page: number,
    size: number,
  ): Observable<PaymentTransactionInfo> {
    return this.companyPaymentHistoryGQL
      .watch({
        page,
        size,
      },
      {
        fetchPolicy: 'cache-and-network',
      }).valueChanges
      .pipe(
        map((data) => data.data
          .companyPaymentHistory as PaymentTransactionInfo),
        shareReplay(),
      );
  }

  hasCurrentSubscriptionStatusFailedTransaction(): Observable<boolean> {
    return this.hasCurrentSubscriptionStatusFailedTransactionGQL
      .fetch(
        {},
        {
          fetchPolicy: 'network-only',
        },
      ).pipe(
        map((result) => result.data
          .hasCurrentSubscriptionStatusFailedTransaction),
      );
  }

  fetchMore(
    page: number,
    size: number,
  ): Observable<PaymentTransactionInfo> {
    return from(
      this.companyPaymentHistoryGQL
        .watch()
        .fetchMore({
          variables: {
            page,
            size,
          },
        }),
    ).pipe(
      map((result) => result.data
        .companyPaymentHistory as PaymentTransactionInfo),
      shareReplay(),
    );
  }

  getAllActiveSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.subscriptionPlansGQL.watch()
      .valueChanges
      .pipe(map((result) => result.data
        .activeSubscriptionPlans as SubscriptionPlan[]));
  }

  getPaymentTransaction(paymentId: string):Observable<PaymentTransaction> {
    return this.paymentTransactionGQL.watch(
      {
        input: paymentId,
      },
    ).valueChanges.pipe(map((result) => result.data
      .paymentTransaction as PaymentTransaction));
  }

  getCompanySubscription(): Observable<CompanySubscription> {
    return this.companySubscriptionGQL.watch(
      {},
      {
        fetchPolicy: 'cache-and-network',
      },
    ).valueChanges.pipe(
      map((result) => result.data
        .companySubscription as CompanySubscription),
      shareReplay(),
    );
  }

  refetchCompanySubscription(): void {
    this.companySubscriptionGQL.watch().refetch();
  }

  getCompanyLastActiveSubscription(): Observable<CompanySubscription> {
    return this.lastActiveSubscriptionGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((result) => result.data
        .lastActiveSubscription as CompanySubscription),
      shareReplay(),
    );
  }

  getCompanyPaymentCard(): Observable<UserCard> {
    return this.companyPaymentCardGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((result) => result.data.companyPaymentCard as UserCard),
      shareReplay(),
    );
  }

  getSubscriptionRecalculationInfo(paymentId: string):
    Observable<SubscriptionRecalculationInfo> {
    return this.getRecalculationInfoGQL.fetch(
      {
        input: paymentId,
      },
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((result) => result
        .data.subscriptionRecalculationInfo as SubscriptionRecalculationInfo),
    );
  }

  generateMerchantData(
    paymentId: string,
    deviceDetails: DeviceDetails,
  ): Observable<MerchantData> {
    return this.generateMerchantDataGql.mutate(
      {
        paymentTransactionId: paymentId,
        deviceDetails,
      },
    ).pipe(map((result) => result.data.generateMerchantData as MerchantData));
  }

  createSubscription(
    input: CreateSubscriptionInput,
  ): Observable<PaymentTransaction> {
    return this.createSubscriptionGQL.mutate(
      {
        input,
      },
    ).pipe(
      map((result) => result.data
        .createSubscription as PaymentTransaction),
    );
  }

  cancelSubscription(subscriptionId: string): Observable<CompanySubscription> {
    return this.cancelSubscriptionGQL.mutate(
      {
        input: subscriptionId,
      },
    ).pipe(map((result) => result.data
      .cancelSubscription as CompanySubscription));
  }

  createNewPaymentBasedOnTheFailed(paymentTransactionId: string):
    Observable<PaymentTransactionStatusFragment> {
    return this.createNewPaymentBasedOnTheFailedGQL.mutate(
      {
        input: paymentTransactionId,
      },
    ).pipe(map((result) => result.data
      .createPaymentTransactionBasedOnFailedOne as
      PaymentTransactionStatusFragment));
  }

  deletePaymentMethod(): Observable<boolean> {
    return this.deleteCompanyPaymentCardGQL.mutate()
      .pipe(map((result) => result.data.deleteCompanyPaymentCard));
  }

  createPaymentTransactionForNewCard(
    description: string,
  ): Observable<PaymentTransactionStatusFragment> {
    return this.createPaymentTransactionForNewCardGQL.mutate(
      {
        input: description,
      },
    ).pipe(map((result) => result.data
      .createPaymentTransactionForNewCard as
      PaymentTransactionStatusFragment));
  }

  payInOneClick(paymentTransactionId: string): Observable<boolean> {
    return this.payWithExistingCardGQL.mutate(
      {
        input: paymentTransactionId,
      },
    ).pipe(map((result) => result.data.payWithExistingCard));
  }

  renewSubscription(subscriptionPlanId: string): Observable<boolean> {
    return this.renewCanceledSubscriptionGQL.mutate(
      {
        input: subscriptionPlanId,
      },
    ).pipe(map((result) => result.data.renewCanceledSubscription));
  }

  updateAdditionalOperatorSeats(
    totalAdditionalSeats: number,
  ): Observable<PaymentTransaction> {
    return this.updateAdditionalOperatorSeatsGQL.mutate(
      {
        input: { totalAdditionalSeats },
      },
      {
        update: (store) => {
          store.updateQuery(
            { query: CompanySubscriptionDocument },
            (cachedData: CompanySubscriptionQuery) => ({
              companySubscription: {
                ...cachedData.companySubscription,
                additionalOperatorSeats: totalAdditionalSeats,
              },
            }),
          );
        },
      },
    ).pipe(
      map((result) => result.data
        .updateAdditionalOperatorSeats as PaymentTransaction),
    );
  }

  getAdditionalOperatorSeatsPrice(
    totalAdditionalSeats: number,
  ): Observable<AdditionalOperatorSeatsPrice> {
    return this.additionalOperatorSeatsPriceGQL.fetch(
      { input: { totalAdditionalSeats } },
      { fetchPolicy: 'network-only' },
    ).pipe(
      map((result) => result.data
        .additionalOperatorSeatsPrice as AdditionalOperatorSeatsPrice),
    );
  }
}
