<nz-spin class="spinner" [nzSize]="'large'" nzSimple *ngIf="loading"></nz-spin>
<p>
  {{ 'settings.billing.cancel_subscription_modal.details' | i18next }} {{ companySubscription.currentStatus.validTo | date: 'mediumDate' : 'UTC' : translationService.language }}.
</p>
<div data-testid="cancel-subscription-modal" class="box">
  <p class="title">{{ 'settings.billing.cancel_subscription_modal.issues_title' | i18next }}</p>
  <p>{{ 'settings.billing.support_team' | i18next }}</p>
  <button nz-button nzType="primary" (click)="handleContactUsButtonClicked()">
    {{ 'settings.billing.contact_us' | i18next }}
  </button>
</div>
<div class="box">
  <p class="title">{{ 'settings.billing.cancel_subscription_modal.unsatisfied_title' | i18next }}</p>
  <p>{{ 'settings.billing.cancel_subscription_modal.unsatisfied_answer' | i18next }}</p>
  <button nz-button nzType="primary" (click)="goToPlans()">
    {{ 'settings.billing.cancel_subscription_modal.change_plan_btn' | i18next }}
  </button>
</div>
<span nz-typography class="error_msg" [hidden]="hideErrorMessage" nzType="danger">{{ errorMessage }}</span>
<div *nzModalFooter>
  <button nz-button nzType="default" (click)="onNoClick()">
    {{ 'settings.billing.cancel_subscription_modal.keep_plan_btn' | i18next }}
  </button>
  <button data-testid="cancel-subscription-modal-button" nz-button nzType="primary" nzDanger (click)="onYesClick()">
    {{ 'settings.billing.cancel_subscription_btn' | i18next }}
  </button>
</div>
