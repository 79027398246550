import {
  Directive, ElementRef, EventEmitter, HostListener,
  OnInit, Output, Renderer2, RendererFactory2,
} from '@angular/core';
import { Position } from '@app/common/model/position';

const INITIAL_CURSOR = 'grab';
const GRABBING_CURSOR = 'grabbing';

@Directive({
  selector: '[appAttachmentViewMovable]',
})
export class AttachmentViewMovableDirective implements OnInit {
  private readonly renderer: Renderer2;

  private readonly listenerMove: (event: MouseEvent) => void;

  private readonly listenerStop: () => void;

  private readonly listenerTouchMove: (event: TouchEvent) => void;

  private readonly listenerTouchStop: () => void;

  private startMousePosition: Position;

  @Output() positionChanges: EventEmitter<Position>
    = new EventEmitter<Position>();

  constructor(
    private hostElement: ElementRef,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.listenerMove = (event: MouseEvent) => this.handleMouseMove(event);
    this.listenerStop = () => this.stop();

    this.listenerTouchMove = (event: TouchEvent) => this.handleTouchMove(event);
    this.listenerTouchStop = () => this.stop();
  }

  ngOnInit(): void {
    this.renderer.setStyle(
      this.hostElement.nativeElement,
      'cursor',
      INITIAL_CURSOR,
    );
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent): void {
    this.startMouseMove(event);
  }

  @HostListener('touchstart', ['$event'])
  touchStart(event: TouchEvent): void {
    this.startTouch(event);
  }

  private startTouch(event: TouchEvent): void {
    event.preventDefault();
    event.stopPropagation();

    document.addEventListener('touchend', this.listenerTouchStop);
    document.addEventListener('touchmove', this.listenerTouchMove);

    this.registerStartMousePosition(event);
    this.renderer.setStyle(this.hostElement.nativeElement, 'cursor', GRABBING_CURSOR);
  }

  private startMouseMove(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    document.addEventListener('mouseup', this.listenerStop);
    document.addEventListener('mousemove', this.listenerMove);

    this.registerStartMousePosition(event);
    this.renderer.setStyle(this.hostElement.nativeElement, 'cursor', GRABBING_CURSOR);
  }

  private handleMouseMove(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.positionChanges.emit(this.toRelativeMousePosition(event));
  }

  private handleTouchMove(event: TouchEvent): void {
    event.stopPropagation();

    this.positionChanges.emit(this.toRelativeMousePosition(event));
  }

  private stop(): void {
    document.removeEventListener('mouseup', this.listenerStop);
    document.removeEventListener('touchend', this.listenerTouchStop);
    document.removeEventListener('mousemove', this.listenerMove);
    document.removeEventListener('touchmove', this.listenerTouchMove);

    this.startMousePosition = null;
    this.renderer.setStyle(this.hostElement.nativeElement, 'cursor', INITIAL_CURSOR);
    this.resetPosition();
  }

  private toRelativeMousePosition(event: MouseEvent | TouchEvent): Position {
    const { x, y } = this.toPosition(event);

    const mouserXFromStart: number = x - this.startMousePosition.x;
    const mouserYFromStart: number = y - this.startMousePosition.y;

    return { x: mouserXFromStart, y: mouserYFromStart };
  }

  private registerStartMousePosition(event: MouseEvent | TouchEvent): void {
    this.startMousePosition = this.toPosition(event);
  }

  private toPosition(event: MouseEvent | TouchEvent): Position {
    return event instanceof MouseEvent
      ? {
        x: event.x,
        y: event.y,
      }
      : {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      };
  }

  private resetPosition(): void {
    this.positionChanges.emit({ x: 0, y: 0 });
  }
}
