<form
  nz-form
  [ngClass]="{
    'form': isBillingDetailsEditorPage,
    'form__payment_page': !isBillingDetailsEditorPage
  }"
  class="billing-details-form"
  *ngIf="formGroup"
  [formGroup]="formGroup"
>
  <nz-form-item class="form__item_email">
    <nz-form-label
      class="form__label"
      nzRequired
      nzFor="email"
      nzNoColon="true"
    >
      {{ 'settings.billing.details.email_label' | i18next }}
    </nz-form-label>
    <nz-form-control
      [nzExtra]="'settings.billing.details.email_inscription' | i18next"
      [nzErrorTip]="getErrorTip(FIELD_NAME.email)"
    >
      <input
        [ngClass]="{
          'form__input': isBillingDetailsEditorPage,
          'form__input__payment_page': !isBillingDetailsEditorPage
        }"
        nz-input
        nzSize="large"
        formControlName="email"
        placeholder="example@mail.com."
        data-testid="billing-details-email-input"
      >
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="form__item_country">
    <nz-form-label
      class="form__label"
      nzRequired
      nzFor="country"
      nzNoColon="true"
    >
      {{ 'settings.billing.details.country_label' | i18next }}
    </nz-form-label>
    <nz-form-control [nzErrorTip]="getErrorTip(FIELD_NAME.country)">
      <nz-select
        nzShowSearch
        [ngClass]="{
          'form__input': isBillingDetailsEditorPage,
          'form__input__payment_page': !isBillingDetailsEditorPage
        }"
        nzSize="large"
        formControlName="country"
        nzMode="default"
        [nzPlaceHolder]="'settings.billing.details.country_placeholder' | i18next"
        data-testid="billing-details-country-select"
      >
        <nz-option
          *ngFor="let country of countries"
          [nzLabel]="country.label"
          [nzValue]="country.name"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    [ngClass]="{
    'form__item_address': isBillingDetailsEditorPage,
    'form__item_address__payment_page': !isBillingDetailsEditorPage
    }"
  >
    <nz-form-label
      class="form__label"
      nzRequired
      nzFor="address"
      nzNoColon="true"
    >
      {{ 'settings.billing.details.address_label' | i18next }}
    </nz-form-label>
    <nz-form-control [nzErrorTip]="getErrorTip(FIELD_NAME.address)">
      <input
        [ngClass]="{
          'form__input': isBillingDetailsEditorPage,
          'form__input__payment_page': !isBillingDetailsEditorPage
        }"
        nzSize="large"
        nz-input
        formControlName="address"
        placeholder="eg. 25 Cusbo St, San Francisco, CA"
        data-testid="billing-details-address-input"
      >
    </nz-form-control>
  </nz-form-item>
  <ng-content></ng-content>
</form>
