import { Injectable } from '@angular/core';
import { File, FileType } from './graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly possibleFileTypesForVideo = [
    FileType.StoryMention,
    FileType.Video,
    FileType.Document,
    FileType.VideoNote,
  ];

  isVideoFile(file: File) {
    return this.possibleFileTypesForVideo.includes(file.fileType)
      && (
        file.mimeType.endsWith('mp4')
        || file.mimeType.endsWith('quicktime')
        || file.mimeType.endsWith('unknown')
      );
  }

  isImageFile(file: File) {
    return file.mimeType.startsWith('image');
  }
}
