import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  BotCredentialPropertyName,
  ChannelFragment,
  CreateChannelInput,
  MessengerProviderFragment,
  MessengerType,
} from '@app/graphql/graphql';
import { ChannelsService } from '@app/settings/channels-page/channels.service';
import {
  WhatsAppChannelCreationResponse,
} from '../model/whats-app-channel-creation-response';

type createChannelFunction = (
  formGroup: FormGroup,
  messengerProviderId: string,
) => Observable<ChannelFragment>;

@Injectable({
  providedIn: 'root',
})
export class WhatsAppChannelCreationStrategy {
  private readonly messengerProviderCreationMap
    = new Map<string, createChannelFunction>([
      ['TWILIO', this.createTwilioChannel.bind(this)],
    ]);

  private readonly messengerProviderCreationMapNew
    = new Map<string, createChannelFunction>([
      ['TWILIO', this.createTwilioChannelNew.bind(this)],
    ]);

  constructor(
    private channelPageService: ChannelsService,
  ) {
  }

  createChannel(
    values: any,
    messengerProvider: MessengerProviderFragment,
  ): Observable<WhatsAppChannelCreationResponse> {
    return this.messengerProviderCreationMap
      .get(messengerProvider.name)(values, messengerProvider.id)
      .pipe(map(this.toCreationResponse));
  }

  createChannelNew(
    values: any,
    messengerProvider: MessengerProviderFragment,
  ): Observable<WhatsAppChannelCreationResponse> {
    return this.messengerProviderCreationMapNew
      .get(messengerProvider.name)(values, messengerProvider.id)
      .pipe(map(this.toCreationResponse));
  }

  private toCreationResponse(
    channel: ChannelFragment,
  ): WhatsAppChannelCreationResponse {
    return {
      webhookUrl: channel.webhookUrl,
      channelId: channel.id,
    };
  }

  private createTwilioChannel(
    formGroup: FormGroup,
    messengerProviderId: string,
  ): Observable<ChannelFragment> {
    const {
      channelName,
      phoneNumber,
      accountSid,
      authToken,
      assignAllMembersToChannel,
    } = formGroup.value;
    const createChannelInput: CreateChannelInput = {
      messengerType: MessengerType.Whatsapp,
      name: channelName,
      properties: [
        {
          name: BotCredentialPropertyName.WhatsappBotNumber,
          value: phoneNumber,
        },
        {
          name: BotCredentialPropertyName.WhatsappAccountSid,
          value: accountSid,
        },
        {
          name: BotCredentialPropertyName.WhatsappAccountToken,
          value: authToken,
        },
      ],
      providerId: messengerProviderId,
    };

    return this.channelPageService.addChannel(
      createChannelInput,
      assignAllMembersToChannel,
    );
  }

  private createTwilioChannelNew(
    values: any,
    messengerProviderId: string,
  ): Observable<ChannelFragment> {
    const {
      channelName,
      phoneNumber,
      accountSid,
      messagingServiceSid,
      authToken,
      assignAllMembersToChannel,
    } = values;
    const createChannelInput: CreateChannelInput = {
      messengerType: MessengerType.Whatsapp,
      name: channelName,
      properties: [
        {
          name: BotCredentialPropertyName.WhatsappBotNumber,
          value: phoneNumber,
        },
        {
          name: BotCredentialPropertyName.WhatsappAccountSid,
          value: accountSid,
        },
        {
          name: BotCredentialPropertyName.WhatsappAccountToken,
          value: authToken,
        },
        {
          name: BotCredentialPropertyName.WhatsappMessagingServiceSid,
          value: messagingServiceSid,
        },
      ],
      providerId: messengerProviderId,
    };

    return this.channelPageService.addChannel(
      createChannelInput,
      assignAllMembersToChannel,
    );
  }
}
