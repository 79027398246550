import { Component, Inject, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NzMessageService } from 'ng-zorro-antd/message';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ROUTE_URL } from '@app/router-url.constants';
import { environment } from '@src/environments/environment';

const ALL_TAB_URL_WITHOUT_SLASH: string = ROUTE_URL.all.slice(1);

export const ALL_TAB_LINK: string = environment.ip + ALL_TAB_URL_WITHOUT_SLASH;

@Component({
  selector: 'app-chat-link-copy-button',
  templateUrl: './chat-link-copy-button.component.html',
  styleUrls: ['./chat-link-copy-button.component.scss'],
})
export class ChatLinkCopyButtonComponent {
  @Input() chatId: string;

  constructor(
    private clipboard: Clipboard,
    private nzMessageService: NzMessageService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  handleClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.clipboard.copy(`${ALL_TAB_LINK}/${this.chatId}`);
    this.nzMessageService.success(
      this.translationService.t('copy_button.chat_link_copied'),
    );
  }
}
