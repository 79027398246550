<app-loading></app-loading>
<nz-steps [nzCurrent]="currentStep">
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
</nz-steps>

<ng-container *ngIf="currentStep === 0">
  <div class="info_box">
    <div class="info">
      <div class="subtitle">
        {{ 'connect_business_account' | i18next }}
      </div>
      <div class="instruction">
        <p>{{ 'instagram-step1-instruction-title' | i18next }}</p>
        <ol>
          <li>{{ 'instagram-step1-instruction-paragraph1' | i18next }}</li>
          <li>{{ 'instagram-step1-instruction-paragraph2' | i18next }}</li>
          <li>{{ 'instagram-step1-instruction-paragraph3' | i18next }}</li>
        </ol>
      </div>
      <div class="link">
        <a target="_blank" href="https://help.instagram.com/502981923235522/">{{ 'instagram-step1-link' | i18next }}</a>
      </div>
    </div>
    <div class="giff">
      <img alt="step-1" src="/assets/giffs/instagram/instagram_step1.gif"/>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentStep === 1">
  <div class="info_box">
    <div class="info">
      <div class="subtitle">
        {{ 'instagram-step2-subtitle' | i18next }}
      </div>
      <div class="instruction">
        <p>{{ 'instagram-step2-instruction-title' | i18next }}</p>
        <ol>
          <li>{{ 'instagram-step2-instruction-paragraph1' | i18next }}</li>
          <li>{{ 'instagram-step2-instruction-paragraph2' | i18next }}</li>
          <li>{{ 'instagram-step2-instruction-paragraph3' | i18next }}</li>
          <li>{{ 'instagram-step2-instruction-paragraph4' | i18next }}</li>
          <li>{{ 'instagram-step2-instruction-paragraph5' | i18next }}</li>
          <li>{{ 'instagram-step2-instruction-paragraph6' | i18next }}</li>
        </ol>
      </div>
      <div class="link">
        <a target="_blank" href="https://help.instagram.com/399237934150902/">{{ 'instagram-step2-link' | i18next }}</a>
      </div>
    </div>
    <div class="giff">
      <img alt="step-2" src="/assets/giffs/instagram/instagram_step2.gif"/>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentStep === 2">
  <div class="info_box">
    <div class="info">
      <div class="subtitle">
        {{ 'instagram-step3-subtitle' | i18next }}
      </div>
      <div class="instruction">
        <p>{{ 'instagram-step3-instruction-title' | i18next }}</p>
        <ol>
          <li>{{ 'instagram-step3-instruction-paragraph1' | i18next }}</li>
          <li>{{ 'instagram-step3-instruction-paragraph2' | i18next }}</li>
          <li>{{ 'instagram-step3-instruction-paragraph3' | i18next }}</li>
          <li>{{ 'instagram-step3-instruction-paragraph4' | i18next }}</li>
          <li>{{ 'instagram-step3-instruction-paragraph5' | i18next }}</li>
          <li>{{ 'instagram-step3-instruction-paragraph6' | i18next }}</li>
        </ol>
      </div>
      <div class="link">
        <a target="_blank" href="https://help.instagram.com/502981923235522/">{{ 'instagram-step3-link' | i18next }}</a>
      </div>
    </div>
    <div class="giff">
      <img alt="step-3" src="/assets/giffs/instagram/instagram_step3.gif"/>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentStep === 3">
  <div class="info_box">
    <div class="info">
      <div class="subtitle">
        {{ 'instagram-step4-subtitle' | i18next }}
      </div>
      <div class="instruction">
        <p>{{ 'instagram-step4-instruction-title' | i18next }}</p>
      </div>
      <div class="link">
        <a target="_blank" href="https://www.facebook.com/help/510247025775149/">{{ 'instagram-step4-link' | i18next }}</a>
      </div>
    </div>
    <div class="giff">
      <img alt="step-4" src="/assets/giffs/instagram/instagram_step4.gif"/>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currentStep === 4">
  <div class="subtitle">
    {{ 'instagram-step5-subtitle' | i18next }}
  </div>
  <div class="instruction">
    <p>{{ 'instagram-step5-instruction-title' | i18next }}</p>
  </div>
  <app-select-from-list [pages]="pages$ | async"
                        (selectedElement)="setSelectedPage($event)">
  </app-select-from-list>
  <label
    class="assign-all-box"
    nz-checkbox
    [(ngModel)]="assignToAllOperators">
    {{'assign.all.members.to.this.channel' | i18next}}
  </label>
</ng-container>

<ng-container *ngIf="currentStep === 5">
  <div class="subtitle">
    {{ 'instagram-step6-first-subtitle' | i18next }}
  </div>
  <div class="instruction">
    <p>{{ 'instagram-step6-first-instruction-title' | i18next }}</p>
  </div>
  <div class="subtitle subtitle--switch">
    <span>{{ 'instagram-step6-second-subtitle' | i18next }}</span>
    <nz-switch
      [(ngModel)]="isDisplayCommentsEnabled"
      (ngModelChange)="updateDisplayCommentsFeature()"
      [nzLoading]="isLoading"
      [nzDisabled]="isLoading"
    >
    </nz-switch>
  </div>
  <div class="instruction">
    <p>{{ 'instagram-step6-second-instruction-title' | i18next }}</p>
  </div>
</ng-container>

<div *nzModalFooter>
  <ng-container *ngIf="currentStep === 0">
    <button
      class="footer_btn"
      (click)="nextStep()"
      nz-button
      nzType="primary"
    >
      {{ 'continue' | i18next }}
    </button>
  </ng-container>
  <ng-container *ngIf="currentStep === 1 || currentStep === 2">
    <button
      class="footer_btn"
      (click)="previousStep()"
      nz-button
      nzType="default"
    >
      {{ 'back' | i18next }}
    </button>
    <button
      class="footer_btn"
      (click)="nextStep()"
      nz-button
      nzType="primary"
    >
      {{ 'continue' | i18next }}
    </button>
  </ng-container>
  <ng-container *ngIf="currentStep === 3">
    <button
      class="footer_btn"
      (click)="previousStep()"
      nz-button
      nzType="default"
    >
      {{ 'back' | i18next }}
    </button>
    <button
      class="footer_btn"
      (click)="openFacebookWindow()"
      nz-button
      nzType="primary"
    >
      <i nz-icon nzType="facebook" nzTheme="fill"></i>{{ 'login-with-facebook' | i18next }}
    </button>
  </ng-container>
  <ng-container *ngIf="currentStep === 4">
    <button
      class="footer_btn"
      (click)="connect()"
      nz-button
      nzType="primary"
      [disabled]="selectedPage === null"
    >
      {{ 'connect' | i18next }}
    </button>
  </ng-container>
  <ng-container *ngIf="currentStep === 5">
    <button
      class="footer_btn"
      (click)="finish()"
      nz-button
      nzType="primary"
    >
      {{ 'instagram-step6-finish-button' | i18next }}
    </button>
  </ng-container>
</div>
