import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WEBSOCKET_EVENT_TYPE } from '@src/constants/websocket-event-type.constants';
import { Contact } from '@app/model/contact';
import { Message } from '@app/graphql/graphql';
import { ChangedChatPreviewGql } from '@app/model/changed-chat-preview-gql';
import { ChatArchivedEvent } from '@app/model/chat-archived-event';
import { EventByOperatorHimselfWsDto } from '@app/model/event-by-operator-himself-ws-dto';
import { ChatMessageCountersUpdate } from '@app/model/chat-message-counters-update';
import { OldMessage } from '../model/old-message';
import { WebsocketIoService } from '../websocket-io.service';
import { WsEventBody } from '../model/ws-event-body';

// TODO: use data instead of graphQlData in events that return ChangedChatPreviewGql
@Injectable({ providedIn: 'root' })
export class WebsocketIoMessageService {
  constructor(private websocketService: WebsocketIoService) {
  }

  onProcessingMessage(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageProcessing);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onProcessingMessageAll(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageProcessingAll);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onDeliveredMessage(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageDelivered);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onFailedMessage(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageFailed);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onInboxMessage(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageNew);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onInboxMessageAll(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageNewAll);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onSystemMessage(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageSystem);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onMessageChanged(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageChanged);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onReactionChangedMessage(chatId: number): Observable<Message> {
    return merge(this.onMessageReacted(),
      this.onMessageUnReacted())
      .pipe(filter((message: Message) => +message.chat.id === chatId));
  }

  onMessageUnReacted(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageUnreacted);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onMessageReacted(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageReacted);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onReadMessage(chatId: number): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageRead);

    return event.pipe(
      filter((dto: WsEventBody) => +dto.graphQlData?.chat?.id === chatId),
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onReadMessageAll(): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageRead);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onArchiveChat(): Observable<ChatArchivedEvent> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.archived);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTakenFromNew(): Observable<OldMessage> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.takenFromNew);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onMarkedAsSpam(): Observable<OldMessage> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.markedAsSpam);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatTakenFromNewByCurrentOperator():
    Observable<EventByOperatorHimselfWsDto> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.takenFromNewByCurrentOperator);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onChatCompletedByCurrentOperator():
    Observable<EventByOperatorHimselfWsDto> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.completedByCurrentOperator);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onMessageReadByCurrentOperator():
    Observable<EventByOperatorHimselfWsDto> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.messageReadByCurrentOperator);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onMessengerUserContact(): Observable<Contact> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.contactSubmitted);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onTakenFromVirtualOperator(): Observable<ChangedChatPreviewGql> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.takenFromVirtualOperator);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onAutomaticComplete(): Observable<ChangedChatPreviewGql> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.automaticComplete);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onChatMessageCountersUpdated(): Observable<ChatMessageCountersUpdate> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatMessageCountersUpdated);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatCategoriesChangedNew(): Observable<ChangedChatPreviewGql> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.categoriesChangedNew);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onChatCategoriesChangedProcessing(): Observable<ChangedChatPreviewGql> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.categoriesChangedProcessing);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
    );
  }

  onChatCategoriesChangedAll(): Observable<ChangedChatPreviewGql> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.categoriesChangedAll);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatCategoriesChangedAllByChatId(chatId: string) {
    return this.onChatCategoriesChangedAll()
      .pipe(
        filter((chatPreview) => Number(chatPreview.id) === Number(chatId)),
      );
  }

  onChatCategoriesChangedProcessingByChatId(chatId: string) {
    return this.onChatCategoriesChangedProcessing()
      .pipe(
        filter((chatPreview) => Number(chatPreview.id) === Number(chatId)),
      );
  }
}
