import { Tab } from '@app/graphql/graphql';
import { MultiselectValue } from '@app/common/model/multiselect.value';
import { FragmentState } from '@app/common/extends/open-close-on-fragment-change.service';
import {
  ChatPreviewFilterInputParameters,
} from './model/chat-preview-filter-input-parameters';
import { ChatStatusOptionValue } from './model/chat-status-option';
import { ChatCategoryOptionValue } from './model/chat-category-option';

export const FRAGMENT_STATE: FragmentState = {
  open: 'filter',
  close: null,
};

export const CHAT_FILTER_CONTROL_NAME = {
  chatStatus: 'chatStatus',
  chatOperatorIds: 'chatOperatorIds',
  chatCategories: 'chatCategories',
  createDate: 'createDate',
  channelIds: 'channelIds',
};

export const UNAVAILABLE_PARAMETERS_WHILE_SEARCHING = [
  CHAT_FILTER_CONTROL_NAME.chatCategories,
];

export const FILTER_PER_TAB: [Tab, ChatPreviewFilterInputParameters][] = [
  [
    Tab.All, {
      [CHAT_FILTER_CONTROL_NAME.chatStatus]: ChatStatusOptionValue.ALL,
      [CHAT_FILTER_CONTROL_NAME.chatOperatorIds]: MultiselectValue.ALL,
      [CHAT_FILTER_CONTROL_NAME.chatCategories]:
        JSON.stringify(ChatCategoryOptionValue.ALL),
      [CHAT_FILTER_CONTROL_NAME.createDate]: [],
      [CHAT_FILTER_CONTROL_NAME.channelIds]: MultiselectValue.ALL,
    },
  ],
  [
    Tab.MyTasks, {
      [CHAT_FILTER_CONTROL_NAME.chatCategories]:
        JSON.stringify(ChatCategoryOptionValue.ALL),
      [CHAT_FILTER_CONTROL_NAME.channelIds]: MultiselectValue.ALL,
    },
  ],
  [
    Tab.New, {
      [CHAT_FILTER_CONTROL_NAME.chatCategories]:
        JSON.stringify(ChatCategoryOptionValue.ALL),
      [CHAT_FILTER_CONTROL_NAME.channelIds]: MultiselectValue.ALL,
    },
  ],
];

export const stateFragmentMap = new Map<boolean, string>([
  [true, FRAGMENT_STATE.open],
  [false, FRAGMENT_STATE.close],
]);
