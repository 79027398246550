import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BotService } from '@app/bot.service';
import { ChannelFragment } from '@app/graphql/graphql';
import { Bot } from './model/bot';
import { ChannelsService } from './settings/channels-page/channels.service';

@Injectable({ providedIn: 'root' })
export class ChannelStorageService {
  private readonly loaded = new BehaviorSubject(false);

  private readonly assignedToOperatorChannelsLoaded
      = new BehaviorSubject(false);

  private channelsSubject = new BehaviorSubject<Bot[]>([]);

  private assignedToOperatorChannelsSubject
      = new BehaviorSubject<ChannelFragment[]>([]);

  channels$: Observable<Bot[]> = this.channelsSubject.asObservable();

  constructor(
      private botService: BotService,
      private channelsService: ChannelsService,
  ) {
  }

  load(): Observable<Bot[]> {
    if (!this.loaded.value) {
      return this.botService.getAssignedBots()
        .pipe(
          tap((channels) => {
            this.channelsSubject.next(channels);
            this.loaded.next(true);
          }),
        );
    }

    return new Observable<Bot[]>((observer) => {
      observer.next(this.channelsSubject.value);
      observer.complete();
    });
  }

  getAssignedToOperatorChannels(): Observable<ChannelFragment[]> {
    if (!this.assignedToOperatorChannelsLoaded.value) {
      return this.channelsService.getAssignedOnly()
        .pipe(
          tap(
            (channels) => {
              this.assignedToOperatorChannelsSubject.next(channels);
              this.assignedToOperatorChannelsLoaded.next(true);
            },
          ),
        );
    }

    return new Observable<ChannelFragment[]>(
      (observer) => {
        observer.next(this.assignedToOperatorChannelsSubject.value);
        observer.complete();
      },
    );
  }
}
