import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessengerType } from '@app/graphql/graphql';
import { environment } from '../environments/environment';

const CHANNEL_IDS_PARAM_NAME = 'channelIds';

@Injectable({ providedIn: 'root' })
export class ChannelLinkGenerationService {
  private readonly channelLinksUrl = 'bots/links';

  constructor(private http: HttpClient) {
  }

  generate(channelIds: number[]): Observable<ChannelLink[]> {
    return this.http.get<ChannelLink[]>(
      `${environment.apiBaseUrl}${this.channelLinksUrl}`,
      {
        params: new HttpParams()
          .set(CHANNEL_IDS_PARAM_NAME, `${channelIds}`),
      },
    );
  }
}

export interface ChannelLink {
  id: number;
  link: string;
  messenger: MessengerType;
}
