import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { I18NextModule } from 'angular-i18next';
import { NoWifiIconModule } from '@app/icons/no-wifi-icon/no-wifi-icon.module';
import { UpdateDataIconModule } from '@app/icons/update-data-icon/update-data-icon.module';
import { InternetStatusTrackerComponent } from './internet-status-tracker.component';

@NgModule({
  declarations: [
    InternetStatusTrackerComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NoWifiIconModule,
    UpdateDataIconModule,
    I18NextModule,
  ],
  exports: [
    InternetStatusTrackerComponent,
  ],
  providers: [
  ],
})
export class InternetStatusTrackerModule {

}
