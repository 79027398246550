import { Component, HostListener } from '@angular/core';
import {
  map, Observable,
} from 'rxjs';
import { AttachmentViewService } from '@app/attachment-view/attachment-view.service';
import { File } from '@app/graphql/graphql';
import {
  openCloseAnimation,
} from '@app/attachment-view/animation/attachment-view.animation';
import { AttachmentViewUtilityButton } from '@app/attachment-view/model/attachment-view-utility-button';
import { AttachmentViewUtil } from '@app/attachment-view/attachment-view.util';
import { Position } from '@app/common/model/position';
import { FileService } from '@app/file.service';

const BACKGROUND_ELEMENT_CLASS_NAME = 'focusout';

@Component({
  selector: 'app-attachment-view',
  templateUrl: './attachment-view.component.html',
  styleUrls: ['./attachment-view.component.scss'],
  animations: [
    openCloseAnimation,
  ],
})
export class AttachmentViewComponent {
  readonly isOpen$ = this.attachmentViewService.isOpen$;

  readonly file$ = this.attachmentViewService.file$;

  readonly files$ = this.attachmentViewService.files$;

  readonly modification$ = this.attachmentViewUtil.modification$;

  readonly utilityButtons: AttachmentViewUtilityButton[]
    = this.getUtilityButtons();

  constructor(
    private attachmentViewService: AttachmentViewService,
    private attachmentViewUtil: AttachmentViewUtil,
    private fileService: FileService,
  ) {
  }

  @HostListener('document:keydown.escape', ['$event'])
  close($event?: MouseEvent): void {
    if (this.attachmentViewService.isClosed()) {
      return;
    }

    $event?.preventDefault();
    $event?.stopPropagation();
    this.attachmentViewService.close();
    this.attachmentViewUtil.resetModification();
  }

  isLastFromLeft(): Observable<boolean> {
    return this.attachmentViewService.isLastFromLeft();
  }

  isLastFromRight(): Observable<boolean> {
    return this.attachmentViewService.isLastFromRight();
  }

  @HostListener('document:keydown.arrowright', ['$event'])
  moveToNext($event?: MouseEvent): void {
    if (this.attachmentViewService.isClosed()) {
      return;
    }

    $event?.preventDefault();
    $event?.stopPropagation();
    this.attachmentViewService.selectNextFile();
  }

  @HostListener('document:keydown.arrowleft', ['$event'])
  moveToPrevious($event?: MouseEvent): void {
    if (this.attachmentViewService.isClosed()) {
      return;
    }

    $event?.preventDefault();
    $event?.stopPropagation();
    this.attachmentViewService.selectPreviousFile();
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  zoomOut($event?: MouseEvent): void {
    if (this.attachmentViewService.isClosed()) {
      return;
    }

    $event?.preventDefault();
    $event?.stopPropagation();
    this.attachmentViewUtil.zoomOut();
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  zoomIn($event?: MouseEvent): void {
    if (this.attachmentViewService.isClosed()) {
      return;
    }

    $event?.preventDefault();
    $event?.stopPropagation();
    this.attachmentViewUtil.zoomIn();
  }

  @HostListener('click', ['$event'])
  clickOut($event: MouseEvent): void {
    const target = $event.target as HTMLElement;

    if (target.classList.contains(BACKGROUND_ELEMENT_CLASS_NAME)) {
      this.close();
    }
  }

  isSelected(file: File): Observable<boolean> {
    return this.file$.pipe(
      map((selectedFile) => selectedFile.id === file.id),
    );
  }

  select(file: File): void {
    this.attachmentViewService.selectFile(file);
  }

  private getUtilityButtons(): AttachmentViewUtilityButton[] {
    return [
      {
        iconName: 'download',
        handleClick: () => this.attachmentViewUtil.downloadFile(
          this.attachmentViewService.getCurrentFile(),
        ),
      },
      {
        iconName: 'rotate-right',
        handleClick: () => this.attachmentViewUtil.rotateRight(),
      },
      {
        iconName: 'rotate-left',
        handleClick: () => this.attachmentViewUtil.rotateLeft(),
      },
      {
        iconName: 'zoom-out',
        handleClick: () => this.zoomOut(),
      },
      {
        iconName: 'zoom-in',
        handleClick: () => this.zoomIn(),
      },
    ];
  }

  updatePosition(imagePosition: Position): void {
    this.attachmentViewUtil.move(imagePosition);
  }

  isImageFile(file: File): boolean {
    return this.fileService.isImageFile(file);
  }

  isVideoFile(file: File): boolean {
    return this.fileService.isVideoFile(file);
  }
}
