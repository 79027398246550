import { Component, Input } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';

const COPIED_EVENT_TIMEOUT = 3000;
const CHECK_CIRCLE_ICON_NAME = 'check-circle';
const COPY_ICON_NAME = 'copy';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {
  @Input() initialTextSlug = 'copy_button.copy';

  @Input() onCopiedTextSlug = 'copy_button.copied';

  @Input() initialTextOld = 'Copy';

  @Input() onCopiedTextOld = 'Copied!';

  @Input() textToCopy: string;

  private readonly isCopied: BehaviorSubject<boolean>
    = new BehaviorSubject<boolean>(false);

  readonly isCopied$: Observable<boolean> = this.isCopied.asObservable();

  readonly iconName$: Observable<string> = this.isCopied$.pipe(
    map((isCopied) => (isCopied
      ? CHECK_CIRCLE_ICON_NAME
      : COPY_ICON_NAME)),
  );

  readonly buttonText$: Observable<string> = this.isCopied$.pipe(
    map((isCopied) => (isCopied
      ? this.onCopiedTextSlug
      : this.initialTextSlug)),
  );

  constructor(
    private clipboard: Clipboard,
  ) {
  }

  copy(): void {
    this.isCopied.next(true);
    this.clipboard.copy(this.textToCopy);

    setTimeout(
      () => this.isCopied.next(false),
      COPIED_EVENT_TIMEOUT,
    );
  }
}
