import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bot } from '@app/model/bot';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BotService {
  private baseUrl = environment.apiBaseUrl;

  private assignedAppendix = '/assigned';

  private botAppendix = 'bots';

  constructor(private http: HttpClient) {
  }

  getAssignedBots(): Observable<Bot[]> {
    return this.http.get<Bot[]>(`${this.baseUrl + this.botAppendix + this.assignedAppendix}`);
  }

  getAllBots(): Observable<Bot[]> {
    return this.http.get<Bot[]>(`${this.baseUrl + this.botAppendix}`);
  }
}
