<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_13231_371524)">
    <g clip-path="url(#clip1_13231_371524)">
      <path d="M2.17678 20.1769C2.07915 20.0793 2.07915 19.921 2.17678 19.8233L6.56503 15.4351L6.37815 15.2124C6.34226 15.1693 6.32445 15.114 6.32845 15.0581C6.33244 15.0022 6.35793 14.9499 6.39958 14.9124C6.99455 14.3946 7.66081 13.9576 8.38176 13.6184L12.0858 9.91433L12.0273 9.91419C9.16386 9.91419 6.53619 10.9374 4.49244 12.6356C4.40404 12.7106 4.26744 12.6999 4.19244 12.6088L3.22815 11.4597C3.19176 11.4159 3.17415 11.3596 3.17917 11.3029C3.18419 11.2462 3.21142 11.1937 3.25494 11.157C5.63351 9.17758 8.68976 7.98561 12.0273 7.98561C12.6589 7.98561 13.2804 8.0283 13.8892 8.11094L16.3391 5.66102C14.9466 5.29031 13.4834 5.09276 11.9737 5.09276C7.93708 5.09276 4.23261 6.50436 1.32101 8.86418C1.23261 8.93651 1.09869 8.92311 1.02369 8.83472L0.0593995 7.68561C0.0409485 7.66378 0.0270531 7.63847 0.0185314 7.61118C0.0100097 7.5839 0.00703408 7.55518 0.00977973 7.52673C0.0125254 7.49827 0.0209367 7.47065 0.0345186 7.4455C0.0481005 7.42034 0.0665784 7.39816 0.0888639 7.38026C3.33261 4.74454 7.46833 3.16419 11.9737 3.16419C14.0398 3.16419 16.0285 3.49667 17.8891 4.11099L21.6222 0.377911C21.7198 0.28028 21.8781 0.280279 21.9758 0.377911L23.0364 1.43857C23.1088 1.51093 23.1275 1.6166 23.0926 1.7062L3.50507 21.2938C3.41546 21.3286 3.30979 21.3099 3.23744 21.2376L2.17678 20.1769Z" fill="#F5F5F5"/>
      <path d="M11.8281 14.7385C11.8942 14.7366 11.9606 14.7356 12.0273 14.7356C13.7067 14.7356 15.2389 15.357 16.4067 16.3829C16.4978 16.4606 16.6344 16.4499 16.7121 16.3588L17.6764 15.2097C17.7514 15.1213 17.7407 14.9874 17.6523 14.9097C16.5258 13.9296 15.1413 13.2389 13.6133 12.9533L11.8281 14.7385Z" fill="#F5F5F5"/>
      <path d="M15.9746 10.592C17.2938 11.061 18.5049 11.7582 19.5594 12.6356C19.6505 12.7106 19.7844 12.6999 19.8594 12.6088L20.8237 11.4597C20.8987 11.3686 20.888 11.232 20.7969 11.157C19.796 10.3231 18.6743 9.62899 17.4617 9.10485L15.9746 10.592Z" fill="#F5F5F5"/>
      <path d="M19.6413 6.92527C20.7299 7.47946 21.7506 8.14798 22.688 8.91508C22.779 8.99008 22.913 8.97669 22.988 8.88561L23.9523 7.73651C24.0299 7.64544 24.0165 7.50883 23.9228 7.43383C23.0354 6.70575 22.0801 6.05706 21.0679 5.49867L19.6413 6.92527Z" fill="#F5F5F5"/>
      <path d="M10.2862 19.1285C10.2862 19.5831 10.4668 20.0192 10.7883 20.3407C11.1098 20.6621 11.5458 20.8428 12.0005 20.8428C12.4551 20.8428 12.8912 20.6621 13.2127 20.3407C13.5341 20.0192 13.7148 19.5831 13.7148 19.1285C13.7148 18.6738 13.5341 18.2378 13.2127 17.9163C12.8912 17.5948 12.4551 17.4142 12.0005 17.4142C11.5458 17.4142 11.1098 17.5948 10.7883 17.9163C10.4668 18.2378 10.2862 18.6738 10.2862 19.1285Z" fill="#F5F5F5"/>
    </g>
  </g>
  <defs>
    <clipPath id="clip0_13231_371524">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
    <clipPath id="clip1_13231_371524">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
