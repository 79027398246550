import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddChannelModalService {
  private readonly showModalSubject: BehaviorSubject<boolean>
      = new BehaviorSubject<boolean>(false);

  readonly showModal$: Observable<boolean>
      = this.showModalSubject.asObservable();

  showModal() {
    this.showModalSubject.next(true);
  }
}
