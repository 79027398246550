import { Injectable } from '@angular/core';
import { mergeWith, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { WEBSOCKET_EVENT_TYPE } from '@src/constants/websocket-event-type.constants';
import {
  ChatPreviewFragment,
  InnerOperatorRequest,
  InnerOperatorRequestStatus,
  Message,
} from '@app/graphql/graphql';
import {
  InnerOperatorRequestCacheService,
} from '@app/gql-service/inner-operator-request/inner-operator-request-cache.service';
import { SoundNotificationService } from '@app/service/sound-notification.service';
import { WebsocketIoMessageService } from '@app/websocket/websocket-io-message.service';
import { PageRouteService } from '@app/page-route.service';
import { InnerOperatorRequestService } from '@app/gql-service/inner-operator-request/inner-operator-request.service';
import { NotificationService } from '@app/service/notification.service';
import { ChatInvitationUpdateWsDto } from '@app/model/dto/inner.operator.request/chat-invitation-update-ws-dto';
import { LocalStorageService } from '@app/local-storage-service';
import { MessageUtilService } from '@app/util/message-util.service';
import {
  ChatInvitationCounterResetWsDto,
} from '@app/model/dto/inner.operator.request/chat-invitation-counter-reset-ws-dto';
import { WsEventHandleService } from '@app/service/ws-event-handle.service';
import { ChatPreviewServiceGql } from '@app/gql-service/chat-preview-service-gql.service';
import { WsEventBody } from '../model/ws-event-body';
import { WebsocketIoService } from '../websocket-io.service';

@Injectable({ providedIn: 'root' })
export class InviteToChatWsService {
  constructor(
    private messageUtilService: MessageUtilService,
    private pageRouteService: PageRouteService,
    private localStorageService: LocalStorageService,
    private websocketService: WebsocketIoService,
    private websocketIoMessageService: WebsocketIoMessageService,
    private soundNotificationService: SoundNotificationService,
    private innerOperatorRequestService: InnerOperatorRequestService,
    private notificationService: NotificationService,
    private innerOperatorRequestCacheService: InnerOperatorRequestCacheService,
    private wsEventHandleService: WsEventHandleService,
    private chatPreviewServiceGql: ChatPreviewServiceGql,
  ) {
    this.subscribeOnWsOnRequestsLoaded(InnerOperatorRequestStatus.Requested);
    this.subscribeOnWsOnRequestsLoaded(InnerOperatorRequestStatus.Accepted);
    this.innerOperatorRequestService.onLoaded(
      InnerOperatorRequestStatus.Requested,
    ).subscribe((): void => {
      this.wsEventHandleService.handleEvents({
        [WEBSOCKET_EVENT_TYPE.chatInvitationUpdated]:
          this.handleChatInvitationUpdated.bind(this),
        [WEBSOCKET_EVENT_TYPE.chatInvitationRequested]:
          this.handleInvitationRequestedEvent.bind(this),
      }).subscribe();
    });
  }

  onChatInvitationCreatedInfoMessage(chatId?: string): Observable<Message> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatInvitationCreated);

    return event.pipe(
      map((dto: WsEventBody) => dto.graphQlData),
      filter((message) => !chatId
        || Number(message.chat.id) === Number(chatId)),
    );
  }

  onChatInvitationRequested(): Observable<InnerOperatorRequest> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatInvitationRequested);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  getPrivateMessage(chatId: string): Observable<Message> {
    return this.websocketIoMessageService.onProcessingMessage()
      .pipe(
        filter(this.messageUtilService.isFromChat
          .bind(this.messageUtilService, chatId)),
        filter(this.messageUtilService.isPrivateMessage
          .bind(this.messageUtilService)),
      );
  }

  onChatInvitationCanceled(
    chatId?: string,
  ): Observable<ChatInvitationUpdateWsDto> {
    return this.onChatInvitationUpdatedByChatId(
      InnerOperatorRequestStatus.Canceled,
      chatId,
    );
  }

  onChatInvitationAccepted(
    chatId?: string,
  ): Observable<ChatInvitationUpdateWsDto> {
    return this.onChatInvitationUpdatedByChatId(
      InnerOperatorRequestStatus.Accepted,
      chatId,
    );
  }

  onChatInvitationRejected(
    chatId?: string,
  ): Observable<ChatInvitationUpdateWsDto> {
    return this.onChatInvitationUpdatedByChatId(
      InnerOperatorRequestStatus.Rejected,
      chatId,
    );
  }

  onChatInvitationCounterReset(): Observable<ChatInvitationCounterResetWsDto> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatInvitationCounterReset);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  onChatInvitationUpdated(): Observable<ChatInvitationUpdateWsDto> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.chatInvitationUpdated);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }

  private onChatInvitationUpdatedByChatId(
    filterStatus: InnerOperatorRequestStatus,
    chatId: string,
  ): Observable<ChatInvitationUpdateWsDto> {
    return this.onChatInvitationUpdated().pipe(
      filter((request: ChatInvitationUpdateWsDto) => request
        .innerOperatorRequest.status === filterStatus),
      filter((request: ChatInvitationUpdateWsDto) => !chatId || Number(request
        .innerOperatorRequest.chat.id) === Number(chatId)),
    );
  }

  private subscribeOnWsOnRequestsLoaded(
    status: InnerOperatorRequestStatus,
  ): void {
    this.innerOperatorRequestService.onLoaded(status)
      .subscribe(() => this.handleMessageProcessingWsEvent(status).subscribe());
  }

  private handleMessageProcessingWsEvent(
    status: InnerOperatorRequestStatus,
  ): Observable<Message> {
    return this.websocketIoMessageService
      .onProcessingMessageAll()
      .pipe(
        mergeWith(this.websocketIoMessageService.onInboxMessageAll()),
        tap(this.innerOperatorRequestCacheService.updateChatLastMessageByStatus
          .bind(this.innerOperatorRequestCacheService, status)),
      );
  }

  private handleInvitationRequestedEvent(body: WsEventBody): void {
    this.chatPreviewServiceGql.findChatPreviewByChatId(body.data.chat.id)
      .subscribe((chatPreview: ChatPreviewFragment): void => {
        this.innerOperatorRequestCacheService.addRequestToRequested({
          ...body.data,
          id: String(body.data.id),
          chat: chatPreview,
          operator: chatPreview.chatOperator?.operator,
        });
      });
  }

  private handleChatInvitationUpdated(body: WsEventBody): void {
    const chatInvitationUpdateWsDto: ChatInvitationUpdateWsDto = body.data;

    ({
      [InnerOperatorRequestStatus.Accepted]: this.handleAcceptInvite.bind(this),
      [InnerOperatorRequestStatus.Rejected]: this.handleRejectInvite.bind(this),
      [InnerOperatorRequestStatus.Canceled]: this.handleCancelInvite.bind(this),
      [InnerOperatorRequestStatus.Closed]: this.handleClosedInvite.bind(this),
    })[chatInvitationUpdateWsDto.innerOperatorRequest.status](
      String(chatInvitationUpdateWsDto.innerOperatorRequest.id),
      chatInvitationUpdateWsDto.innerOperatorRequest.chat.id,
    );
  }

  private handleClosedInvite(
    requestId: string,
    chatId: string,
  ): void {
    this.innerOperatorRequestCacheService
      .removeRequestFromAccepted(
        requestId,
        () => this.closeChatIfNeeded(chatId),
      );
    this.innerOperatorRequestCacheService
      .removeRequestFromRequested(requestId);
  }

  private handleCancelInvite(
    requestId: string,
    chatId: string,
  ): void {
    this.innerOperatorRequestCacheService
      .removeRequestFromRequested(
        requestId,
        () => this.closeChatIfNeeded(chatId),
      );
  }

  private handleRejectInvite(
    requestId: string,
    chatId: string,
  ): void {
    this.innerOperatorRequestCacheService
      .removeRequestFromRequested(
        requestId,
        () => this.closeChatIfNeeded(chatId),
      );
  }

  private handleAcceptInvite(requestId: string): void {
    this.innerOperatorRequestCacheService
      .removeRequestFromRequested(
        requestId,
        (
          requestedRequests: InnerOperatorRequest[],
        ): void => {
          this.innerOperatorRequestCacheService
            .addRequestToAccepted(
              requestId,
              requestedRequests,
            );
        },
      );
  }

  private closeChatIfNeeded(chatId: string): void {
    if (this.pageRouteService.isCurrentChatPage(chatId)) {
      this.pageRouteService.moveToCurrentChatsPage();
    }
  }
}
