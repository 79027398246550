<div
  class="loading-background"
  *ngIf="loadingService.loading$ | async"
  [style.opacity]="backgroundOpacity"
></div>
<nz-spin
  class="spinner"
  *ngIf="loadingService.loading$ | async"
  [style.top]="top"
  [nzSize]="'large'"
  nzSimple
></nz-spin>
