import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { ROUTE_URL } from '@app/router-url.constants';
import { PageRouteService } from '@app/page-route.service';
import { ReferralCodeDetectionService } from '@app/service/referral/referral-code-detection.service';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  private loginUrl = ROUTE_URL.login;

  private signUpUrl = ROUTE_URL.signUp;

  private signUpChannelsUrl = ROUTE_URL.signUpChannels;

  constructor(
    @Inject(TokenStorageService)
    private tokenStorageService: TokenStorageService,
    private pageRouteService: PageRouteService,
    private referralCodeDetectionService: ReferralCodeDetectionService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    this.referralCodeDetectionService.registerIfExist(next);

    const hasToken = this.tokenStorageService.getAccessToken().length !== 0;

    if (this.isForbiddenUrlForUserWithToken(state.url) && !hasToken) {
      return true;
    }

    if (hasToken) {
      if (this.isForbiddenUrlForUserWithToken(state.url)) {
        this.pageRouteService.moveToInboxPage();

        return false;
      }

      return true;
    }

    this.pageRouteService.moveToLoginPage();

    return false;
  }

  private isForbiddenUrlForUserWithToken(url: string) {
    return url.startsWith(this.loginUrl)
      || (url.startsWith(this.signUpUrl)
        && !url.startsWith(this.signUpChannelsUrl));
  }
}
