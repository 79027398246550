import { Component, OnInit } from '@angular/core';
import {
  fromEvent, merge, Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '@app/local-storage-service';
import { NetworkRefreshEventService } from '@app/amplitude-event-service/network-refresh-event.service';
import { PageRouteService } from '@app/page-route.service';

export type InternetStatus = {
  titleSlug: string;
  detailsSlug: string;
  isOffline: boolean;
};

@Component({
  selector: 'app-internet-status-tracker',
  templateUrl: './internet-status-tracker.component.html',
  styleUrls: ['./internet-status-tracker.component.scss'],
})
export class InternetStatusTrackerComponent implements OnInit {
  connectionData$: Observable<InternetStatus>;

  constructor(
    private localStorageService: LocalStorageService,
    private networkRefreshEventService: NetworkRefreshEventService,
    private pageRouteService: PageRouteService,
  ) {
  }

  ngOnInit(): void {
    const offline$ = fromEvent(window, 'offline');
    const online$ = fromEvent(window, 'online');

    this.connectionData$ = merge(offline$, online$).pipe(
      map((value) => {
        if (value.type === 'offline') {
          return {
            titleSlug: 'internet_status_indicator.currently_offline',
            detailsSlug: 'internet_status_indicator.please_check_connection',
            isOffline: true,
          };
        }

        return {
          titleSlug: 'internet_status_indicator.connection_lost',
          detailsSlug: 'internet_status_indicator.please_refresh',
          isOffline: false,
        };
      }),
    );
  }

  refreshPage(): void {
    this.networkRefreshEventService.sendNetworkRefreshPageEvent();
    this.pageRouteService.reload();
  }
}
