import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { LoadingService } from '@app/loading/loading.service';
import { BillingDetailService } from '@app/gql-service/billing-detail.service';
import { FormValidationService } from '@app/form-validation-service';
import { BillingDetail, BillingDetailType } from '@app/graphql/graphql';
import { ErrorTipProviderService } from '@app/service/error-tip-provider.service';
import { Country, CountryService } from '@app/country.service';

export const FIELD_NAME = {
  email: 'email',
  country: 'country',
  address: 'address',
};

const EMAIL_MAX_LENGTH = 100;
const ADDRESS_MAX_LENGTH = 100;

@Component({
  selector: 'app-billing-details-form',
  templateUrl: './billing-details-form.component.html',
  styleUrls: ['./billing-details-form.component.scss'],
})
export class BillingDetailsFormComponent implements OnInit {
  @Input() isBillingDetailsEditorPage = true;

  @Output() formGroupEmitter= new EventEmitter<FormGroup>();

  @Output() billingDetailsEmitter = new EventEmitter<BillingDetail[]>();

  mode: string;

  formGroup: FormGroup;

  countries: Country[] = [];

  billingDetails: BillingDetail[];

  constructor(
    private loadingService: LoadingService,
    private billingDetailService: BillingDetailService,
    private countryService: CountryService,
    private errorTipProviderService: ErrorTipProviderService,
    private formValidationService: FormValidationService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.loadingService.loadingOn();
    this.countries = this.countryService.getCountries();
    this.billingDetailService.getBillingDetails()
      .pipe(
        first(),
      )
      .subscribe((billingDetails) => {
        this.billingDetails = billingDetails;
        this.billingDetailsEmitter.emit(billingDetails);
        this.initFormGroup();
        this.loadingService.loadingOff();
      });
  }

  getErrorTip(inputField: string): string {
    return this.errorTipProviderService
      .getErrorTip(this.formGroup, inputField);
  }

  private initFormGroup() {
    this.formGroup = this.formBuilder.group({
      email: [
        this.billingDetailService.getBillingDetailValueByTypeIn(
          BillingDetailType.Email,
          this.billingDetails,
        ), [
          this.formValidationService.required(),
          this.formValidationService.validEmail(
            'validation.not_valid_email.default_message',
            true,
          ),
          this.formValidationService.maxLength(EMAIL_MAX_LENGTH),
        ]],
      country: [this.billingDetailService.getBillingDetailValueByTypeIn(
        BillingDetailType.Country,
        this.billingDetails,
      ), [this.formValidationService.required()]],
      address: [this.billingDetailService.getBillingDetailValueByTypeIn(
        BillingDetailType.Address,
        this.billingDetails,
      ), [
        this.formValidationService.required(),
        this.formValidationService.noWhitespaceValidatorFunction(),
        this.formValidationService.maxLength(ADDRESS_MAX_LENGTH),
      ]],
    });

    this.formGroupEmitter.emit(this.formGroup);
  }

  protected readonly FIELD_NAME = FIELD_NAME;
}
