import { Injectable } from '@angular/core';
import {
  BehaviorSubject, map, Observable, tap, withLatestFrom,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { File } from '@app/graphql/graphql';
import { FileService } from '@app/file.service';

const ATTACHMENT_ID_QUERY_PARAMETER = 'attachment-id';

@Injectable({
  providedIn: 'root',
})
export class AttachmentViewService {
  private readonly state = new BehaviorSubject(AttachmentViewState.CLOSED);

  private readonly file = new BehaviorSubject<File>(null);

  private readonly files = new BehaviorSubject<File[]>([]);

  private fileScopes: File[][] = [];

  readonly isOpen$: Observable<boolean> = this.state.asObservable().pipe(
    map((state) => state === AttachmentViewState.OPEN),
  );

  readonly file$: Observable<File> = this.file.asObservable();

  readonly files$: Observable<File[]> = this.files.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private router: Router,
  ) {
  }

  setFileScopes(fileScopes: File[][]): void {
    this.fileScopes = fileScopes
      .map(
        (files) => files
          .filter((file) => this.isMediaFormat(file)),
      ).filter(
        (files) => files.length,
      );
  }

  openAttachmentByQueryParameters(): Observable<void> {
    return this.activatedRoute.queryParams.pipe(
      filter((params) => !!params[ATTACHMENT_ID_QUERY_PARAMETER]),
      map((params) => params[ATTACHMENT_ID_QUERY_PARAMETER]),
      tap(this.findFileAndOpen.bind(this)),
    );
  }

  private findFileAndOpen(fileId: string): void {
    if (!this.fileScopes?.length) {
      return;
    }

    const foundFiles: File[] = this.fileScopes
      .find((files: File[]) => files.some((file: File) => file.id === fileId));
    const foundFile: File = foundFiles
      ?.find((file: File) => file.id === fileId);

    if (foundFile) {
      this.setFiles(foundFiles);
      this.open(foundFile);
    } else {
      this.updateAttachmentIdQueryParameter(null);
    }
  }

  setFiles(files: File[]): void {
    const validFiles: File[] = files
      .filter((file) => this.isMediaFormat(file));

    this.files.next(validFiles);
  }

  open(file: File): void {
    if (!this.isMediaFormat(file)) {
      return;
    }

    this.selectFile(file);
    this.state.next(AttachmentViewState.OPEN);
  }

  selectFile(file: File): void {
    this.updateAttachmentIdQueryParameter(
      file.id,
      () => this.file.next(file),
    );
  }

  selectNextFile(): void {
    const files = this.files.getValue();
    const file = this.file.getValue();
    const index = files.indexOf(file);

    if (index < files.length - 1) {
      this.selectFile(files[index + 1]);
    } else {
      if (!this.fileScopes.length) {
        return;
      }

      const fileScopeIndex = this.getFileScopeIndexByFile(file);

      if (fileScopeIndex < this.fileScopes.length - 1) {
        const nextFileScope = this.fileScopes[fileScopeIndex + 1];

        this.files.next(nextFileScope);
        this.selectFile(nextFileScope[0]);
      }
    }
  }

  selectPreviousFile(): void {
    const files = this.files.getValue();
    const file = this.file.getValue();
    const index = files.indexOf(file);

    if (index > 0) {
      this.selectFile(files[index - 1]);
    } else {
      if (!this.fileScopes.length) {
        return;
      }

      const fileScopeIndex = this.getFileScopeIndexByFile(file);

      if (fileScopeIndex > 0) {
        const previousFileScope = this.fileScopes[fileScopeIndex - 1];

        this.files.next(previousFileScope);
        this.selectFile(previousFileScope[previousFileScope.length - 1]);
      }
    }
  }

  close(): void {
    this.updateAttachmentIdQueryParameter(
      null,
      () => this.state.next(AttachmentViewState.CLOSED),
    );
  }

  isLastFromRight(): Observable<boolean> {
    return this.file$.pipe(
      withLatestFrom(this.files$),
      map(([file, files]) => {
        if (files[files.length - 1] === file) {
          if (!this.fileScopes.length) {
            return true;
          }

          const fileScopeIndex = this.getFileScopeIndexByFile(file);

          return fileScopeIndex === this.fileScopes.length - 1;
        }

        return false;
      }),
    );
  }

  isLastFromLeft(): Observable<boolean> {
    return this.file$.pipe(
      withLatestFrom(this.files$),
      map(([file, files]) => {
        if (files[0] === file) {
          if (!this.fileScopes.length) {
            return true;
          }

          const fileScopeIndex = this.getFileScopeIndexByFile(file);

          return fileScopeIndex === 0;
        }

        return false;
      }),
    );
  }

  getCurrentFile(): File {
    return this.file.getValue();
  }

  isClosed(): boolean {
    return this.state.getValue() === AttachmentViewState.CLOSED;
  }

  private getFileScopeIndexByFile(file: File): number {
    return this.fileScopes
      .findIndex((scope) => scope.includes(file));
  }

  isMediaFormat(file: File): boolean {
    return this.fileService.isImageFile(file)
      || this.fileService.isVideoFile(file);
  }

  private updateAttachmentIdQueryParameter(
    value: string,
    onUpdatedFunc?: () => void,
  ): void {
    const currentUrl = this.router.parseUrl(this.router.url);

    if (value) {
      currentUrl.queryParams = {
        ...currentUrl.queryParams,
        [ATTACHMENT_ID_QUERY_PARAMETER]: value,
      };
    } else {
      delete currentUrl.queryParams[ATTACHMENT_ID_QUERY_PARAMETER];
    }

    this.router.navigateByUrl(currentUrl)
      .finally(onUpdatedFunc);
  }
}

enum AttachmentViewState {
  OPEN='OPEN', CLOSED='CLOSED'
}
