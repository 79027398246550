import { MessengerProviderName } from '../graphql/graphql';

export interface ChannelType {
  type: Type;
  title: string;
  description: string;
  isPaid: boolean;
  providerName?: MessengerProviderName;
}

export enum Type {
  BOT = 'BOT',
  DIRECT = 'DIRECT',
}
