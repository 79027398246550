import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { RouterModule } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { UnsupportedPageComponent } from '@app/responsive/unsupported-page/unsupported-page.component';
import { AdaptiveHeightDirective } from '@app/responsive/directive/adaptive-height.directive';
import { AdaptiveDirective } from './directive/adaptive.directive';
import { BreakpointsDirective } from './directive/breakpoints.directive';

@NgModule({
  declarations: [
    AdaptiveDirective,
    BreakpointsDirective,
    UnsupportedPageComponent,
    AdaptiveHeightDirective,
  ],
  imports: [
    NzButtonModule,
    RouterModule,
    I18NextModule,
  ],
  exports: [
    AdaptiveDirective,
    BreakpointsDirective,
    UnsupportedPageComponent,
    AdaptiveHeightDirective,
  ],
})
export class ResponsiveModule { }
