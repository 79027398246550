import { Inject, Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ShopifyStore, UserCard } from '@app/graphql/graphql';
import { PaymentMethodInfo } from '@app/model/payment-method-info';

enum CardProvider {
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
  Maestro = 'MAESTRO',
}

@Injectable({ providedIn: 'root' })
export class PaymentMethodService {
  private defaultCardProviderPath = 'user-card.svg';

  private readonly cardProviderPathMap = new Map<string, string>([
    [CardProvider.Mastercard, 'mastercard.svg'],
    [CardProvider.Visa, 'visa.svg'],
    [CardProvider.Maestro, 'maestro.svg'],
  ]);

  constructor(
    private titleCasePipe: TitleCasePipe,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  public getUserCardPaymentInfo(userCard: UserCard): PaymentMethodInfo {
    const svgIconPath = this.cardProviderPathMap.get(userCard.cardProvider)
      || this.defaultCardProviderPath;

    return new PaymentMethodInfo(
      this.translationService.t(
        'payment_method_info.title',
        {
          card_provider: this.titleCasePipe.transform(userCard.cardProvider),
          tail_numbers: userCard.cardTailNumbers,
        },
      ),
      this.translationService.t(
        'payment_method_info.details',
        {
          expiration_month: userCard.cardExpMonth,
          expiration_year: userCard.cardExpYear,
        },
      ),
      `../../assets/images/icons/${svgIconPath}`,
    );
  }

  public getShopifyStorePaymentInfo(
    shopifyStore: ShopifyStore,
  ): PaymentMethodInfo {
    return new PaymentMethodInfo(
      shopifyStore.name,
      shopifyStore.domain,
      '../../assets/images/icons/shopify.svg',
    );
  }
}
