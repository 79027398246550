import { NgModule } from '@angular/core';
import { WidgetChannelsImageComponent } from './widget-channels-image/widget-channels-image.component';

@NgModule({
  declarations: [
    WidgetChannelsImageComponent,
  ],
  imports: [],
  exports: [
    WidgetChannelsImageComponent,
  ],
})
export class SvgImageModule { }
