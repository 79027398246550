import {
  Component, Inject, LOCALE_ID, OnDestroy,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { BillingService } from '@app/gql-service/billing.service';
import { ROUTE_URL } from '@app/router-url.constants';
import { CompanySubscription } from '@app/graphql/graphql';
import { ERROR_SLUG } from '@src/constants/error-slug.constants';
import { CusboWidgetApiService } from '@app/cusbo-widget/cusbo-widget-api.service';

@Component({
  selector: 'app-confirm-cancel-subscription-modal',
  templateUrl: './confirm-cancel-subscription-modal.component.html',
  styleUrls: ['./confirm-cancel-subscription-modal.component.scss'],
})
export class ConfirmCancelSubscriptionModalComponent
implements OnDestroy {
  companySubscription: CompanySubscription;

  loading = false;

  errorMessage: string;

  hideErrorMessage = true;

  private componentIsDestroyed = new Subject<boolean>();

  constructor(
    @Inject(LOCALE_ID)
    private locale: string,
    private router: Router,
    private modal: NzModalService,
    private billingService: BillingService,
    @Inject(I18NEXT_SERVICE)
    public translationService: ITranslationService,
    private cusboWidgetService: CusboWidgetApiService,
  ) {
  }

  showCancelSubscriptionConfirmationModal(subscription: CompanySubscription) {
    this.modal.closeAll();
    this.modal.create({
      nzTitle: this.translationService.t('settings.billing.cancel_subscription_modal.title'),
      nzWidth: '40%',
      nzMaskClosable: true,
      nzContent: ConfirmCancelSubscriptionModalComponent,
      nzClosable: true,
      nzComponentParams: {
        companySubscription: subscription,
      },
    });
  }

  onYesClick() {
    this.loading = true;
    this.billingService.cancelSubscription(this.companySubscription.id)
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe(
        () => {
          this.loading = false;
          this.modal.closeAll();
          this.openInformationModal();
        }, () => {
          this.onError();
        },
      );
  }

  onNoClick() {
    this.modal.closeAll();
  }

  goToPlans() {
    this.modal.closeAll();
    this.router.navigate([ROUTE_URL.settingsPlans]);
  }

  handleContactUsButtonClicked(): void {
    this.cusboWidgetService.openCusboWidget();
  }

  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.unsubscribe();
  }

  private openInformationModal() {
    const validTo = new Date(this.companySubscription.currentStatus.validTo);
    const date = formatDate(validTo, 'mediumDate', this.locale);

    this.modal.success({
      nzTitle: this.translationService.t('settings.billing.subscription_was_canceled_modal.title'),
      nzContent: this.translationService.t(
        'settings.billing.subscription_was_canceled_modal.content',
        { date },
      ),
      nzOkText: this.translationService.t('settings.billing.subscription_was_canceled_modal.got_it_btn'),
      nzOnOk: () => window.location.reload(),
      nzOnCancel: () => this.closeInformationModal(),
    });
  }

  private closeInformationModal() {
    this.modal.closeAll();
    window.location.reload();
  }

  private onError() {
    this.loading = false;
    this.errorMessage = this.translationService
      .t(ERROR_SLUG.somethingWentWrong);
    this.hideErrorMessage = false;
  }
}
