import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-attachment-view-button',
  templateUrl: './attachment-view-button.component.html',
  styleUrls: ['./attachment-view-button.component.scss'],
})
export class AttachmentViewButtonComponent {
  @Input() disabled = false;
}
