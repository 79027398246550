import {
  AbstractControl, FormControlStatus, FormGroup, ValidatorFn,
} from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';

// If there are more than zero enabled controls, just use formGroup.value instead.
// We need this function because formGroup.value returns all controls,
// even disabled ones when there no enabled controls.
export function getEnabledValue(formGroup: FormGroup) {
  return Object.keys(formGroup.controls)
    .filter((key) => formGroup.controls[key].enabled)
    .reduce(
      (parameters, key) => ({
        ...parameters,
        [key]: formGroup.controls[key].value,
      }),
      {},
    );
}

/**
* Allows to use validators conditionally.
*/
export function conditionalValidator(
  predicate: (FormControl) => boolean,
  validator: ValidatorFn,
): ValidatorFn {
  return (formControl) => {
    if (predicate(formControl)) {
      return validator(formControl);
    }

    return null;
  };
}

export function showErrorTips(formGroup: FormGroup): void {
  if (formGroup.valid) {
    return;
  }

  Object.values(formGroup.controls)
    .filter((control: AbstractControl): boolean => control.invalid)
    .forEach((control: AbstractControl): void => {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    });
}

export function mergeControls(
  formGroups: FormGroup[],
): {[key: string]: AbstractControl} {
  return formGroups.reduce(
    (accumulator, group) => ({ ...accumulator, ...group.controls }),
    {},
  );
}

export function isFormGroupInvalid(formGroup: FormGroup): Observable<boolean> {
  return formGroup.statusChanges.pipe(
    map((status: FormControlStatus): boolean => status === 'INVALID'),
    startWith(formGroup.invalid),
  );
}

export function getObservableWithInitialValue<T>(
  formGroup: FormGroup,
  controlName: string,
): Observable<T> {
  return formGroup.get(controlName).valueChanges
    .pipe(startWith(formGroup.get(controlName).value));
}
