import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { OperatorIdentityInformation } from '@app/cusbo-widget/model/operator-identity-information';
import { AuthenticatedWidgetUser } from '@app/cusbo-widget/model/authenticated-widget-user';
import { CusboWidgetService } from '@app/cusbo-widget/cusbo-widget.service';

declare function authenticateWidgetUser(websiteWidgetData: any): void;
declare function hideCusboWidget(): void;
declare function displayCusboWidget(): void;
declare function openCusboWidget(): void;

@Injectable({
  providedIn: 'root',
})
export class CusboWidgetApiService {
  private readonly onWidgetIntegrationScriptLoaded$
    = this.cusboWidgetService.isWidgetIntegrationScriptLoaded$;

  private loaded = false;

  constructor(
    private cusboWidgetService: CusboWidgetService,
  ) {
  }

  loadWidgetIfNeeded(): void {
    if (this.loaded) {
      return;
    }

    this.cusboWidgetService.load();
    this.loaded = true;
  }

  authenticateWidgetUser(
    widgetUserAuthentication: OperatorIdentityInformation,
  ): void {
    this.onWidgetIntegrationScriptLoaded$
      .pipe(first())
      .subscribe(() => authenticateWidgetUser(
        this.toWidgetUserData(widgetUserAuthentication),
      ));
  }

  displayCusboWidget(): void {
    this.onWidgetIntegrationScriptLoaded$
      .pipe(first())
      .subscribe(() => displayCusboWidget());
  }

  openCusboWidget(): void {
    this.onWidgetIntegrationScriptLoaded$
      .pipe(first())
      .subscribe(() => openCusboWidget());
  }

  hideCusboWidget(): void {
    this.onWidgetIntegrationScriptLoaded$
      .pipe(first())
      .subscribe(() => hideCusboWidget());
  }

  private toWidgetUserData(
    widgetUserAuthentication: OperatorIdentityInformation,
  ): AuthenticatedWidgetUser {
    return {
      userUID: `${widgetUserAuthentication.companyId}.${widgetUserAuthentication.operatorId}`,
    };
  }
}
