import { Pipe, PipeTransform, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'translationLinks',
})
export class TranslationLinksPipe implements PipeTransform {
  private readonly HREF_INDEX = 1;

  private readonly TEXT_INDEX = 2;

  private readonly linkRegExp = /<c-link href=".*?">.*?<\/c-link>/gi;

  private readonly linkValuesRegExp = /<c-link href="(.*?)">(.*?)<\/c-link>/i;

  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {
  }

  transform(text: string, additionalAttributes: {name; value}[][]): SafeHtml {
    const linkElements = (text.match(this.linkRegExp) || [])
      .map((link, index) => {
        const linkElement = this.renderer.createElement('a');
        const linkValues = link.match(this.linkValuesRegExp);

        linkElement.innerText = linkValues[this.TEXT_INDEX];
        linkElement.setAttribute('href', linkValues[this.HREF_INDEX]);
        additionalAttributes[index].forEach((attribute) => {
          linkElement.setAttribute(attribute.name, attribute.value);
        });

        return linkElement;
      });
    const transformedText = text.split(this.linkRegExp)
      .flatMap((el, idx) => [el, linkElements[idx]?.outerHTML])
      .join('');

    return this.sanitizer.bypassSecurityTrustHtml(transformedText);
  }
}
