import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  constructor(
    private featureFlagService: FeatureFlagService,
  ) {
  }

  isScheduledMessagesEnabled(): Observable<boolean> {
    return this.featureFlagService
      .isEnabled('scheduled-messages');
  }

  isShopifyAppReviewEnabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('shopify-app-review');
  }

  isInviteOperatorToChatEnabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('invite-operator-to-chat');
  }

  isWidgetV2p1Disabled(): Observable<boolean> {
    return this.featureFlagService.isDisabled('widget-2.1');
  }

  isAnalyticsV1Enabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('analytics-1.0');
  }

  isVoiceMessagesV1Enabled(): Observable<boolean> {
    return this.featureFlagService.isEnabled('voice-messages-1.0');
  }
}
