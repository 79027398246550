import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelegramIconModule } from '@app/icons/telegram-icon/telegram-icon.module';
import { ViberIconModule } from '@app/icons/viber-icon/viber-icon.module';
import { InstagramIconModule } from '@app/icons/instagram-icon/instagram-icon.module';
import { WhatsappIconModule } from '@app/icons/whatsapp-icon/whatsapp-icon.module';
import { FacebookIconModule } from '@app/icons/facebook-icon/facebook-icon.module';
import { WidgetIconModule } from '@app/icons/widget-icon/widget-icon.module';
import { MailIconModule } from '@app/icons/mail-icon/mail-icon.module';
import { GmailIconModule } from '@app/icons/gmail-icon/gmail-icon.module';
import { OutlookIconModule } from '@app/icons/outlook-icon/outlook-icon.module';
import { PhoneIconModule } from '@app/icons/phone-icon/phone-icon.module';
import { MessengerIconComponent } from './messenger-icon.component';

@NgModule({
  imports: [
    TelegramIconModule,
    ViberIconModule,
    InstagramIconModule,
    WhatsappIconModule,
    FacebookIconModule,
    CommonModule,
    WidgetIconModule,
    MailIconModule,
    GmailIconModule,
    OutlookIconModule,
    PhoneIconModule,
  ],
  declarations: [
    MessengerIconComponent,
  ],
  exports: [
    MessengerIconComponent,
  ],
})
export class MessengerIconModule {
}
