import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ERROR_TIP_PROPERTY_NAME } from '@app/form-validation-service';

@Injectable({ providedIn: 'root' })
export class ErrorTipProviderService {
  getErrorTip(
    formGroup: FormGroup,
    controlName: string,
  ): string {
    const control = formGroup.controls[controlName];

    return this.getErrorTipByFromControl(control);
  }

  getErrorTipByFromControl(control: AbstractControl): string {
    return control?.errors
      ? control.errors[ERROR_TIP_PROPERTY_NAME]
      : null;
  }

  getFormErrorTip(formGroup: FormGroup): string {
    return formGroup?.errors
      ? formGroup.errors[ERROR_TIP_PROPERTY_NAME]
      : null;
  }
}
