import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const openCloseAnimation = trigger('openCloseAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('150ms ease-in-out',
      style({
        opacity: 1,
      })),
  ]),
  transition(':leave', [
    animate('150ms ease-in-out',
      style({
        opacity: 0,
      })),
  ]),
]);
