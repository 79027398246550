import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ShopifyPaymentPageComponent } from './shopify-payment-page.component';

const routes: Routes = [{ path: '', component: ShopifyPaymentPageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShopifyPaymentPageRoutingModule { }
