import { Injectable } from '@angular/core';
import { parseIfValidJson } from '@app/util/json.util';
import { MultiselectValue } from '@app/common/model/multiselect.value';
import { CHAT_FILTER_CONTROL_NAME } from '../filter-section.config';
import { ChatStatusOptionValue } from '../model/chat-status-option';
import { ChatCategoryOptionValue } from '../model/chat-category-option';

const PARAMETER_NAME_INDEX = 1;

const OPERATOR_ID_VALUE_PATTERN = /^"\d+"$/;
const JSON_ARRAY_WITH_NUMBER_VALUES_PATTERN = /^\["(\d+"(,")?)+]$/;

const STATUS_VALUES = [
  ChatStatusOptionValue.ALL.toString(),
  ChatStatusOptionValue.NEW.toString(),
  ChatStatusOptionValue.MY_TASK.toString(),
  ChatStatusOptionValue.COMPLETED.toString(),
  ChatStatusOptionValue.SPAM.toString(),
];
const MULTISELECT_VALUES = [
  JSON.stringify(MultiselectValue.ALL),
  JSON.stringify(MultiselectValue.NONE),
];
const CATEGORY_VALUES = [
  JSON.stringify(ChatCategoryOptionValue.ALL),
  JSON.stringify(ChatCategoryOptionValue.NONE),
];

@Injectable({
  providedIn: 'root',
})
export class FiltrationParameterValidationService {
  private readonly validationMap: Map<string, (value: string) => boolean>
    = new Map([
      [CHAT_FILTER_CONTROL_NAME.chatStatus, isValidStatusValue],
      [CHAT_FILTER_CONTROL_NAME.chatOperatorIds, isValidOperatorValue],
      [CHAT_FILTER_CONTROL_NAME.chatCategories, isValidCategoryValue],
      [CHAT_FILTER_CONTROL_NAME.createDate, isValidCreateDateFromTo],
      [CHAT_FILTER_CONTROL_NAME.channelIds, isValidMultiselectIds],
    ]);

  isValid(
    parameterName: string,
    parameterValue: string,
  ): boolean {
    const originalParameterName = parameterName.split('_')[PARAMETER_NAME_INDEX];

    return this.validationMap.get(originalParameterName)(parameterValue);
  }
}

function isValidStatusValue(
  value: string,
): boolean {
  return STATUS_VALUES.includes(value);
}

function isValidOperatorValue(
  value: string,
): boolean {
  return isValidOperatorSingleValue(value)
    || isValidMultiselectIds(value);
}

function isValidOperatorSingleValue(
  value: string,
): boolean {
  return MULTISELECT_VALUES.includes(value)
    || OPERATOR_ID_VALUE_PATTERN.test(value);
}

function isValidCategoryValue(
  value: string,
): boolean {
  return CATEGORY_VALUES.includes(value)
    || JSON_ARRAY_WITH_NUMBER_VALUES_PATTERN.test(value);
}

function isValidCreateDateFromTo(
  value: string,
): boolean {
  return Boolean(parseIfValidJson(value));
}

function isValidMultiselectIds(
  value: string,
): boolean {
  return MULTISELECT_VALUES.includes(value)
    || JSON_ARRAY_WITH_NUMBER_VALUES_PATTERN.test(value);
}
