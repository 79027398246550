import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  PreChatForm,
  WidgetChannel,
  WidgetPosition,
  WidgetProperties,
  WidgetTextColor,
  WidgetViewProperties,
} from '@app/model/widget-properties';
import { WidgetChannelType } from '@app/graphql/graphql';
import { WidgetPreviewConstants } from './widget-preview.constants';
import { WidgetPreviewPage } from './modal/widget-preview-page';

const WIDGET_CHANNEL_ORDER = new Map<WidgetChannelType, number>([
  [WidgetChannelType.Telegram, 0],
  [WidgetChannelType.Viber, 1],
  [WidgetChannelType.Whatsapp, 2],
  [WidgetChannelType.Facebook, 3],
  [WidgetChannelType.Instagram, 4],
  [WidgetChannelType.Email, 5],
  [WidgetChannelType.Phone, 6],
]);

@Injectable({
  providedIn: 'root',
})
export class WidgetPreviewService {
  private readonly isWidgetPreviewShown = new BehaviorSubject<boolean>(false);

  private readonly isWidgetWindowOpen = new BehaviorSubject(false);

  readonly isWidgetWindowOpen$ = this.isWidgetWindowOpen.asObservable();

  private readonly widgetTestColorMap
    = new Map<WidgetTextColor, string>([
      [WidgetTextColor.LIGHT, '#fff'],
      [WidgetTextColor.DARK, '#000'],
    ]);

  private readonly widgetPositionUpdaterMap
    = new Map<WidgetPosition, SetPositionFunction>([
      [WidgetPosition.BOTTOM_LEFT, setLeftPosition],
      [WidgetPosition.BOTTOM_RIGHT, setRightPosition],
    ]);

  private readonly widgetProperties = new BehaviorSubject<WidgetProperties>({
    title: 'Not initialized',

    language: 'en',

    channels: [],

    liveChatEnabled: true,

    view: {
      backgroundColor: WidgetPreviewConstants.BACKGROUND_COLOR,
      textColor: WidgetPreviewConstants.TEXT_COLOR,
      position: WidgetPreviewConstants.POSITION,
    },
  });

  private readonly welcomeMessage = new BehaviorSubject<string>(null);

  private readonly widgetPreviewPage
    = new BehaviorSubject(WidgetPreviewPage.CHANNELS);

  private readonly welcomeMessage$
    = this.welcomeMessage.asObservable();

  private readonly widgetProperties$
    = this.widgetProperties.asObservable();

  readonly isWidgetPreviewShown$
    = this.isWidgetPreviewShown.asObservable();

  readonly widgetPreviewPage$
    = this.widgetPreviewPage.asObservable();

  readonly widgetTitle$
    = this.widgetProperties$.pipe(
      map((widgetProperties) => widgetProperties.title),
    );

  readonly introductionText$
    = this.widgetProperties$.pipe(
      map((widgetProperties) => widgetProperties.introductionText),
    );

  readonly language$ = this.widgetProperties$.pipe(
    map((widgetProperties) => widgetProperties.language),
  );

  init(
    widgetProperties: WidgetProperties,
    welcomeMessage?: string,
  ): void {
    const widgetPropertiesCopy = {
      ...widgetProperties,
      view: {
        backgroundColor: widgetProperties.view?.backgroundColor
          || WidgetPreviewConstants.BACKGROUND_COLOR,
        textColor: widgetProperties.view?.textColor
          || WidgetPreviewConstants.TEXT_COLOR,
        position: widgetProperties.view?.position
          || WidgetPreviewConstants.POSITION,
      },
    };

    widgetPropertiesCopy.channels
      = filterActiveChannels(widgetPropertiesCopy.channels)
        .sort(compareWidgetChannels);

    this.setPageByChannels(widgetPropertiesCopy.channels);
    widgetPropertiesCopy.language = widgetPropertiesCopy.language.toLowerCase();
    this.widgetProperties.next(widgetPropertiesCopy);
    this.isWidgetPreviewShown.next(true);
    this.welcomeMessage.next(welcomeMessage);
  }

  isOnlinePreChatFormEnabled(): boolean {
    return this.widgetProperties.value.preChatFormOnline?.enabled;
  }

  hide(): void {
    this.isWidgetPreviewShown.next(false);
  }

  setWidgetView(widgetView: WidgetViewProperties): void {
    const widgetProperties = { ...this.widgetProperties.value };

    widgetProperties.view = widgetView;

    this.widgetProperties.next(widgetProperties);
  }

  setChannels(channels: WidgetChannel[], isLiveChatEnabled: boolean): void {
    const widgetProperties = { ...this.widgetProperties.value };

    widgetProperties.channels = channels.sort(compareWidgetChannels);
    widgetProperties.liveChatEnabled = isLiveChatEnabled;

    this.setPageByChannels(widgetProperties.channels);
    this.widgetProperties.next(widgetProperties);
  }

  getBackgroundColor(): Observable<string> {
    return this.widgetProperties$.pipe(
      map((widgetProperties) => widgetProperties.view.backgroundColor),
    );
  }

  getWidgetChannels(): Observable<WidgetChannel[]> {
    return this.widgetProperties$.pipe(
      map((widgetProperties) => filterActiveChannels(
        widgetProperties.channels,
      )),
    );
  }

  getWidgetPosition(): Observable<WidgetPosition> {
    return this.widgetProperties$.pipe(
      map((widgetProperties) => widgetProperties.view.position),
    );
  }

  getTextColor(): Observable<string> {
    return this.widgetProperties$.pipe(
      map((widgetProperties) => widgetProperties.view.textColor),
      map((widgetTextColor) => this.widgetTestColorMap.get(widgetTextColor)),
    );
  }

  isLiveChatEnabled(): Observable<boolean> {
    return this.widgetProperties$.pipe(
      map((widgetProperties) => widgetProperties.liveChatEnabled),
    );
  }

  hasWelcomeMessage(): Observable<boolean> {
    return this.welcomeMessage$.pipe(
      map((welcomeMessage) => !!welcomeMessage),
    );
  }

  getWelcomeMessage(): Observable<string> {
    return this.welcomeMessage$;
  }

  updateWidgetPosition(widgetButton: ElementRef): Observable<any> {
    return this.getWidgetPosition().pipe(
      tap((widgetPosition) => {
        this.widgetPositionUpdaterMap
          .get(widgetPosition)(widgetButton);
      }),
    );
  }

  setWidgetTitle(title: string): void {
    const widgetProperties = { ...this.widgetProperties.value };

    widgetProperties.title = title;

    this.widgetProperties.next(widgetProperties);
  }

  setIntroductionText(introductionText: string): void {
    this.widgetProperties.next({
      ...this.widgetProperties.value,
      introductionText,
    });
  }

  setLanguage(language: string): void {
    const widgetProperties = { ...this.widgetProperties.value };

    widgetProperties.language = language.toLowerCase();

    this.widgetProperties.next(widgetProperties);
  }

  setPage(page: WidgetPreviewPage): void {
    if (page === this.widgetPreviewPage.value) {
      return;
    }

    this.widgetPreviewPage.next(page);
  }

  getCurrentWidgetProperties(): WidgetProperties {
    return this.widgetProperties.value;
  }

  private setPageByChannels(channels: WidgetChannel[]): void {
    const widgetPage = filterActiveChannels(channels).length
      ? WidgetPreviewPage.CHANNELS
      : WidgetPreviewPage.CONVERSATION;

    this.setPage(widgetPage);
  }

  setOfflinePreChatForm(preChatForm: PreChatForm): void {
    this.widgetProperties.next({
      ...this.widgetProperties.value,
      preChatFormOffline: preChatForm,
    });
  }

  getOnlinePreChatForm(): Observable<PreChatForm> {
    return this.widgetProperties$.pipe(
      map((
        widgetProperties: WidgetProperties,
      ) => widgetProperties.preChatFormOnline),
    );
  }

  setOnlinePreChatForm(preChatForm: PreChatForm): void {
    this.widgetProperties.next({
      ...this.widgetProperties.value,
      preChatFormOnline: preChatForm,
    });
  }

  openWidgetWindow(): void {
    this.isWidgetWindowOpen.next(true);
  }

  closeWidgetWindow(): void {
    this.isWidgetWindowOpen.next(false);
  }
}

type SetPositionFunction = (element: ElementRef) => void;

function setLeftPosition(element: ElementRef): void {
  element.nativeElement.style.left = '24px';
  element.nativeElement.style.right = null;
}

function setRightPosition(element: ElementRef): void {
  element.nativeElement.style.left = null;
  element.nativeElement.style.right = '24px';
}

function filterActiveChannels(channels: WidgetChannel[]): WidgetChannel[] {
  return channels.filter((channel) => channel.active);
}

function compareWidgetChannels(
  first: WidgetChannel,
  second: WidgetChannel,
): number {
  return WIDGET_CHANNEL_ORDER.get(first.type)
    - WIDGET_CHANNEL_ORDER.get(second.type);
}
