import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PaymentMethodComponent } from './payment-method.component';

@NgModule({
  declarations: [
    PaymentMethodComponent,
  ],
  imports: [
    CommonModule,
    NzToolTipModule,
  ],
  exports: [
    PaymentMethodComponent,
  ],
  providers: [
    TitleCasePipe,
  ],
})
export class PaymentMethodModule { }
