import { Inject, Injectable } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Tab, TAB_INFO } from '@src/constants/tab-info.constants';
import { SORT_ORDER } from '@src/constants/sort-type.constants';
import { FirebaseService } from '@app/firebase.service';
import {
  Chat, ChatPreviewFragment, ChatStatusName,
  Message, MessageType, MessengerType,
} from '@app/graphql/graphql';
import { ChatPreviewServiceGql } from '@app/gql-service/chat-preview-service-gql.service';
import { ChangedChatPreviewGql } from '@app/model/changed-chat-preview-gql';
import { SoundNotificationService } from '@app/service/sound-notification.service';
import { TransferredChatsStorage } from '@app/transferred-chat-storage.service';
import { FilterSectionService } from '@app/conversation-page/tab/filter-section/filter-section.service';
import { CHAT_RESCUE_FORBIDDEN_MESSAGE_TYPES, CHAT_RESCUE_MESSAGE_TYPES_FROM_USER } from '@app/model/message-type';
import { UnreadMessagesCounterService } from '@app/unread-messages-counter.service';
import { Breakpoint } from '@app/responsive/model/breakpoint';
import { ResponsiveService } from '@app/responsive/service/responsive.service';
import { HtmlToPlaintextPipe } from '@app/pipe/html-to-plain-text.pipe';
import { truncate } from '@app/util/text.util';
import { MessageUtilService } from '@app/util/message-util.service';
import { LocalStorageService } from '@app/local-storage-service';

const NOTIFICATION_BLOCK_INDENT = '80px';
const MAIL_CHAT_ID_SEPARATOR = '#';
const MAX_NOTIFICATION_LENGTH = 200;

export const ANSWERED_INFLUENCING_MESSAGE_TYPES = [
  MessageType.BotBlockedInfo,
  MessageType.Conversation,
  MessageType.Comment,
  MessageType.ReplyToCommentInDm,
  MessageType.ContactShare,
  MessageType.UserInformationShare,
];

@Injectable({ providedIn: 'root' })
export class ChatPreviewUpdateGqlService {
  constructor(
    private chatPreviewService: ChatPreviewServiceGql,
    private firebaseService: FirebaseService,
    private configService: NzConfigService,
    private notificationService: NzNotificationService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private soundNotificationService: SoundNotificationService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private transferredChatsStorage: TransferredChatsStorage,
    private filterSectionService: FilterSectionService,
    private unreadMessagesCounterService: UnreadMessagesCounterService,
    private responsiveService: ResponsiveService,
    private htmlToPlaintextPipe: HtmlToPlaintextPipe,
    private messageUtilService: MessageUtilService,
  ) {
    configService.set('notification', {
      nzTop: NOTIFICATION_BLOCK_INDENT,
    });
  }

  addNewChatPreview(
    previews: ChatPreviewFragment[],
    newChatPreview: ChangedChatPreviewGql,
    tab: Tab,
    sortType?: string,
    hasMorePage?: boolean,
  ): Observable<ChatPreviewFragment[]> {
    const isPresent = !!previews.find(
      (
        preview: ChatPreviewFragment,
      ) => Number(newChatPreview.id) === Number(preview.id),
    );

    if (!isPresent) {
      return this.chatPreviewService.findChatPreviewByChatId(
        newChatPreview.id,
      )
        .pipe(
          filter(this.isMatchesFilter.bind(this, tab)),
          map((newPreview: ChatPreviewFragment) => {
            if (tab === Tab.New) {
              if (sortType === SORT_ORDER.asc && !hasMorePage) {
                previews.push(newPreview);
              }

              if (sortType && sortType === SORT_ORDER.desc) {
                this.addAfterTransfers(previews, newPreview);
              }
            } else {
              previews.unshift(newPreview);
            }

            this.unreadMessagesCounterService.setUnreadMessagesAndComments(
              Number(newPreview.id),
              newPreview.unreadMessagesCounter,
              newPreview.unreadCommentIds,
            );

            return previews;
          }),
          tap(() => {
            this.soundNotificationService.playNewChatSoundIfEnabled();
          }),
        );
    }

    return EMPTY;
  }

  updatePreviewsOnNewMessage(
    previews: ChatPreviewFragment[],
    newMessage: Message,
    shouldNotify = true,
    tab: Tab,
    sortType?: string,
    hasMorePage?: boolean,
  ): Observable<ChatPreviewFragment[]> {
    let isPresent = false;
    let isThreadCommentFromThirdPartyUser = false;
    const updatedChats = previews.map((preview: ChatPreviewFragment) => {
      if (Number(newMessage.chat.id) === Number(preview.id)) {
        isPresent = true;

        if (newMessage.senderUser
          && preview.bot.messenger.type !== MessengerType.Widget
          && newMessage.senderUser.externalUserId !== preview.externalChatId
          && !preview.externalChatId.endsWith(
            `${MAIL_CHAT_ID_SEPARATOR}${newMessage.senderUser.externalUserId}`,
          )
        ) {
          isThreadCommentFromThirdPartyUser = true;
        }

        return {
          ...preview,
          lastMessage: newMessage,
          isAnswered: this.messageUtilService
            .isAnswerToUser(preview.isAnswered, newMessage),
          firstUnansweredMessageCreateTime: this
            .getFirstUnansweredMessageCreateTime(newMessage, preview),
        };
      }

      return preview;
    });

    if (isThreadCommentFromThirdPartyUser) {
      return of(previews);
    }

    // we want to notify operator only if it's message from user
    if (shouldNotify && newMessage.senderUser) {
      this.sendNotification(newMessage, tab);
      if (!window.location.href.endsWith(newMessage.chat.id)) {
        if (isPresent) {
          this.soundNotificationService.playNewMessageSoundIfEnabled();
        } else {
          this.soundNotificationService.playNewChatSoundIfEnabled();
        }
      }
    }

    if (!isPresent) {
      return this.chatPreviewService.findChatPreviewByChatId(
        Number(newMessage.chat.id),
      )
        .pipe(
          filter(this.isMatchesFilter.bind(this, tab)),
          map((newPreview: ChatPreviewFragment) => {
            if (tab === Tab.New) {
              if (
                this.isTransferredChat(newMessage.chat, tab)
                && newPreview.chatOperator.operator
                  .id === this.localStorageService.getCurrentOperatorIdString()
              ) {
                return updatedChats;
              }

              if (sortType === SORT_ORDER.asc && !hasMorePage) {
                if (this.isTransferredChat(newMessage.chat, tab)) {
                  this.addAfterTransfers(updatedChats, newPreview);
                } else {
                  updatedChats.push(newPreview);
                }
              }

              if (sortType && sortType === SORT_ORDER.desc) {
                if (this.isTransferredChat(newMessage.chat, tab)) {
                  this.addToStart(updatedChats, newPreview);
                } else {
                  this.addAfterTransfers(updatedChats, newPreview);
                }
              }
            } else {
              updatedChats.unshift(newPreview);
            }

            return updatedChats;
          }),
        );
    }

    const chatToMovePreview = updatedChats.find(
      (chatPreview) => Number(chatPreview.id) === Number(newMessage.chat.id),
    );
    const fromIndex: number = updatedChats.indexOf(chatToMovePreview);

    updatedChats.splice(fromIndex, 1);
    if (tab === Tab.New) {
      if (sortType === SORT_ORDER.asc) {
        if (this.isTransferredChat(newMessage.chat, tab)) {
          this.addAfterTransfers(updatedChats, chatToMovePreview);
        } else if (!hasMorePage) {
          updatedChats.splice(updatedChats.length, 0, chatToMovePreview);
        }
      }

      if (sortType && sortType === SORT_ORDER.desc) {
        if (this.isTransferredChat(newMessage.chat, tab)) {
          this.addToStart(updatedChats, chatToMovePreview);
        } else {
          this.addAfterTransfers(updatedChats, chatToMovePreview);
        }
      }
    } else {
      this.addToStart(updatedChats, chatToMovePreview);
    }

    return of(updatedChats);
  }

  addAfterTransfers(
    previews: ChatPreviewFragment[],
    newPreview: ChatPreviewFragment,
  ): ChatPreviewFragment[] {
    const lastIndexOfTransfer = this.transferredChatsStorage
      .getLastIndexOfTransferredChatPreview(previews);

    previews.splice(lastIndexOfTransfer + 1, 0, newPreview);

    return previews;
  }

  addToStart(
    previews: ChatPreviewFragment[],
    newPreview: ChatPreviewFragment,
  ): ChatPreviewFragment[] {
    return previews.splice(0, 0, newPreview);
  }

  updateDataStoreOnChangedMessage(
    oldPreviews: ChatPreviewFragment[],
    changedMessage: Message,
  ): ChatPreviewFragment[] {
    return oldPreviews.map((preview: ChatPreviewFragment) => {
      if (Number(changedMessage.id) === Number(preview.lastMessage.id)) {
        return {
          ...preview,
          lastMessage: changedMessage,
        };
      }

      return preview;
    });
  }

  updateDataStoreOnCategoriesChange(
    oldPreviews: ChatPreviewFragment[],
    changedChatPreview: ChangedChatPreviewGql,
  ): ChatPreviewFragment[] {
    return oldPreviews.map((preview: ChatPreviewFragment) => {
      if (Number(changedChatPreview.id) === Number(preview.id)) {
        return {
          ...preview,
          categories: changedChatPreview.categories,
        };
      }

      return preview;
    });
  }

  updateLastMessage(
    oldPreviews: ChatPreviewFragment[],
    chatId: number,
    message: Message,
  ): ChatPreviewFragment[] {
    return oldPreviews.map((preview: ChatPreviewFragment) => {
      if (Number(chatId) === Number(preview.id)) {
        return {
          ...preview,
          lastMessage: message,
        };
      }

      return preview;
    });
  }

  updateTitle(
    oldPreviews: ChatPreviewFragment[],
    chatId: number,
    newTitle: string,
  ): ChatPreviewFragment[] {
    return oldPreviews.map((preview: ChatPreviewFragment) => {
      if (Number(chatId) === Number(preview.id)) {
        return {
          ...preview,
          title: newTitle,
        };
      }

      return preview;
    });
  }

  updatePendingInvitesAmount(
    oldPreviews: ChatPreviewFragment[],
    chatId: number,
    newAmount: number,
  ): ChatPreviewFragment[] {
    return oldPreviews.map((chat: Chat) => {
      if (Number(chatId) === Number(chat.id)) {
        return {
          ...chat,
          pendingInvitationsAmount: newAmount,
        };
      }

      return chat;
    });
  }

  getNextChatUrlFromList(
    chats: ChatPreviewFragment[],
    currentChatId: number,
  ): string {
    const nextChat = chats
      .find((chat) => Number(chat.id) !== Number(currentChatId));

    return nextChat
      ? `/${nextChat.id}`
      : '';
  }

  createNotification(
    title: string,
    content: string,
    chatId: number,
    redirectRoute: string,
  ): void {
    if (window.location.href.endsWith(String(chatId))) {
      return;
    }

    this.notificationService
      .blank(
        title,
        content,
        {
          nzPauseOnHover: this.responsiveService
            .isMatched(Breakpoint.DESKTOP),
        },
      )
      .onClick.subscribe((e) => {
        if ((e.target as Element).tagName === 'DIV') {
          this.router.navigateByUrl(redirectRoute);
        }
      });
  }

  private sendNotification(newMessage: Message, tab: Tab) {
    const route = `${TAB_INFO.find((i) => i.tab === tab).route}/${newMessage.chat.id}`;
    const username = newMessage.senderUser.companyMessengerUser?.firstName
      || newMessage.senderUser.username;
    const firebaseNotificationBody = `${username} : ${newMessage.text
      ? newMessage.text
      : this.translationService.t('notification.file')
    }`;

    this.firebaseService.sendPushNotification(
      firebaseNotificationBody,
      route,
      Number(newMessage.id),
    ).subscribe();
    const notificationContent = newMessage.text
      ? this.htmlToPlaintextPipe.transform(newMessage.text)
      : this.translationService.t('notification.sent_file');

    this.createNotification(
      username,
      truncate(notificationContent, MAX_NOTIFICATION_LENGTH),
      Number(newMessage.chat.id),
      route,
    );
  }

  private getFirstUnansweredMessageCreateTime(
    newMessage: Message,
    preview: ChatPreviewFragment,
  ): string {
    if (this.messageUtilService.isPrivateMessage(newMessage)) {
      return (preview as Chat).firstUnansweredMessageCreateTime;
    }

    return this.isMessageFromUser(newMessage)
        || (
          !this.isMessageFromOperator(newMessage)
          && !CHAT_RESCUE_FORBIDDEN_MESSAGE_TYPES.some(
            (type) => type === newMessage.messageType,
          )
        )
      ? (preview as Chat).firstUnansweredMessageCreateTime
      || newMessage.createTime
      : null;
  }

  private isMessageFromUser(newMessage: Message): boolean {
    return CHAT_RESCUE_MESSAGE_TYPES_FROM_USER.some(
      (type) => type === newMessage.messageType,
    ) && Boolean(newMessage.senderUser);
  }

  private isMessageFromOperator(newMessage: Message): boolean {
    return newMessage.messageType === MessageType.Conversation
      && Boolean(newMessage.senderOperator);
  }

  private isMatchesFilter(tab: Tab, chatPreview: ChatPreviewFragment): boolean {
    return this.filterSectionService.isMatchesFilter(chatPreview, tab);
  }

  private isTransferredChat(chat: Chat, currentTab: string): boolean {
    return chat.status === ChatStatusName.Processing && currentTab === Tab.New;
  }
}
