import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {
  AllChannelsDocument,
  AllChannelsForFiltrationSectionGQL,
  AllChannelsForVirtualOperatorGQL,
  AllChannelsGQL,
  AllChannelsQuery,
  AllChannelsWithResponsibleGQL,
  BotCredentialProperty,
  BotFeature,
  BotStatus,
  CanAccessMailGQL,
  ChannelFilter,
  ChannelForFiltrationSectionFragment,
  ChannelForVirtualOperatorFragment,
  ChannelFragment,
  ChannelWithResponsibleFragment,
  CheckMailAccessInput,
  CreateChannelGQL,
  CreateChannelInput,
  CreateMetaChannelGQL,
  CreateMetaChannelInput,
  DeleteChannelGQL,
  GetAllChannelsLightGQL,
  LightChannelFragment,
  MessengerProviderName,
  UpdateChannelFeaturesGQL,
  UpdateChannelFeaturesInput,
  UpdateChannelGQL,
  UpdateChannelInput,
  UpdateChannelPropertiesGQL,
  UpdateChannelPropertiesInput,
} from '@app/graphql/graphql';

const INITIAL_IS_INSTALLED_VALUE = false;
const INITIAL_SHOPIFY_SHOP_VALUE = null;

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  constructor(
    private allChannelsGQL: AllChannelsGQL,
    private allChannelsLightGQL: GetAllChannelsLightGQL,
    private updateChannelGQL: UpdateChannelGQL,
    private deleteChannelGQL: DeleteChannelGQL,
    private createChannelGQL: CreateChannelGQL,
    private createMetaChannelGQL: CreateMetaChannelGQL,
    private allChannelsForVirtualOperatorGQL
      : AllChannelsForVirtualOperatorGQL,
    private allChannelsForFiltrationSectionGQL
      : AllChannelsForFiltrationSectionGQL,
    private allChannelsWithResponsibleGQL: AllChannelsWithResponsibleGQL,
    private updateChannelFeaturesGQL: UpdateChannelFeaturesGQL,
    private updateChannelPropertiesGQL: UpdateChannelPropertiesGQL,
    private canAccessMailGQL: CanAccessMailGQL,
    private apollo: Apollo,
  ) {
  }

  getAllChannels(): Observable<ChannelFragment[]> {
    return this.allChannelsGQL.watch().valueChanges.pipe(
      map((data) => data.data.channels as ChannelFragment[]),
    );
  }

  getAllChannelsByFilter(
    channelFilter: ChannelFilter,
  ): Observable<ChannelFragment[]> {
    return this.allChannelsGQL
      .fetch(
        { filter: channelFilter },
        { fetchPolicy: 'network-only' },
      ).pipe(
        map((data) => data.data.channels as ChannelFragment[]),
      );
  }

  getAssignedOnly(): Observable<ChannelFragment[]> {
    return this.allChannelsGQL
      .fetch(
        {
          filter: {
            assignedOnly: true,
            status: BotStatus.Active,
          },
        },
        { fetchPolicy: 'network-only' },
      ).pipe(
        map((data) => data.data.channels as ChannelFragment[]),
      );
  }

  getDirectChannels(): Observable<LightChannelFragment[]> {
    return this.allChannelsLightGQL
      .fetch(
        {
          filter:
            {
              assignedOnly: true,
              status: BotStatus.Active,
              messengerProviderNames: [
                MessengerProviderName.EChat,
                MessengerProviderName.Twilio,
              ],
            },
        },
        { fetchPolicy: 'no-cache' },
      ).pipe(
        map((data) => data.data.channels as LightChannelFragment[]),
      );
  }

  getLightChannelsByFilter(
    filter: ChannelFilter,
  ): Observable<LightChannelFragment[]> {
    return this.allChannelsLightGQL.fetch({ filter }).pipe(
      map((data) => data.data.channels as LightChannelFragment[]),
    );
  }

  getAllChannelsForFiltrationSection(
  ): Observable<ChannelForFiltrationSectionFragment[]> {
    return this.allChannelsForFiltrationSectionGQL.fetch(
      {},
      { fetchPolicy: 'network-only' },
    ).pipe(
      map((data) => data.data.channels),
    );
  }

  updateChannel(channel: UpdateChannelInput): Observable<ChannelFragment> {
    return this.updateChannelGQL.mutate({ input: channel })
      .pipe(
        map((res) => res.data.updateChannel as ChannelFragment),
      );
  }

  updateChannelFeatures(
    input: UpdateChannelFeaturesInput,
  ): Observable<BotFeature[]> {
    return this.updateChannelFeaturesGQL.mutate({ input })
      .pipe(
        map((res) => res.data.updateChannelFeatures),
      );
  }

  updateChannelProperties(
    input: UpdateChannelPropertiesInput,
  ): Observable<BotCredentialProperty[]> {
    return this.updateChannelPropertiesGQL.mutate(
      { input },
      {
        update: (cache, { data: updateChannelPropertiesMutation }) => {
          cache.updateQuery({
            query: AllChannelsDocument,
          }, (data: AllChannelsQuery) => {
            const channelToUpdate = data.channels
              .find((channel) => channel.id === input.channelId);

            return {
              channels: [
                ...data.channels
                  .filter((channel) => channel.id !== input.channelId),
                {
                  ...channelToUpdate,
                  properties: {
                    ...channelToUpdate.properties,
                    ...updateChannelPropertiesMutation.updateChannelProperties,
                  },
                },
              ],
            };
          });
        },
      },
    ).pipe(
      map((res) => res.data.updateChannelProperties),
    );
  }

  updateChannelStatusCache(
    channel: ChannelFragment,
    newStatus: BotStatus,
  ): void {
    this.apollo.client.cache.modify({
      id: this.apollo.client.cache.identify(channel),
      fields: {
        status() {
          return newStatus;
        },
      },
    });
  }

  deleteChannelById(channelId: string): Observable<boolean> {
    return this.deleteChannelGQL.mutate(
      { id: channelId },
      {
        update: (cache) => {
          cache.updateQuery({
            query: AllChannelsDocument,
          }, (data: AllChannelsQuery) => deleteChannelFromCache(
            data,
            channelId,
          ));
        },
      },
    ).pipe(map((result) => result.data.deleteChannel));
  }

  addChannel(
    channelInput: CreateChannelInput,
    shouldAssign: boolean,
  ): Observable<ChannelFragment> {
    return this.createChannelGQL.mutate(
      {
        input: channelInput,
        assignToAll: shouldAssign,
      },
      {
        update: (cache, { data: createChannelMutation }) => {
          cache.updateQuery({
            query: AllChannelsDocument,
          }, (data: AllChannelsQuery) => ({
            channels: [
              createChannelMutation.createChannel,
              ...data.channels,
            ],
          }));
        },
      },
    ).pipe(map((res) => res.data.createChannel as ChannelFragment));
  }

  addMetaChannel(
    channelInput: CreateMetaChannelInput,
    shouldAssign: boolean,
  ): Observable<ChannelFragment[]> {
    return this.createMetaChannelGQL.mutate(
      {
        input: channelInput,
        assignToAll: shouldAssign,
      },
      {
        update: (cache, { data: createChannelMutation }) => {
          cache.updateQuery({
            query: AllChannelsDocument,
          }, (data: AllChannelsQuery) => ({
            channels: [
              ...createChannelMutation.createMetaChannel,
              ...data.channels,
            ],
          }));
        },
      },
    ).pipe(map((res) => res.data.createMetaChannel as ChannelFragment[]));
  }

  changeWidgetProperties(
    newProperties: string,
    channelId: string,
    slug?: string,
    propertyId?: string,
  ): void {
    this.apollo.client.cache.updateQuery({
      query: AllChannelsDocument,
    }, (data: AllChannelsQuery) => updateWidgetChannelProperties(
      data,
      channelId,
      newProperties,
      slug,
      propertyId,
    ));
  }

  deleteOperatorFromChannels(operatorId: string): void {
    this.apollo.client.cache.updateQuery({
      query: AllChannelsDocument,
    }, (data: AllChannelsQuery) => deleteOperatorFromChannels(
      data?.channels || [],
      operatorId,
    ));
  }

  fetchChannelsForVirtualOperator(
  ): Observable<ChannelForVirtualOperatorFragment[]> {
    return this.allChannelsForVirtualOperatorGQL.fetch().pipe(
      map((result) => result.data.channels),
    );
  }

  changeChannelProperties(channel: ChannelFragment): void {
    this.apollo.client.cache.updateQuery({
      query: AllChannelsDocument,
    }, (data: AllChannelsQuery) => updateChannelProperties(
      data,
      channel.id,
      channel.features,
    ));
  }

  getAllChannelsWithResponsible():
    Observable<ChannelWithResponsibleFragment[]> {
    return this.allChannelsWithResponsibleGQL.fetch().pipe(
      map((result) => result.data.channels),
    );
  }

  canAccessMail(input: CheckMailAccessInput): Observable<boolean> {
    return this.canAccessMailGQL.mutate(
      {
        input,
      },
    ).pipe(
      map((result) => result.data.canAccessMail),
    );
  }
}

function updateChannelProperties(
  data: AllChannelsQuery,
  channelId: string,
  features?: BotFeature[],
) {
  const existingChannels = data.channels.slice();
  const channelToUpdate = existingChannels
    .find((channel) => channel.id === channelId);

  const fromIndex = existingChannels.indexOf(channelToUpdate);
  const updatedFeatures = features || channelToUpdate.features;

  const newChannel = {
    ...channelToUpdate,
    features: updatedFeatures,
  };

  existingChannels.splice(fromIndex, 1, newChannel);

  return {
    channels: [
      ...existingChannels,
    ],
  };
}

function updateWidgetChannelProperties(
  data: AllChannelsQuery,
  channelId: string,
  newProperties: string,
  slug?: string,
  propertyId?: string,
) {
  const existingChannels = data.channels.slice();
  const channelToUpdate = existingChannels
    .find((channel) => channel.id === channelId);
  const fromIndex = existingChannels.indexOf(channelToUpdate);
  const updatedSlug = slug || channelToUpdate.widgetProperty.slug;
  const updatedPropertyId = propertyId || channelToUpdate.widgetProperty.id;
  const newChannel = {
    ...channelToUpdate,
    widgetProperty: {
      id: updatedPropertyId,
      slug: updatedSlug,
      properties: newProperties,
      isInstalled: channelToUpdate.widgetProperty?.isInstalled
        || INITIAL_IS_INSTALLED_VALUE,
      shopifyStore: channelToUpdate.widgetProperty?.shopifyStore
        || INITIAL_SHOPIFY_SHOP_VALUE,
    },
  };

  existingChannels.splice(fromIndex, 1, newChannel);

  return {
    channels: [
      ...existingChannels,
    ],
  };
}

function deleteChannelFromCache(
  data: AllChannelsQuery,
  channelId: string,
) {
  const existingChannels = data.channels.slice();
  const indexToDelete = existingChannels
    .findIndex((channel) => channel.id === channelId);

  existingChannels.splice(indexToDelete, 1);

  return {
    channels: [
      ...existingChannels,
    ],
  };
}

function deleteOperatorFromChannels(
  channels: ChannelFragment[],
  operatorId: string,
) {
  const existingChannels = channels.slice();

  const updatedChannels = existingChannels.map((channel) => {
    const operatorsNew = channel.assignedOperators.slice();
    const indexToDelete = operatorsNew
      .findIndex((operator) => operator.id === operatorId);

    if (indexToDelete >= 0) {
      operatorsNew.splice(indexToDelete, 1);
    }

    return {
      ...channel,
      assignedOperators: [
        ...operatorsNew,
      ],
    };
  });

  return {
    channels: [
      ...updatedChannels,
    ],
  };
}
