import {
  AbstractControl, FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn, Validators,
} from '@angular/forms';
import { AbstractInputField } from '@app/settings/channels-page/models';
import {
  SWITCH_CONTROL_APPENDIX,
} from '@app/settings/channels-page/channel-details-page/widget-channels-page/widget-channels-page.component';
import { ArrayUtil } from '@app/util/array.util';

export const URL_REGEX
  = /(\b(https?|ftp|file|viber):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
export const PHONE_NUMBER_REGEX = /[0-9]{11,13}/im;

export class CustomValidators {
  static notBlank(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      (control: FormControl) => (
        control.value
        && typeof control.value === 'string'
        && control.value.trim() === ''
          ? { blank: true }
          : null
      ),
    ]);
  }

  static atLeastOneChanged(
    fields: AbstractInputField[],
    transform: (value: any) => any = (value) => value,
  ): ValidatorFn {
    const transformedFields: AbstractInputField[]
      = fields.map((field) => ({
        ...field,
        sourceValue: transform(field.sourceValue),
      }));

    return (group: FormGroup): ValidationErrors | null => {
      const isValid: boolean = transformedFields.some(
        (field) => !CustomValidators.equals(
          transform(group.get(field.name).value),
          field.sourceValue,
        ),
      );

      return isValid
        ? null
        : { notEquivalent: true };
    };
  }

  static equals(value1: any, value2: any): boolean {
    return Array.isArray(value1)
      ? ArrayUtil.equals(value1, value2)
      : value1 === value2;
  }

  static atLeastOneSwitchOn(fields: AbstractInputField[]): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      let valid = false;

      fields
        .filter((field) => field.name.includes(SWITCH_CONTROL_APPENDIX))
        .forEach((field) => {
          if (group.controls[field.name].value) {
            valid = true;
          }
        });

      return valid
        ? null
        : { allSwitchesOff: true };
    };
  }

  static isNumber(control: AbstractControl): boolean {
    return typeof control.value === 'number';
  }

  static notNull(control: AbstractControl): boolean {
    return !!control.value;
  }
}

export function toAbstractFields(formGroup: FormGroup): AbstractInputField[] {
  return Object.entries(formGroup.controls)
    .map(([controlName, control]) => ({
      name: controlName,
      sourceValue: control.value,
    }));
}

export function toFilteredAbstractFields(
  formGroup: FormGroup,
  controlNames: string[],
): AbstractInputField[] {
  return Object.entries(formGroup.controls)
    .filter(([controlName]) => controlNames.includes(controlName))
    .map(([controlName, control]) => ({
      name: controlName,
      sourceValue: control.value,
    }));
}
