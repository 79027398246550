import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ChannelsService } from '@app/settings/channels-page/channels.service';
import {
  CreateWidgetPropertyGQL,
  UpdateWidgetPropertiesGQL,
  WidgetProperty,
} from './graphql/graphql';

@Injectable({ providedIn: 'root' })
export class WidgetPropertiesGqlService {
  constructor(
    private createWidgetPropertyGQL: CreateWidgetPropertyGQL,
    private updateWidgetPropertiesGQL: UpdateWidgetPropertiesGQL,
    private channelPageService: ChannelsService,
  ) {
  }

  updateWidgetProperties(
    channelId: string,
    slug: string,
    properties: string,
  ): Observable<WidgetProperty> {
    return this.updateWidgetPropertiesGQL.mutate({
      slug,
      properties,
    },
    {
      update: (store, { data: { updateWidgetProperties } }) => this
        .channelPageService.changeWidgetProperties(
          properties,
          channelId,
          updateWidgetProperties.slug,
          updateWidgetProperties.id,
        ),
    }).pipe(
      map((result) => result.data.updateWidgetProperties),
    );
  }

  createWidgetProperties(
    channelId: string,
    properties: string,
  ): Observable<WidgetProperty & { channelId: string }> {
    return this.createWidgetPropertyGQL
      .mutate({
        channelId,
        properties,
      },
      {
        update: (store, { data: { createWidgetProperty } }) => this
          .channelPageService.changeWidgetProperties(
            properties,
            channelId,
            createWidgetProperty.slug,
            createWidgetProperty.id,
          ),
      })
      .pipe(
        map((result) => ({ channelId, ...result.data.createWidgetProperty })),
      );
  }
}
