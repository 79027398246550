import {
  Component, EventEmitter, Inject, Input, OnInit, Output,
} from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { FacebookPage } from '@app/model/facebook-page';

@Component({
  selector: 'app-select-from-list',
  templateUrl: './select-from-list.component.html',
  styleUrls: ['./select-from-list.component.scss'],
})
export class SelectFromListComponent implements OnInit {
  pageIsConnectedTooltip: string;

  @Input() pages: FacebookPage[] = [];

  @Output() selectedElement = new EventEmitter<FacebookPage>();

  public constructor(@Inject(I18NEXT_SERVICE)
                     private translationService: ITranslationService) {
  }

  ngOnInit(): void {
    this.pageIsConnectedTooltip = this.translationService.t('fb_channel_page_is_already_connected_tooltip');
  }

  selectPage(page: FacebookPage) {
    this.selectedElement.emit(page);
  }

  getFirstCharacter(name: string): string {
    return name
      ? name.charAt(0)
      : 'P'; // default value
  }
}
