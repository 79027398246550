<app-loading class="loading"></app-loading>
<div class="page" *ngIf="channelFragment$ | async as channelGql">
  <nz-page-header nzBackIcon>
    <nz-breadcrumb nz-page-header-breadcrumb class="cut-text">
      <nz-breadcrumb-item><a [routerLink]="channelsUrl">{{ 'settings.channel_details.breadcrumb-path.channels' | i18next }}</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>{{channelGql.name}}</nz-breadcrumb-item>
    </nz-breadcrumb>
    <nz-page-header-title class="cut-text" data-testid="channel-name-field">{{channelGql.name}}</nz-page-header-title>
    <nz-page-header-subtitle>{{ getChannelViewName(channelGql.messenger.type) }}</nz-page-header-subtitle>
    <nz-page-header-extra>
      <button
        nz-button
        nzType="primary"
        *ngIf="fieldsValidationService.isActive$ | async"
        (click)="onSaveButtonClickEventNew.next(channelGql)"
        [disabled]="fieldsValidationService.isInvalid()"
        data-testid="channels-save-changes-button">
        <i nz-icon nzType="check" nzTheme="outline"></i>
        {{ 'settings.channel_details.save_changes' | i18next }}
      </button>
    </nz-page-header-extra>
  </nz-page-header>
  <ul nz-menu nzMode="horizontal" class="tab-menu">
    <li nz-menu-item nzMatchRouter *ngFor="let menuItem of tabMenuItems$ | async" [hidden]="menuItem.isHidden && menuItem.isHidden(channelGql)" [nzDisabled]="menuItem.disable">
      <a class="tab-menu-item" [routerLink]="[menuItem.uri]">
        {{ menuItem.title }}
        <nz-badge
          nzStandalone
          [nzCount]="menuItem.messageCounter | async">
        </nz-badge>
      </a>
    </li>
  </ul>

  <div class="content content--widget-preview--{{ (isWidgetPreviewShown$ | async) ? 'on' : 'off' }}">
    <div class="settings">
      <router-outlet></router-outlet>
    </div>

    <app-widget-preview *ngIf="isWidgetPreviewShown$ | async"></app-widget-preview>
  </div>
</div>
