import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChatPreviewFilter,
  ChatPreviewFragment,
  ChatPreviewInfo, ChatPreviewLightFragment, ChatSortMode,
  GetChatPreviewByIdGQL, GetChatPreviewLightByIdGQL,
  GetNewChatPreviewsGQL, GetProcessingChatPreviewsGQL,
} from '../graphql/graphql';

const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 50;
const DEFAULT_SORT_MODE = ChatSortMode.NewestLastMessageFirst;

@Injectable({ providedIn: 'root' })
export class ChatPreviewServiceGql {
  constructor(
    private getNewChatPreviewsGQL: GetNewChatPreviewsGQL,
    private getChatPreviewByIdGQL: GetChatPreviewByIdGQL,
    private getChatPreviewLightByIdGQL: GetChatPreviewLightByIdGQL,
    private getProcessingChatPreviewsGQL: GetProcessingChatPreviewsGQL,
  ) {
  }

  findChatPreviewLightByChatId(
    id: string,
  ): Observable<ChatPreviewLightFragment> {
    return this.getChatPreviewLightByIdGQL.fetch(
      { id },
      { fetchPolicy: 'network-only' },
    ).pipe(map((result) => result.data.chat));
  }

  findNewChatsPreviewInfo(
    offset: number = DEFAULT_OFFSET,
    chatPreviewFilter: ChatPreviewFilter,
    sortModes: ChatSortMode[] = [DEFAULT_SORT_MODE],
    limit: number = DEFAULT_LIMIT,
  ): Observable<ChatPreviewInfo> {
    return this.getNewChatPreviewsGQL.fetch({
      offset,
      limit,
      chatPreviewFilter,
      sortModes,
    },
    {
      fetchPolicy: 'network-only',
    })
      .pipe(map((result) => result.data
        .newChatPreviews as ChatPreviewInfo));
  }

  findChatPreviewByChatId(chatId: number): Observable<ChatPreviewFragment> {
    return this.getChatPreviewByIdGQL.fetch({
      id: chatId.toString(),
    },
    {
      fetchPolicy: 'network-only',
    })
      .pipe(map((result) => result.data.chat as ChatPreviewFragment));
  }

  findProcessingChatsPreviewInfo(
    offset: number = DEFAULT_OFFSET,
    chatPreviewFilter: ChatPreviewFilter,
    sortModes: ChatSortMode[] = [DEFAULT_SORT_MODE],
    limit: number = DEFAULT_LIMIT,
  ): Observable<ChatPreviewInfo> {
    return this.getProcessingChatPreviewsGQL.fetch({
      offset,
      limit,
      chatPreviewFilter,
      sortModes,
    },
    {
      fetchPolicy: 'network-only',
    }).pipe(
      map((result) => result.data.processingChatPreviews as ChatPreviewInfo),
    );
  }
}
