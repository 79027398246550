import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { FacebookPage } from '@app/model/facebook-page';
import { FacebookChannel } from '@app/model/facebook-channel';
import { MessengerName } from '@app/model/old-messenger-name';
import { environment } from '../environments/environment';
import { FacebookUserCreationData } from './model/facebook-user-creation-data';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class FacebookUserService {
  private baseUrl = environment.apiBaseUrl;

  private facebookUsersAppendix = 'facebook-users';

  private botsAppendix = '/bots';

  private pagesAppendix = '/pages';

  private channelAppendix = '/channels';

  private tokenAppendix = '/token';

  constructor(private http: HttpClient) {
  }

  create(facebookUser: FacebookUserCreationData) {
    return this.http.post<number>(
      `${this.baseUrl + this.facebookUsersAppendix}`,
      facebookUser,
      httpOptions,
    ).pipe(
      shareReplay(),
    );
  }

  updateToken(facebookUserId: number, channelId: number) {
    return this.http.patch(
      `${this.baseUrl + this.facebookUsersAppendix}/${facebookUserId}
      ${this.botsAppendix}/${channelId}${this.tokenAppendix}`,
      null,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text',
      },
    );
  }

  getPages(facebookUserId: number) {
    return this.http.get<FacebookPage[]>(
      `${this.baseUrl + this.facebookUsersAppendix}/${facebookUserId}${this.pagesAppendix}`,
      httpOptions,
    );
  }

  getChannels(
    facebookUserId: number,
    status: string,
    messengerName: MessengerName,
  ) {
    return this.http.get<FacebookChannel[]>(
      `${this.baseUrl + this.facebookUsersAppendix}/${facebookUserId}${this.channelAppendix}/${messengerName}`,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: new HttpParams().set('status', `${status}`),
      },
    );
  }
}
