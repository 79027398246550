import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppInfoMessageStyle } from '@app/info-message-card/info-message-card.component';
import { InfoMessageTab } from '../channel-details-page.component';

@Injectable({ providedIn: 'root' })
export class InfoMessageService {
  private readonly infoMessages
    = new BehaviorSubject<InfoMessage[]>([]);

  readonly infoMessages$ = this.infoMessages.asObservable();

  add(message: InfoMessage): void {
    const messages = this.infoMessages.value.slice(0);

    messages.push(message);

    this.infoMessages.next(messages);
  }

  hasInfoMessageWithText(text: string): boolean {
    return this.infoMessages.value.some(
      (message) => message.text === text,
    );
  }

  clear(): void {
    this.infoMessages.next([]);
  }

  clearByTab(clearingTab: InfoMessageTab): void {
    const messages = this.infoMessages.value
      .filter((tab) => tab.tab !== clearingTab);

    this.infoMessages.next(messages);
  }

  hasInfoMessages(tab?: InfoMessageTab): Observable<boolean> {
    return this.infoMessages$.pipe(map(
      (messages) => messages.length > 0
        && (!tab || (!!messages.find((message) => message.tab === tab))),
    ));
  }
}

export interface InfoMessage {
  text: string;
  style: AppInfoMessageStyle;
  tab: InfoMessageTab;
  showCloseButton?: boolean;
  buttonText?: string;
  buttonCallback?: () => void;
}
