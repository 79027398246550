export function setAttributeToElement(
  script,
): (attribute: { name: string; value: string }) => void {
  return (attribute) => script.setAttribute(attribute.name, attribute.value);
}

export const DECIMAL_RADIX = 10;

export function getElementContentBottomIndent(element: HTMLElement) {
  return parseInt(getComputedStyle(element).marginBottom, DECIMAL_RADIX)
    + parseInt(getComputedStyle(element).borderBottomWidth, DECIMAL_RADIX)
    + parseInt(getComputedStyle(element).paddingBottom, DECIMAL_RADIX);
}

const LESS_THAN = '<';
const LESS_THAN_ENTITY = '&lt;';
const GREATER_THAN = '>';
const GREATER_THAN_ENTITY = '&gt;';
const HTML_BREAK_LINE_REGEX_GLOBAL = /<\s*br\s*\/?>/gi;
const TEXT_NEW_LINE = '\n';

export function escapeHtml(value: string): string {
  return value
    .replaceAll(LESS_THAN, LESS_THAN_ENTITY)
    .replaceAll(GREATER_THAN, GREATER_THAN_ENTITY);
}

export function unescapeHtml(value: string): string {
  return value
    .replaceAll(LESS_THAN_ENTITY, LESS_THAN)
    .replaceAll(GREATER_THAN_ENTITY, GREATER_THAN);
}

export function transformBreakLinesToText(text: string): string {
  return text.replaceAll(HTML_BREAK_LINE_REGEX_GLOBAL, TEXT_NEW_LINE);
}

export function removeCharactersBeforeRange(range: Range, amountToRemove = 1) {
  if (range.startOffset >= amountToRemove) {
    range.setStart(range.startContainer, range.startOffset - amountToRemove);
    range.deleteContents();
  } else {
    const prevNode = range.startContainer.previousSibling;

    if (
      prevNode
      && prevNode.nodeType === Node.TEXT_NODE
      && prevNode instanceof CharacterData
    ) {
      prevNode.deleteData(prevNode.length - amountToRemove, amountToRemove);
    }
  }
}

export function isStringBeforeRange(range: Range, target: string): boolean {
  const clonedRange = range.cloneRange();

  clonedRange.setStart(
    range.startContainer,
    range.startOffset - target.length,
  );
  clonedRange.setEnd(range.startContainer, range.startOffset);

  return clonedRange.toString() === target;
}

export function getElementIdsFromRange(range) {
  const ancestor = range.commonAncestorContainer;
  const elements: HTMLElement[] = ancestor.nodeType === Node.TEXT_NODE
    ? Array.from(ancestor.parentNode.children)
    : Array.from(ancestor.children);

  return elements.filter((element) => range.intersectsNode(element))
    .map((element) => element.id)
    .filter((id) => id);
}

export function extendRangeStartByOne(range: Range): Range {
  const newRange = range.cloneRange();

  if (range.startOffset > 0) {
    newRange.setStart(range.startContainer, range.startOffset - 1);

    return newRange;
  }

  let node = range.startContainer.previousSibling;

  while (node) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (!node.textContent) {
        node = node.previousSibling;

        // eslint-disable-next-line no-continue
        continue;
      }

      newRange.setStart(node, (node as Text).length);
    } else {
      newRange.setStartBefore(node);
    }

    return newRange;
  }

  if (range.startContainer.parentNode) {
    newRange.setStartBefore(range.startContainer.parentNode);

    return newRange;
  }

  throw new Error('Cannot extend range start');
}

export function extendRangeEndByOne(range: Range): Range {
  const newRange = range.cloneRange();

  if (range.endContainer.nodeType === Node.TEXT_NODE
      && range.endOffset < (range.endContainer as Text).length) {
    newRange.setEnd(range.endContainer, range.endOffset + 1);

    return newRange;
  }

  if (range.endContainer.nextSibling) {
    const { nextSibling } = range.endContainer;

    if (nextSibling.nodeType === Node.TEXT_NODE) {
      newRange.setEnd(nextSibling, 1);
    } else {
      newRange.setEndAfter(nextSibling);
    }

    return newRange;
  }

  if (range.endContainer.parentNode) {
    newRange.setEndAfter(range.endContainer.parentNode);

    return newRange;
  }

  throw new Error('Cannot extend range end');
}
