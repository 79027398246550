import { Injectable } from '@angular/core';
import { FormValidationService } from '@app/form-validation-service';
import { ChannelValidationService } from '@app/sign-up-channels-page/channels/channel-validation.service';
import {
  CONTROL_NAME,
} from '@app/sign-up-channels-page/channels/create-channel-buttons/meta-channels/create-whatsapp-channel/create-whats-app-channel.component';
import { MessengerProviderName, MessengerType } from '@app/graphql/graphql';
import { MessengerProviderDataField } from '../model/messenger-provider-data-field';

const PHONE_NUMBER_MASK = '+000 00 000 00 099';

@Injectable({
  providedIn: 'root',
})
export class MessengerProviderFieldsService {
  readonly generalChannelFields: MessengerProviderDataField[] = [
    {
      labelSlug: 'channel.name',
      name: CONTROL_NAME.channelName,
      placeholderSlug: 'enter.channel.name.here',
      tooltipSlug: 'channel.name.tooltip',
      validators: [
        this.channelValidationService.uniqueNameValidator(
          MessengerType.Whatsapp,
          MessengerProviderName.Twilio,
        ),
      ],
    },
    {
      labelSlug: 'whatsapp.phone.number.title',
      name: CONTROL_NAME.phoneNumber,
      placeholderSlug: '+000 00 000 00 00',
      tooltipSlug: 'whatsapp.phone.number.description',
      validators: [
        this.formValidationService.validPhoneNumber(),
      ],
      mask: PHONE_NUMBER_MASK,
    },
  ];

  private readonly messengerProviderFieldsNew: Record<
    string,
    MessengerProviderDataField[]
  > = {
    TWILIO: [
      {
        labelSlug: 'Twilio Account SID',
        name: CONTROL_NAME.accountSid,
        placeholderSlug: 'Ladnas7a1amsod93kmasdoqmegpmcin3kn8ks58df',
        tooltipSlug: 'find.twilio.account.sid',
      },
      {
        labelSlug: 'Twilio Auth token',
        name: CONTROL_NAME.authToken,
        placeholderSlug: 'qeqwe12kmqo934m13wekmdq42as234k29a',
        tooltipSlug: 'find.twilio.auth.token',
      },
      {
        labelSlug: 'Messaging Service SID',
        name: CONTROL_NAME.messagingServiceSid,
        placeholderSlug: 'qeqwe12kmqo934m13wekmdq42as234k29a',
        tooltipSlug: 'find.twilio.messaging-service.sid',
      },
    ],
  };

  private readonly messengerProviderFieldsMap
    = new Map<string, MessengerProviderDataField[]>(
      Object.entries(this.messengerProviderFieldsNew),
    );

  constructor(
    private channelValidationService: ChannelValidationService,
    private formValidationService: FormValidationService,
  ) {
  }

  getFieldsByMessengerProviderName(
    messengerProviderName: string,
  ): MessengerProviderDataField[] {
    return this.messengerProviderFieldsMap.get(messengerProviderName);
  }
}
