import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { EMPTY_FUNCTION } from '@app/util/function.util';
import {
  InnerOperatorRequest,
  InnerOperatorRequestsDocument,
  InnerOperatorRequestStatus,
  Message,
} from '@app/graphql/graphql';
import { MessageUtilService } from '@app/util/message-util.service';
import { orElse } from '@app/util/object.util';

@Injectable({
  providedIn: 'root',
})
export class InnerOperatorRequestCacheService {
  constructor(
    private apollo: Apollo,
    private messageUtilService: MessageUtilService,
  ) {
  }

  updateChatLastMessageByStatus(
    status: InnerOperatorRequestStatus,
    message: Message,
  ): void {
    this.apollo.client.cache.updateQuery(
      {
        query: InnerOperatorRequestsDocument,
        variables: { status },
      },
      (data: any): any => {
        const requestedRequests: InnerOperatorRequest[]
          = data?.innerOperatorRequests as InnerOperatorRequest[];

        return {
          innerOperatorRequests: requestedRequests?.map(
            (
              request: InnerOperatorRequest,
            ): InnerOperatorRequest => (
              Number(request.chat.id) === Number(message.chat.id)
                ? {
                  ...request,
                  unreadMessagesCounter: request.unreadMessagesCounter + 1,
                  chat: {
                    ...request.chat,
                    lastMessage: {
                      ...request.chat.lastMessage,
                      ...message,
                      senderUser: message.senderUser
                        ? { ...request.chat.lastMessage.senderUser }
                        : null,
                    },
                    isAnswered: this.messageUtilService.isAnswerToUser(
                      request.chat.isAnswered,
                      message,
                    ),
                  },
                }
                : request),
          ),
        };
      },
    );
  }

  updateRequestCounterByStatusAndChatId(
    status: InnerOperatorRequestStatus,
    chatId: string,
    newCounter: number,
  ): void {
    this.apollo.client.cache.updateQuery(
      {
        query: InnerOperatorRequestsDocument,
        variables: { status },
      },
      (data: any): any => {
        const requestedRequests: InnerOperatorRequest[]
          = data?.innerOperatorRequests as InnerOperatorRequest[];

        return {
          innerOperatorRequests: requestedRequests?.map(
            (
              request: InnerOperatorRequest,
            ): InnerOperatorRequest => (
              Number(request.chat.id) === Number(chatId)
                ? {
                  ...request,
                  unreadMessagesCounter: newCounter,
                }
                : request),
          ),
        };
      },
    );
  }

  removeRequestFromRequested(
    requestId: string,
    processRemovedRequest
      : (requests: InnerOperatorRequest[]) => void = EMPTY_FUNCTION,
  ): void {
    this.apollo.client.cache.updateQuery(
      {
        query: InnerOperatorRequestsDocument,
        variables: { status: InnerOperatorRequestStatus.Requested },
      },
      (data: any): any => {
        if (!data) {
          return data;
        }

        const requests: InnerOperatorRequest[]
          = data.innerOperatorRequests as InnerOperatorRequest[];
        const newRequestedRequests: InnerOperatorRequest[]
          = requests.filter(
            (
              request: InnerOperatorRequest,
            ): boolean => request.id !== requestId,
          );

        if (requests.length > newRequestedRequests.length) {
          processRemovedRequest(requests);
        }

        return {
          innerOperatorRequests: newRequestedRequests,
        };
      },
    );
  }

  removeRequestFromAccepted(
    requestId: string,
    handleIfRemove: () => void = EMPTY_FUNCTION,
  ): void {
    this.apollo.client.cache.updateQuery(
      {
        query: InnerOperatorRequestsDocument,
        variables: { status: InnerOperatorRequestStatus.Accepted },
      },
      (data: any): any => {
        if (!data) {
          return data;
        }

        const acceptedRequests: InnerOperatorRequest[]
          = data.innerOperatorRequests as InnerOperatorRequest[];
        const newAcceptedRequests: InnerOperatorRequest[]
          = acceptedRequests.filter(
            (
              request: InnerOperatorRequest,
            ): boolean => request.id !== requestId,
          );

        if (acceptedRequests.length > newAcceptedRequests.length) {
          handleIfRemove();
        }

        return {
          innerOperatorRequests: newAcceptedRequests,
        };
      },
    );
  }

  addRequestToAccepted(
    updatedRequestId: string,
    requestedRequests: InnerOperatorRequest[],
  ): void {
    const newAcceptedRequest: InnerOperatorRequest = {
      ...requestedRequests.find(
        (
          innerOperatorRequest: InnerOperatorRequest,
        ): boolean => innerOperatorRequest.id === updatedRequestId,
      ),
      status: InnerOperatorRequestStatus.Accepted,
    };

    this.apollo.client.cache.updateQuery(
      {
        query: InnerOperatorRequestsDocument,
        variables: { status: InnerOperatorRequestStatus.Accepted },
      },
      (data: any): any => ({
        innerOperatorRequests: data
          ? [
            newAcceptedRequest,
            ...data.innerOperatorRequests,
          ]
          : newAcceptedRequest,
      }),
    );
  }

  addRequestToRequested(
    newRequest: InnerOperatorRequest,
  ): void {
    this.apollo.client.cache.updateQuery(
      {
        query: InnerOperatorRequestsDocument,
        variables: { status: InnerOperatorRequestStatus.Requested },
      },
      (data: any): any => ({
        innerOperatorRequests: data
          ? [
            {
              ...newRequest,
              operator: orElse(
                newRequest.operator,
                null,
              ),
            },
            ...data.innerOperatorRequests,
          ]
          : newRequest,
      }),
    );
  }
}
