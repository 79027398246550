import { NgModule } from '@angular/core';
import {
  Routes, RouterModule,
} from '@angular/router';
import { AuthGuardService } from '@app/auth/auth-guard.service';
import { ShopifyInstallationProxyComponent } from '@app/route-proxy/shopify-installation-proxy.component';

const routes: Routes = [
  {
    path: 'chats',
    loadChildren: () => import('./chat/chat.module')
      .catch(handleChunkLoadError('/chats/new'))
      .then((m) => m.ChatModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'subscription-plans',
    loadChildren: () => import('./expired-plan-page/expired-plan-page.module')
      .catch(handleChunkLoadError('/subscription-plans'))
      .then((m) => m.ExpiredPlanPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'schedule-message',
    loadChildren: () => import('./schedule-message/schedule-message.module')
      .catch(handleChunkLoadError('/schedule-message'))
      .then((m) => m.ScheduleMessageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'select-widget',
    loadChildren: () => import('./select-widget/select-widget.module')
      .catch(handleChunkLoadError('/select-widget'))
      .then((m) => m.SelectWidgetModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    loadChildren: () => import('@app/settings/page/settings-page.module')
      .catch(handleChunkLoadError('/settings'))
      .then((m) => m.SettingsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'analytics',
    loadChildren: () => import('@app/analytics/analytics-page/analytics-page.module')
      .catch(handleChunkLoadError('/analytics'))
      .then((m) => m.AnalyticsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password-page/forgot-password-page.module')
      .catch(handleChunkLoadError('/forgot-password'))
      .then((m) => m.ForgotPasswordPageModule),
  },
  {
    path: 'sign-up/channels',
    loadChildren: () => import('./sign-up-channels-page/sign-up-channels-page.module')
      .catch(handleChunkLoadError('/sign-up/channels'))
      .then((m) => m.SignUpChannelsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment-page/payment-page.module')
      .catch(handleChunkLoadError('/payment'))
      .then((m) => m.PaymentPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'payment/:id/successful',
    loadChildren: () => import('./payment/successful-payment/successful-payment.module')
      .then((m) => m.SuccessfulPaymentModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'payment/:id/failed',
    loadChildren: () => import('./payment/failed-payment/failed-payment.module')
      .then((m) => m.FailedPaymentModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'shopify-payment',
    loadChildren: () => import('./payment/shopify-payment-page/shopify-payment-page.module')
      .catch(handleChunkLoadError('/shopify-payment'))
      .then((m) => m.ShopifyPaymentPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '404',
    loadChildren: () => import('./page-not-found/page-not-found.module')
      .catch(handleChunkLoadError('/404'))
      .then((m) => m.PageNotFoundModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./login-page/login-page.module')
      .catch(handleChunkLoadError('/sign-up'))
      .then((m) => m.LoginPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./change-password-page/change-password-page.module')
      .catch(handleChunkLoadError('/reset-password'))
      .then((m) => m.ChangePasswordPageModule),
  },
  {
    path: 'sign-up/completion',
    redirectTo: 'sign-up',
  },
  {
    path: 'settings/channels/:channelId/installation/shopify',
    component: ShopifyInstallationProxyComponent,
  },
  {
    path: 'login',
    loadChildren: () => import('./login-page/login-page.module')
      .catch(handleChunkLoadError('/login'))
      .then((m) => m.LoginPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

function handleChunkLoadError(path: string): (error: Error) => any {
  return (): void => {
    window.location.href = path;
  };
}

@NgModule({
  declarations: [
    ShopifyInstallationProxyComponent,
  ],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
