<ng-container>
  <app-loading></app-loading>
  <nz-layout class="payment-page" *ngIf="shopifyStores">
    <nz-header class="header">
      <app-logotype height="40" width="130"></app-logotype>
      <div class="header__title">
        {{ paymentTransaction?.description }}
      </div>
      <div class="header__close-button">
        <app-circle-button (click)="closePayment()">
          <span nz-icon nzType="close" nzTheme="outline"></span>
        </app-circle-button>
      </div>
    </nz-header>
  <nz-content>
    <div nz-row nzJustify="center">
      <div nz-col [nzXs]="22" [nzLg]="8" [nzXXl]="8">
        <nz-steps [nzCurrent]="currentStepIndex" (nzIndexChange)="onIndexChange($event)">
          <nz-step [nzTitle]="'settings.billing.subscription_payment.first_step' | i18next"></nz-step>
          <nz-step
            [nzTitle]="'settings.billing.subscription_payment.second_step' | i18next"
            [nzDisabled]="!hasAllBillingDetails"
          >
          </nz-step>
        </nz-steps>
        <ng-container *ngIf="currentStepIndex === 0">
          <div class="form-box">
            <div class="billing-details-action">
              <app-billing-details-form
                [isBillingDetailsEditorPage]="false"
                (formGroupEmitter)="setFormGroup($event)"
                (billingDetailsEmitter)="setBillingDetails($event)"
              >
              </app-billing-details-form>
            </div>
            <div class="billing-payment-details">
              <app-payment-details
                *ngIf="paymentTransaction"
                [paymentTransaction]="paymentTransaction"
                [recalculationInfo]="recalculationInfo"
              ></app-payment-details>
              <button
                class="next_button"
                nz-button
                nzType="primary"
                (click)="moveToPaymentMethod()"
              >
                {{ 'billing_payment_details.save_and_continue' | i18next }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStepIndex === 1">
          <ng-container *ngIf="false; else shopifyStoresList"></ng-container>
        </ng-container>
        <ng-template #shopifyStoresList>
          <div class="form-box">
            <div class="payment-details" *ngIf="paymentTransaction">
              <app-payment-details
                [paymentTransaction]="paymentTransaction"
                [recalculationInfo]="recalculationInfo"
              ></app-payment-details>
            </div>
            <div class="payment-action">
              <p class="plan-info-details">
                {{ 'shopify_stores.select_one' | i18next }}
              </p>
              <app-shopify-store-list
                [stores]="shopifyStores"
                [buttonText]="'shopify_stores.activate_button' | i18next"
                [selectedStore]="selectedStore"
                (selectedStoreEmitter)="setSelectedStore($event)"
                (selectedStoreToActivateEmitter)="activate(subscriptionPlan.id, $event.id)"
              >
              </app-shopify-store-list>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </nz-content>
</nz-layout>
</ng-container>
