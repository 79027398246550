import { Component, Input } from '@angular/core';
import { LoadingService } from './loading.service';

const DEFAULT_BACKGROUND_OPACITY = 0.1;

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input()
  top = 'none';

  @Input()
  backgroundOpacity = DEFAULT_BACKGROUND_OPACITY;

  constructor(public loadingService: LoadingService) {
  }
}
