interface WidgetChannelCredentials {
  src: string;
  slug: string;
}

const CHANNELS: [string, WidgetChannelCredentials][] = [
  [
    'development', {
      src: 'https://dev.cusbo.tech/widget/app.js',
      slug: 'r0tDIzTxiVwHkxl8',
    },
  ],
  [
    'staging', {
      src: 'https://stage.cusbo.tech/widget/app.js',
      slug: 'uTst0YD4O5OFZ01P',
    },
  ],
  [
    'production', {
      src: 'https://app.cusbo.tech/widget/app.js',
      slug: 'N4v2mMW3umwYOwiA',
    },
  ],
];

const ENV_WIDGET_CHANNELS_MAP
  = new Map<string, WidgetChannelCredentials>(CHANNELS);

export function getWidgetChannelByEnv(env: string): WidgetChannelCredentials {
  return ENV_WIDGET_CHANNELS_MAP.get(env)
    || ENV_WIDGET_CHANNELS_MAP.get('development');
}
