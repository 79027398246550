import { defaultInterpolationFormat, I18NextModule } from 'angular-i18next';
import { localeDetector } from '@app/middleware/i18n/localeDetector';
import { LOCAL_STORAGE_KEY } from '@src/constants/local_storage.constants';
import { environment } from '@src/environments/environment';

const LANGUAGE_URL_PARAM = 'lang';
const translatesEndpoint = `${environment.ip + environment.apiBaseUrl}translates/slug-value-pair`;

export const DEFAULT_LANGUAGE = 'en';
const WHITE_LIST = ['en', 'uk', 'ka', 'ru'];
const WHITE_LIST_BROWSER_DETECTION = ['en', 'uk', 'ka', 'ru'];
const LANGUAGE_INDEX = 0;

export const i18nextOptions = {
  lng: getLanguage(),
  supportedLngs: WHITE_LIST,
  preload: WHITE_LIST,
  whitelist: WHITE_LIST,
  fallbackLng: 'en',
  returnEmptyString: false,
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },
  backend: {
    loadPath: `${translatesEndpoint}/?lang={{lng}}`,
  },
  customDetectors: [localeDetector],
  detection: {
    order: ['domain'],
  },
};

function getLanguage(): string {
  const urlLanguageParam: string
    = new URLSearchParams(window.location.search).get(LANGUAGE_URL_PARAM);

  if (urlLanguageParam) {
    localStorage.setItem(LOCAL_STORAGE_KEY.language, urlLanguageParam);
  }

  const languageFromStorage: string
    = urlLanguageParam || localStorage.getItem(LOCAL_STORAGE_KEY.language);

  if (
    languageFromStorage && WHITE_LIST.some(
      (language) => language === languageFromStorage,
    )
  ) {
    return languageFromStorage;
  }

  const browserLanguageCode = navigator?.language;

  if (browserLanguageCode) {
    const browserLanguage = browserLanguageCode.split('-')[LANGUAGE_INDEX];

    if (
      WHITE_LIST_BROWSER_DETECTION.some(
        (language) => browserLanguage === language,
      )
    ) {
      return browserLanguage;
    }
  }

  return DEFAULT_LANGUAGE;
}
