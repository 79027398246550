import {
  Directive, ElementRef, Input, OnDestroy, OnInit,
} from '@angular/core';
import { map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointState } from '@angular/cdk/layout';
import { Breakpoint } from '@app/responsive/model/breakpoint';
import { CSS_CLASS_MAP, CSS_MODIFIER } from '../responsive.config';
import { ResponsiveService } from '../service/responsive.service';

const DEFAULT_BREAKPOINTS: Breakpoint[] = [
  Breakpoint.DESKTOP,
  Breakpoint.MOBILE,
];

@Directive({
  selector: '[appAdaptive]',
})
export class AdaptiveDirective implements OnInit, OnDestroy {
  private readonly isComponentDestroyed: Subject<boolean>
    = new Subject<boolean>();

  @Input() appAdaptive: string;

  @Input() breakpoints: Breakpoint[] = DEFAULT_BREAKPOINTS;

  constructor(
    private host: ElementRef,
    private responsiveService: ResponsiveService,
  ) {
  }

  ngOnInit(): void {
    this.responsiveService.observe$(this.breakpoints).pipe(
      takeUntil(this.isComponentDestroyed),
      map(this.toCssClasses.bind(this)),
    ).subscribe(this.updateCssClasses.bind(this));
  }

  private updateCssClasses(classes: string[]): void {
    const element = this.host.nativeElement;

    Array.from(element.classList)
      .filter((cssClass: string): boolean => cssClass.includes(CSS_MODIFIER))
      .forEach((cssClass: string): void => element.classList.remove(cssClass));

    classes
      .map(this.toCssModifier.bind(this))
      .forEach((cssClass) => element.classList.add(cssClass));
  }

  private toCssClasses(state: BreakpointState): string[] {
    return Object.entries(state.breakpoints)
      .filter(([, isActive]) => isActive)
      .map(([breakpoint]) => CSS_CLASS_MAP.get(breakpoint as Breakpoint))
      .filter((cssClass) => !!cssClass);
  }

  private toCssModifier(modifierState: string): string {
    return this.appAdaptive
      ? `${this.appAdaptive}--${CSS_MODIFIER}--${modifierState}`
      : `${CSS_MODIFIER}--${modifierState}`;
  }

  ngOnDestroy(): void {
    this.isComponentDestroyed.next(true);
    this.isComponentDestroyed.complete();
  }
}
