import { Injectable } from '@angular/core';
import { fromEvent, Observable, tap } from 'rxjs';

const EVENT_NAME = 'beforeinstallprompt';

@Injectable({
  providedIn: 'root',
})
export class PwaInstallationService {
  readonly beforeInstallPromptEvent$: Observable<any>
    = this.getBeforeInstallPromptEvent();

  private deferredPrompt: any;

  prompt(): void {
    this.deferredPrompt?.prompt();
  }

  private getBeforeInstallPromptEvent(): Observable<any> {
    return fromEvent(window, EVENT_NAME).pipe(
      tap((event: any): void => {
        event.preventDefault();
        this.deferredPrompt = event;
      }),
    );
  }
}
