import { SubscriptionPlanPeriod } from '@app/graphql/graphql';

export const PERIOD_COST_TO_TRANSLATION_CODE = {
  [SubscriptionPlanPeriod.Month]: 'settings.billing.cost_period.month',
  [SubscriptionPlanPeriod.Year]: 'settings.billing.cost_period.year',
};

export const PLAN_DURATION_TO_SINGULAR_TRANSLATION_CODE = {
  [SubscriptionPlanPeriod.Month]: 'settings.billing.duration.month.singular',
  [SubscriptionPlanPeriod.Year]: 'settings.billing.duration.year.singular',
};

export const PLAN_DURATION_TO_PLURAL_TRANSLATION_CODE = {
  [SubscriptionPlanPeriod.Month]: 'settings.billing.duration.month.plural',
  [SubscriptionPlanPeriod.Year]: 'settings.billing.duration.year.plural',
};
