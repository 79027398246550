import {
  Component, Inject, Input, OnInit,
} from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import {
  PaymentTransaction,
  SubscriptionPlan,
  SubscriptionRecalculationInfo,
} from '@app/graphql/graphql';
import { TranslationUtilService } from '@app/util/translations/translation-util.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit {
  @Input() paymentTransaction: PaymentTransaction;

  @Input() recalculationInfo: SubscriptionRecalculationInfo;

  paymentDetails: PaymentDetails[] = [];

  totalPeriod: string;

  paymentMethodMessage: string;

  constructor(
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private translationUtilService: TranslationUtilService,
  ) {}

  ngOnInit(): void {
    this.initPaymentDetails();
    this.totalPeriod = this.getPeriod(this.paymentTransaction);
    this.paymentMethodMessage = this.formTransactionDetails(
      this.paymentTransaction,
    );
  }

  private initPaymentDetails(): void {
    if (this.paymentTransaction.subscription?.subscriptionPlan) {
      const billingPeriod = this.translationUtilService.getPeriodText(
        this.paymentTransaction.subscription.subscriptionPlan.period,
      );
      const subscriptionPlan = {
        name: this.paymentTransaction.subscription?.subscriptionPlan.name,
        price: `${this.getCurrencySymbol(this.paymentTransaction.currency.code)} ${this.getPlanPrice(this.paymentTransaction.subscription.subscriptionPlan)}`,
        period: billingPeriod,
      };

      this.paymentDetails.push(subscriptionPlan);

      if (this.paymentTransaction.subscriptionOperatorHistory) {
        const operatorSeats = {
          name: this.translationService.t(
            'payment_details.number_of_extra_seats',
            {
              number_of_extra_seats: this.paymentTransaction
                .subscriptionOperatorHistory.operatorSeats,
            },
          ),
          price: `${this.getCurrencySymbol(this.paymentTransaction.currency.code)} ${this.getOperatorPrice(this.paymentTransaction)}`,
          period: billingPeriod,
        };

        this.paymentDetails.push(operatorSeats);
      }
    }
  }

  private getCurrencySymbol(code: string): string {
    return getCurrencySymbol(code, 'narrow');
  }

  private getPlanPrice(subscriptionPlan: SubscriptionPlan): number {
    return subscriptionPlan.salePrice || subscriptionPlan.defaultPrice;
  }

  private getOperatorPrice(paymentTransaction: PaymentTransaction): number {
    return paymentTransaction.subscriptionOperatorHistory.operatorSeats
      * paymentTransaction.subscription.subscriptionPlan.operatorPrice;
  }

  getSubscriptionChangingMessage(
    recalculationInfo: SubscriptionRecalculationInfo,
  ): string {
    if (recalculationInfo) {
      return this.translationService.t(
        'payment_details.changing_message',
        {
          previous_unused_days: recalculationInfo.previousUnusedDays,
          new_additional_days: recalculationInfo.newAdditionalDays,
        },
      );
    }

    return null;
  }

  private getPeriod(payment: PaymentTransaction): string {
    return payment.subscription?.subscriptionPlan
      ? this.getPeriodWithDuration(payment)
      : this.translationService.t('payment_details.period');
  }

  private getPeriodWithDuration(payment: PaymentTransaction): string {
    return `/ ${this.translationUtilService.getSubscriptionPlanDurationText(payment.subscription.subscriptionPlan)}`;
  }

  private formTransactionDetails(paymentTransaction: PaymentTransaction) {
    if (this.isPaymentMethodTransaction(paymentTransaction)) {
      return this.translationService.t(
        'payment_details.update_payment_method.warning',
        {
          amount: `${this.getCurrencySymbol(paymentTransaction.currency.code)}${paymentTransaction.amount}`,
        },
      );
    }

    return null;
  }

  private isPaymentMethodTransaction(transaction: PaymentTransaction) {
    return transaction.amount === 1
      && (transaction.description === 'Edit payment method'
        || transaction.description === 'Add payment method');
  }
}

interface PaymentDetails {
  name: string;
  price: string;
  period: string;
}
