import { Injectable } from '@angular/core';
import amplitude, { Callback } from 'amplitude-js';
import { LocalStorageService } from '@app/local-storage-service';
import { SentryService } from '@app/sentry.service';
import { environment } from '../environments/environment';

const ROLE_SEPARATOR = ',';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor(
    private sentryService: SentryService,
    private localStorageService: LocalStorageService,
  ) {
    amplitude.init(
      environment.amplitudeApiKey,
      this.localStorageService.getCurrentOperatorIdString(),
    );
    amplitude.setUserProperties({
      roles: this.getFilteredOperatorRoles(),
    });
  }

  sendAmplitudeEvent(type: string,
    data: {[key: string]: any} = {},
    callback?: Callback) {
    amplitude.logEvent(type, data, callback);
    this.sentryService.info(`Amplitude event "${type}" was sent.`);
  }

  getFilteredOperatorRoles(): string {
    return this.localStorageService.getRoles().join(ROLE_SEPARATOR);
  }
}
