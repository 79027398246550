import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { initializeApp } from 'firebase/app';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  EyeFill,
  EyeInvisibleOutline,
  LockFill,
  MailFill,
} from '@ant-design/icons-angular/icons';
import { I18NextModule } from 'angular-i18next';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { DynamicHooksModule } from 'ngx-dynamic-hooks';
import localeUK from '@angular/common/locales/uk';
import localeRu from '@angular/common/locales/ru';
import localeKa from '@angular/common/locales/ka';
import { I18N_PROVIDERS } from '@app/middleware/i18n/i18n.providers';
import { GlobalErrorHandler } from '@app/global-error-handler';
import { AuthGuardService } from '@app/auth/auth-guard.service';
import { httpInterceptorProviders } from '@app/auth/auth-interceptor';
import { httpStatusInterceptorProviders } from '@app/error/error-status-interceptor';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CanDeactivateGuard } from '@app/can-deactivate-guard';
import { SvgImageModule } from '@app/svg-image/svg-image.module';
import { InternetStatusTrackerModule } from '@app/internet-status-tracker/internet-status-tracker.module';
import { AppUtilModule } from '@app/app-util.module';
import { environment } from '@src/environments/environment';
import { PaymentPageModule } from '@app/payment/payment-page/payment-page.module';
import { AppAttachmentViewModule } from '@app/attachment-view/app-attachment-view.module';
import {
  ShopifyPaymentPageModule,
} from '@app/payment/shopify-payment-page/shopify-payment-page.module';
import { TranslationLinksPipe } from '@app/util/translations/translation-links.pipe';
import { ParametrizedTranslationPipe } from '@app/util/translations/parametrized-translation.pipe';
import { CustomTimeagoIntl } from '@app/pipe/timeago/custom-timeago-intl';
import { CustomTimeagoFormatter } from '@app/pipe/timeago/custom-timeago-formatter';
import { GraphQLModule } from './graphql.module';

initializeApp(environment.firebase);

registerLocaleData(localeUK);
registerLocaleData(localeRu);
registerLocaleData(localeKa);

const icons: IconDefinition[] = [
  EyeFill,
  EyeInvisibleOutline,
  MailFill,
  LockFill,
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NzNotificationModule,
    NzMessageModule,
    I18NextModule.forRoot(),
    SocialLoginModule,
    GraphQLModule,
    SvgImageModule,
    ReactiveFormsModule,
    FormsModule,
    InternetStatusTrackerModule,
    AppUtilModule,
    PaymentPageModule,
    ShopifyPaymentPageModule,
    AppAttachmentViewModule,
    TimeagoModule.forRoot({
      intl: {
        provide: TimeagoIntl,
        useClass: CustomTimeagoIntl,
      },
      formatter: {
        provide: TimeagoFormatter,
        useClass: CustomTimeagoFormatter,
      },
    }),
    DynamicHooksModule.forRoot({}),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.metaAppId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    httpInterceptorProviders, httpStatusInterceptorProviders,
    AuthGuardService, CookieService, DatePipe,
    NzModalService, CanDeactivateGuard,
    I18N_PROVIDERS,
    TranslationLinksPipe, ParametrizedTranslationPipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NZ_ICONS, useValue: icons },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => { },
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
