import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { I18NextModule } from 'angular-i18next';
import { RouterModule } from '@angular/router';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { InfoMessageCardModule } from '@app/info-message-card/info-message-card.module';
import { LoadingModule } from '@app/loading/loading.module';
import { PaymentMethodModule } from '@app/user-card/payment-method.module';
import { NotEnoughPermissionsModule } from '@app/not-enough-permissions/not-enough-permissions.module';
import {
  BillingDetailsFormComponent,
} from '@app/settings/billing/details/form/billing-details-form.component';
import { LongNameModule } from '@app/common/long-name/long-name.module';
import { AppUtilModule } from '@app/app-util.module';
import {
  BillingDetailsEditorPageComponent,
} from '@app/settings/billing/details/editor-page/billing-details-editor-page.component';
import { ConfirmDeletePaymentMethodModalComponent } from '@app/settings/billing/modal/confirm-delete-payment-method/confirm-delete-payment-method-modal.component';
import { ConfirmCancelSubscriptionModalComponent } from '@app/settings/billing/modal/confirm-cancel-subscription/confirm-cancel-subscription-modal.component';
import { BillingPageComponent } from '@app/settings/billing/page/billing-page.component';
import { BillingDetailsComponent } from '@app/settings/billing/details/billing-details.component';
import {
  BillingMenageSeatsPageComponent,
} from '@app/settings/billing/page/manage-seats/billing-menage-seats-page.component';
import { ManageSeatsCardComponent } from '@app/settings/billing/page/manage-seats/card/manage-seats-card.component';
import { InputNumberControlModule } from '@app/common/input-number-control/input-number-control.module';
import { LoadingService } from '@app/loading/loading.service';
import { SettingsBillingPageComponent } from './settings-billing-page.component';

@NgModule({
  declarations: [
    SettingsBillingPageComponent,
    BillingPageComponent,
    BillingDetailsEditorPageComponent,
    ConfirmDeletePaymentMethodModalComponent,
    ConfirmCancelSubscriptionModalComponent,
    BillingDetailsComponent,
    BillingDetailsFormComponent,
    BillingMenageSeatsPageComponent,
    ManageSeatsCardComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    NzLayoutModule,
    NzPageHeaderModule,
    NzToolTipModule,
    InfoMessageCardModule,
    LoadingModule,
    NotEnoughPermissionsModule,
    NzTableModule,
    NzTagModule,
    NzIconModule,
    NzTypographyModule,
    NzSpinModule,
    NzModalModule,
    PaymentMethodModule,
    I18NextModule,
    RouterModule,
    NzBreadCrumbModule,
    NzFormModule,
    ReactiveFormsModule,
    A11yModule,
    NzInputModule,
    NzSelectModule,
    LongNameModule,
    NzProgressModule,
    AppUtilModule,
    NzBadgeModule,
    NzDividerModule,
    InputNumberControlModule,
  ],
  providers: [
    ConfirmDeletePaymentMethodModalComponent,
    ConfirmCancelSubscriptionModalComponent,
    LoadingService,
  ],
  exports: [
    BillingPageComponent,
    BillingDetailsEditorPageComponent,
    SettingsBillingPageComponent,
    ConfirmDeletePaymentMethodModalComponent,
    ConfirmCancelSubscriptionModalComponent,
    BillingDetailsFormComponent,
  ],
})
export class SettingsBillingPageModule { }
