import { ROUTE_URL } from '@app/router-url.constants';

export enum Tab {
  New = 'NEW',
  MyTasks = 'MY-TASKS'
}

export const TAB_INFO = [
  {
    tab: Tab.New,
    route: ROUTE_URL.inbox,
  },
  {
    tab: Tab.MyTasks,
    route: ROUTE_URL.dialog,
  },
];
