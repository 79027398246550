import { Injectable } from '@angular/core';
import {
  BehaviorSubject, combineLatestWith, map, Observable, tap,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  ChatPreviewFilter, ChatPreviewFragment, InnerOperatorRequest, PageInfo, Tab,
} from '@app/graphql/graphql';
import { SentryService } from '@app/sentry.service';
import { getEnabledValue } from '@app/util/reactive-form.util';
import { OpenCloseOnFragmentChange } from '@app/common/extends/open-close-on-fragment-change.service';
import {
  ChatPreviewFilterInputParameters,
} from './model/chat-preview-filter-input-parameters';
import {
  CHAT_FILTER_CONTROL_NAME,
  FILTER_PER_TAB, FRAGMENT_STATE,
  UNAVAILABLE_PARAMETERS_WHILE_SEARCHING,
} from './filter-section.config';
import { FiltrationSectionData } from './model/filtration-section-data';
import { ChatPreviewFilterMapper } from './chat-preview-filter-mapper.service';
import { FiltrationParameterStorage } from './parameter-storage/filtration-parameter.storage';

const INITIAL_STATE = {
  filterCounter: 0,
  isOpen: false,
};

@Injectable({
  providedIn: 'root',
})
export class FilterSectionService extends OpenCloseOnFragmentChange {
  private readonly tabFromControlMap
    = new Map<Tab, ChatPreviewFilterInputParameters>(FILTER_PER_TAB);

  private readonly filterCounter
    = new BehaviorSubject(INITIAL_STATE.filterCounter);

  private formGroupDefaultValue!: ChatPreviewFilterInputParameters;

  private formGroup!: FormGroup;

  private currentTab!: Tab;

  readonly filterCounter$ = this.filterCounter.asObservable();

  data$!: Observable<FiltrationSectionData>;

  constructor(
    private sentryService: SentryService,
    private filterSectionMapperService: ChatPreviewFilterMapper,
    private filtrationSectionStorage: FiltrationParameterStorage,
    router: Router,
  ) {
    super(router, FRAGMENT_STATE);
    super.setOpenHandler(this.handleOpenEvent.bind(this));
  }

  init(tab: Tab): void {
    super.setInitialState(INITIAL_STATE.isOpen);
    this.filterCounter.next(INITIAL_STATE.filterCounter);

    this.formGroupDefaultValue = this.tabFromControlMap.get(tab);

    this.initializeFormGroup(tab);
    this.updateFilterCounter();

    this.currentTab = tab;
  }

  setDefaultFilterParameters(parameterName: string, value: any): void {
    this.formGroupDefaultValue = {
      ...this.formGroupDefaultValue,
      [parameterName]: value,
    };
  }

  setPageInfoObservable(
    data$: Observable<PageInfo>,
  ): void {
    this.data$ = data$.pipe(map(toFiltrationSectionData));
  }

  setPageInfoObservableWithInnerRequests(
    data$: Observable<PageInfo>,
    innerOperatorRequests$: Observable<InnerOperatorRequest[]>,
    isInviteOperatorToChatEnabled$: Observable<boolean>,
  ): void {
    this.data$ = data$.pipe(
      map(toFiltrationSectionData),
      combineLatestWith(
        innerOperatorRequests$,
        isInviteOperatorToChatEnabled$,
      ),
      map(([
        data,
        innerOperatorRequests,
        isInviteOperatorToChatEnabled,
      ]) => (isInviteOperatorToChatEnabled
        ? ({
          ...data,
          totalAmount: data.totalAmount + innerOperatorRequests.length,
        })
        : data)),
    );
  }

  getFormGroup(): FormGroup {
    return this.formGroup;
  }

  isFilterParameterVisible(filterParameterControlName: string): boolean {
    return this.formGroupDefaultValue[filterParameterControlName] !== undefined;
  }

  private initializeFormGroup(tab: Tab): void {
    this.formGroup = new FormGroup(Object.entries(
      this.filtrationSectionStorage
        .getAllOrDefault(this.formGroupDefaultValue, tab),
    ).reduce(
      (controls, [key, value]) => ({
        ...controls,
        [key]: new FormControl(value),
      }),
      {},
    ));
  }

  private handleOpenEvent(): void {
    if (this.isNotInitialized()) {
      this.sentryService.error(
        'FiltrationSection: can not be opened before "init" and "setPageInfoObservable" methods called.',
      );

      return;
    }

    super.open();
  }

  resetInputFields(): void {
    this.formGroup.reset(this.formGroupDefaultValue);
  }

  getCurrentFilterParameters(): ChatPreviewFilter {
    return this.filterSectionMapperService.toChatPreviewFilter(
      getEnabledValue(this.formGroup),
    );
  }

  getInitialFilterParameters(tab: Tab): ChatPreviewFilter {
    return this.filterSectionMapperService.toChatPreviewFilter(
      this.filtrationSectionStorage.getAllOrDefault(
        this.formGroupDefaultValue,
        tab,
      ),
    );
  }

  onFilterParametersChange(): Observable<ChatPreviewFilter> {
    return this.formGroup.valueChanges
      .pipe(
        tap(() => this.updateFilterCounter()),
        tap(() => this.saveFilterParametersToStorage()),
        map((values) => this.filterSectionMapperService
          .toChatPreviewFilter(values)),
      );
  }

  updateFilterCounter(): void {
    this.filterCounter.next(
      this.getNumberOfDirtyValues(this.formGroup),
    );
  }

  isInitialState(): boolean {
    return this.getNumberOfDirtyValues(this.formGroup) === 0;
  }

  private saveFilterParametersToStorage(): void {
    this.filtrationSectionStorage.save(
      this.formGroup.value,
      this.currentTab,
    );
  }

  private getNumberOfDirtyValues(
    formGroup: FormGroup,
  ): number {
    return Object.keys(formGroup.controls)
      .filter(getIsNotInitialFunction(
        formGroup,
        this.formGroupDefaultValue,
      )).length;
  }

  disableInputFieldsBeforeSearching(): void {
    if (this.isNotInitialized()) {
      return;
    }

    UNAVAILABLE_PARAMETERS_WHILE_SEARCHING.forEach(
      (controlName) => this.formGroup.controls[controlName]
        .disable({ emitEvent: false }),
    );
    if (UNAVAILABLE_PARAMETERS_WHILE_SEARCHING.length) {
      this.updateFilterCounter();
    }
  }

  enableInputFieldsAfterSearching(): void {
    if (this.isNotInitialized()) {
      return;
    }

    UNAVAILABLE_PARAMETERS_WHILE_SEARCHING.forEach(
      (controlName) => this.formGroup.controls[controlName]
        .enable({ emitEvent: false }),
    );
    if (UNAVAILABLE_PARAMETERS_WHILE_SEARCHING.length) {
      this.updateFilterCounter();
    }
  }

  isMatchesFilter(chatPreview: ChatPreviewFragment, tab: Tab): boolean {
    const filterParameters: ChatPreviewFilter
      = this.getInitialFilterParameters(tab);

    if (filterParameters.chatStatusNames
      && !filterParameters.chatStatusNames.includes(chatPreview.status)) {
      return false;
    }

    if (filterParameters.responsibleOperatorIds
      && !filterParameters.responsibleOperatorIds
        .includes(chatPreview.chatOperator.operator.id)) {
      return false;
    }

    if (filterParameters.categoryIds
      && !chatPreview.categories
        .some((category) => filterParameters.categoryIds
          .includes(category.id))) {
      return false;
    }

    if (filterParameters.channelIds
      && !filterParameters.channelIds.includes(chatPreview.bot.id)) {
      return false;
    }

    return true;
  }

  isNotInitialized(): boolean {
    return !this.data$
      || !this.formGroup
      || !this.formGroupDefaultValue
      || !this.currentTab;
  }
}

function getIsNotInitialFunction(
  formGroup: FormGroup,
  formGroupInitial,
): (controlName) => boolean {
  return (controlName) => {
    const control = formGroup.controls[controlName];

    return control.enabled
      && (
        controlName === CHAT_FILTER_CONTROL_NAME.createDate
          ? control.value.length > 0
          : control.value !== formGroupInitial[controlName]
      );
  };
}

function toFiltrationSectionData(pageInfo: PageInfo): FiltrationSectionData {
  return { totalAmount: pageInfo.totalItemCount };
}
