/* eslint-disable no-extend-native,func-names */

Array.prototype.getFirst = function () {
  return this[0];
};

Array.prototype.getLast = function () {
  return this[this.length - 1];
};

Array.prototype.nonEmpty = function (): boolean {
  return this.length > 0;
};

Array.prototype.isEmpty = function (): boolean {
  return this.length === 0;
};
