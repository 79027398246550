// eslint-disable-next-line import/no-extraneous-dependencies
import * as moment from 'moment-timezone';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';

export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_SECOND = 1000;

export const START_OF_THE_DAY: Date = new Date(0, 0, 0, 0, 0, 0, 0);

const MILLISECONDS_IN_DAY = 86400000;

export function timestampToDays(timestamp: number): number {
  return Math.floor(timestamp / MILLISECONDS_IN_DAY);
}

export function toLocalTimeStringFromDate(date: Date): string {
  return moment(date).format('HH:mm');
}

export function toLocalDateStringFromDate(date: Date, delimiter = '-'): string {
  return moment(date).format(['YYYY', 'MM', 'DD'].join(delimiter));
}

export function toDDMMYYFromDate(date: Date, delimiter = '.'): string {
  return moment(date).format(['DD', 'MM', 'YY'].join(delimiter));
}

export function differenceInCalendarDays(
  dateLeft: Date,
  dateRight: Date,
): number {
  const leftTimestamp: number = toStartOfTheDate(dateLeft).getTime();
  const rightTimestamp: number = toStartOfTheDate(dateRight).getTime();

  return Math.abs(
    Math.round((leftTimestamp - rightTimestamp) / MILLISECONDS_IN_DAY),
  );
}

export function toDateFromLocalTimeString(time: string): Date {
  const date = new Date();
  const [hours, minutes] = time.split(':');

  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  return date;
}

export function getTimezonesOptions(): NzSelectOptionInterface[] {
  return moment.tz.names()
    .filter((timezone: string) => !timezone.includes('Europe/Kyiv')) // Date time API doesn't contain such zone
    .map((timezone: string) => {
      const offset: number = moment.tz(timezone).utcOffset();

      return {
        label: `${timezone}${toOffsetPostfix(offset)}`,
        value: timezone,
      };
    });
}

export function toStartOfTheDate(date: Date): Date {
  date.setHours(0, 0, 0, 0);

  return date;
}

export function toEndOfTheDate(date: Date): Date {
  date.setHours(23, 59, 59, 999);

  return date;
}

function toOffsetPostfix(offset: number): string {
  const hours: number = Math.floor(offset / 60);
  const minutes: number = Math.abs(offset % 60);
  const sign: string = offset < 0
    ? '-'
    : '+';

  return offset !== 0
    ? ` (GMT${sign}${Math.abs(hours).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')})`
    : '';
}

export function secondsToTime(seconds: number): string {
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);
  const remainingSeconds: number = Math.floor(seconds % 60);

  return [hours || null, minutes, remainingSeconds]
    .filter((value: number | null) => value !== null)
    .map((value: number) => value.toString().padStart(2, '0'))
    .join(':');
}

const MILLISECONDS_DIGITS = 3;

export function millisecondsToTime(
  milliseconds: number,
  numberOfVisibleDigits = 2,
): string {
  const visiblePartDivider
    = 10 ** (MILLISECONDS_DIGITS - numberOfVisibleDigits);
  const millisecondsPart = Math.floor(
    (milliseconds % MILLISECONDS_IN_SECOND) / visiblePartDivider,
  );

  return `${
    secondsToTime(Math.floor(milliseconds / MILLISECONDS_IN_SECOND))
  },${
    millisecondsPart.toString().padStart(numberOfVisibleDigits, '0')
  }`;
}

const MONTH_INDEX_SHIFT = 1;
const DATE_PART_SYMBOL_LIMIT = 2;
const DATE_PART_FILLER = '0';

export function toTimeDayDate(timestamp: Date, locale: string): string {
  const now = new Date();

  if (timestamp.toDateString() === now.toDateString()) {
    return timestamp.toLocaleTimeString(
      locale,
      { hour: '2-digit', minute: '2-digit', hour12: false },
    );
  }

  const oneDayMillis = 24 * 60 * 60 * 1000;
  const dayOfWeek = now.getDay();
  const daysSinceMonday = dayOfWeek === 0
    ? 6
    : dayOfWeek - 1;
  const startOfThisWeek
    = new Date(now.getTime() - daysSinceMonday * oneDayMillis);

  startOfThisWeek.setHours(0, 0, 0, 0);

  if (timestamp >= startOfThisWeek) {
    return timestamp.toLocaleDateString(locale, { weekday: 'short' });
  }

  return `${
    timestamp.getDate().toString()
      .padStart(DATE_PART_SYMBOL_LIMIT, DATE_PART_FILLER)
  }.${
    (timestamp.getMonth() + MONTH_INDEX_SHIFT).toString()
      .padStart(DATE_PART_SYMBOL_LIMIT, DATE_PART_FILLER)
  }.${
    timestamp.getFullYear().toString().slice(-DATE_PART_SYMBOL_LIMIT)
  }`;
}

export function getDateNDaysBefore(date: Date, daysBefore: number): Date {
  const copy = new Date(date);

  copy.setDate(copy.getDate() - daysBefore);

  return copy;
}

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export function getDifferenceInDays(date1: Date, date2: Date): number {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());

  return Math.ceil(diffInMs / ONE_DAY_MS);
}
