import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { filter, map } from 'rxjs/operators';
import { FullNamePipe } from '@app/pipe/full-name.pipe';
import { Message, Tab } from '../graphql/graphql';
import { Breakpoint } from '../responsive/model/breakpoint';
import { ResponsiveService } from '../responsive/service/responsive.service';
import { PageRouteService } from '../page-route.service';

export interface NotificationDto {
  title: string;
  content: string;
  chatId: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationCreationService {
  constructor(
    private pageRouteService: PageRouteService,
    private responsiveService: ResponsiveService,
    private nzNotificationService: NzNotificationService,
  ) {
  }

  showMessage(message: Message, tab: Tab): void {
    this.show({
      title: message.senderUser?.username
        || FullNamePipe.getFullNameByUser(message.senderOperator),
      content: message.text,
      chatId: message.chat.id,
    }, tab);
  }

  show(dto: NotificationDto, tab: Tab): void {
    this.nzNotificationService
      .blank(
        dto.title,
        dto.content,
        {
          nzPauseOnHover: this.responsiveService
            .isMatched(Breakpoint.DESKTOP),
        },
      ).onClick.pipe(
        map((event: Event) => event.target as Element),
        filter((element: Element): boolean => element.tagName === 'DIV'),
      ).subscribe(() => this.pageRouteService.moveToChat(dto.chatId, tab));
  }
}
