import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Message,
  ReadMessagesOfChatWithIdGQL, UnreadChatsCounter, UnreadChatsCountersGQL,
} from '@app/graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class MessageCounterGqlService {
  constructor(
    private readMessagesOfChatWithIdGQL: ReadMessagesOfChatWithIdGQL,
    private unreadChatsCountersGQL: UnreadChatsCountersGQL,
  ) {}

  readMessagesOfChatId(
    chatId: string,
  ): Observable<Message[]> {
    return this.readMessagesOfChatWithIdGQL.mutate(
      {
        chatId,
      },
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(map((value) => value.data.readMessagesOfChatWithId as Message[]));
  }

  getUnreadChatsCounter(): Observable<UnreadChatsCounter[]> {
    return this.unreadChatsCountersGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((value) => value.data
        .unreadChatsCounters as UnreadChatsCounter[]),
    );
  }
}
