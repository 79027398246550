import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationSettingsGqlService } from '@app/gql-service/notification-settings-gql.service';
import { TokenStorageService } from '@app/auth/token-storage.service';
import { NotificationSettings } from '@app/graphql/graphql';

const START_OF_AUDIO = 0;
const NOT_ALLOWED_ERROR_NAME = 'NotAllowedError';

@Injectable({
  providedIn: 'root',
})
export class SoundNotificationService {
  private readonly fetchedSettings: BehaviorSubject<NotificationSettings>
    = new BehaviorSubject(null);

  private readonly fetchedSettings$ = this.fetchedSettings.asObservable();

  private readonly newChatSound = new Audio();

  private readonly newMessageSound = new Audio();

  private newChatSoundEnabled: boolean;

  private newMessageSoundEnabled: boolean;

  constructor(
    private notificationSettingsGqlService: NotificationSettingsGqlService,
    @Inject(TokenStorageService)
    private tokenStorageService: TokenStorageService,
  ) {
    if (this.tokenStorageService.hasRefreshToken()) {
      this.fetchSettingsIfNeeded();
    }

    this.newChatSound.src = '../../assets/audio/new-chat.wav';
    this.newChatSound.load();

    this.newMessageSound.src = '../../assets/audio/new-message.wav';
    this.newMessageSound.load();
  }

  playNewChatSoundIfEnabled(): void {
    if (this.newChatSoundEnabled) {
      this.newChatSound.currentTime = START_OF_AUDIO;
      this.newChatSound.play().catch((error: Error) => {
        if (error.name !== NOT_ALLOWED_ERROR_NAME) {
          throw error;
        }
      });
    }
  }

  playNewMessageSoundIfEnabled(): void {
    if (this.newMessageSoundEnabled) {
      this.newMessageSound.currentTime = START_OF_AUDIO;
      this.newMessageSound.play().catch((error: Error) => {
        if (error.name !== NOT_ALLOWED_ERROR_NAME) {
          throw error;
        }
      });
    }
  }

  isNewChatSoundEnabled(): boolean {
    return this.newChatSoundEnabled;
  }

  isNewMessageSoundEnabled(): boolean {
    return this.newMessageSoundEnabled;
  }

  setNewChatSoundEnabled(isEnabled: boolean): void {
    this.newChatSoundEnabled = isEnabled;
  }

  setNewMessageSoundEnabled(isEnabled: boolean): void {
    this.newMessageSoundEnabled = isEnabled;
  }

  getFetchedSettings(): NotificationSettings {
    return this.fetchedSettings.value;
  }

  setFetchSettings(notificationSettings: NotificationSettings) {
    this.fetchedSettings.next(notificationSettings);
  }

  fetchSettingsIfNeeded(): Observable<NotificationSettings> {
    if (this.fetchedSettings.value) {
      return this.fetchedSettings$;
    }

    const settings$
      = this.notificationSettingsGqlService.getNotificationSettings();

    settings$
      .subscribe((notificationSettings) => {
        this.newChatSoundEnabled = notificationSettings.isNewChatSoundEnabled;
        this.newMessageSoundEnabled
          = notificationSettings.isNewMessageSoundEnabled;
        this.fetchedSettings.next(notificationSettings);
      });

    return settings$;
  }
}
