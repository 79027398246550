import {
  Inject, Injectable, Renderer2, RendererFactory2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject } from 'rxjs';
import { setAttributeToElement } from '@app/util/html.util';
import { environment } from '@src/environments/environment';
import { getWidgetChannelByEnv } from '@app/cusbo-widget/cusbo-widget-channels';

const WIDGET_INTEGRATION_SCRIPT_ID = 'cusbo-widget.integration-api';

@Injectable({
  providedIn: 'root',
})
export class CusboWidgetService {
  private readonly renderer: Renderer2
    = this.rendererFactory.createRenderer(null, null);

  private readonly onWidgetIntegrationScriptLoaded
    = new ReplaySubject<boolean>();

  readonly isWidgetIntegrationScriptLoaded$
    = this.onWidgetIntegrationScriptLoaded.asObservable();

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
  }

  public load(): void {
    const script = this.addWidgetScriptToHtmlHead();

    this.distributeOnElementLoaded(script, WIDGET_INTEGRATION_SCRIPT_ID)
      .subscribe(() => this.onWidgetIntegrationScriptLoaded.next(true));
  }

  private addWidgetScriptToHtmlHead(): HTMLScriptElement {
    const { src, slug } = getWidgetChannelByEnv(environment.env);
    const script = this.renderer.createElement('script');
    const attributes = [
      { name: 'id', value: 'cusbo-widget' },
      { name: 'src', value: src },
      { name: 'slug', value: slug },
      { name: 'auth', value: 'website' },
      { name: 'visible', value: 'false' },
      { name: 'async', value: 'true' },
    ];

    attributes.forEach(setAttributeToElement(script));
    this.renderer.appendChild(this.document.body, script);

    return script;
  }

  private distributeOnElementLoaded(
    script: HTMLScriptElement,
    elementId: string,
  ): Observable<void> {
    return new Observable<void>((observer) => {
      script.onload = () => {
        this.document.getElementById(elementId)
          .onload = () => {
            observer.next();
            observer.complete();
          };
      };
    });
  }
}
