import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenStorageService } from '@app/auth/token-storage.service';
import { LoginResponse } from '@app/auth/login-response';
import { ROUTE_URL } from '@app/router-url.constants';
import { environment } from '@src/environments/environment';
import { RoleName } from '@app/graphql/graphql';
import { LOCAL_STORAGE_KEY } from '@src/constants/local_storage.constants';
import { LocalStorageService } from '@app/local-storage-service';
import { PageRouteService } from '@app/page-route.service';
import { BaseResponse } from '../model/base-response';
import { AuthLoginInfo } from './login-request';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = environment.apiBaseUrl;

  private loginUrl = `${this.baseUrl}login`;

  private logoutUrl = `${this.baseUrl}disconnect`;

  private refreshUrl = `${this.baseUrl}refresh`;

  constructor(
    private http: HttpClient,
    private tokenService: TokenStorageService,
    private localStorageService: LocalStorageService,
    private pageRouteService: PageRouteService,
  ) {
  }

  // TODO: remove this method and use GraphQl version
  attemptAuth(
    credentials: AuthLoginInfo,
  ): Observable<BaseResponse<LoginResponse>> {
    return this.http.post<BaseResponse<LoginResponse>>(
      this.loginUrl,
      credentials,
      httpOptions,
    );
  }

  // TODO: remove this method and use GraphQl version
  refreshToken(): Observable<BaseResponse<LoginResponse>> {
    const token = this.tokenService.getRefreshToken();

    return this.http.post<BaseResponse<LoginResponse>>(
      this.refreshUrl,
      token,
      httpOptions,
    )
      .pipe(tap((data: BaseResponse<LoginResponse>) => {
        this.tokenService.saveTokenPair(
          data.value.accessToken, data.value.refreshToken,
        );
      }));
  }

  logout(): void {
    this.http.post<void>(this.logoutUrl, {}, httpOptions).subscribe(() => {
      this.onLogout();
    });
  }

  onLogout(): void {
    this.tokenService.signOut();
    this.localStorageService.clear([
      LOCAL_STORAGE_KEY.operator,
      LOCAL_STORAGE_KEY.roles,
      LOCAL_STORAGE_KEY.companyId,
    ]);

    this.pageRouteService.updateLocation(ROUTE_URL.login);
  }

  login(
    email: string,
    password: string,
    isSkipped: boolean,
  ): Observable<any> {
    const loginInfo = new AuthLoginInfo(email, password);

    return this.attemptAuth(loginInfo).pipe(
      tap((data) => {
        this.tokenService.saveTokenPair(
          data.value.accessToken, data.value.refreshToken,
        );
        this.localStorageService.setValues({
          [LOCAL_STORAGE_KEY.operator]: String(data.value.operatorId),
          [LOCAL_STORAGE_KEY.roles]: data.value.roles.join(),
          [LOCAL_STORAGE_KEY.companyId]: String(data.value.companyId),
        });
        if (data.value.redirectUrl) {
          this.pageRouteService.updateLocation(data.value.redirectUrl);
        } else {
          this.pageRouteService.updateLocation(!data.value.hasBots && !isSkipped
          && data.value.roles.includes(RoleName.CompanyAdmin)
            ? ROUTE_URL.signUpChannels
            : ROUTE_URL.dialog);
        }
      }),
    );
  }
}
