<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 120 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_6790_103664)">
    <path d="M0 18C0 9.71573 6.86154 3 15.3257 3C23.3771 3 29.9784 9.0768 30.603 16.8H22.5369C21.9856 14.5624 19.9268 12.9 17.4713 12.9H13.1801C10.3023 12.9 7.96935 15.1833 7.96935 18C7.96935 20.8167 10.3023 23.1 13.1801 23.1H16.5751L19.0193 25.4042C19.2601 25.6313 19.6169 25.4292 19.6169 25.0657V22.649C21.0631 22.0085 22.1573 20.7406 22.5369 19.2H30.603C29.9784 26.9232 23.3771 33 15.3257 33C6.86154 33 0 26.2843 0 18Z" fill="#1890FF"/>
  </g>
  <g clip-path="url(#clip1_6790_103664)">
    <path d="M38.0894 17.9789C38.0894 22.6378 41.6049 25.9264 46.0849 25.9264C50.316 25.9264 52.836 23.4599 53.6449 20.1713L50.316 19.4101C49.9738 21.5111 48.6671 23.0032 46.116 23.0032C43.5027 23.0032 41.5116 21.0848 41.5116 17.9789C41.5116 14.9035 43.5027 12.9851 46.116 12.9851C48.6049 12.9851 49.9738 14.5076 50.2227 16.5173L53.5205 15.8474C52.8982 12.5284 50.2849 10.0619 46.0538 10.0619C41.6049 10.0619 38.0894 13.3201 38.0894 17.9789Z" fill="#1890FF"/>
    <path d="M69.7797 25.5001V10.4882H66.3886V18.1921C66.3886 21.2675 64.7397 23.0032 62.1886 23.0032C59.9174 23.0032 58.5797 21.8156 58.5797 19.3187V10.4882H55.1574V19.5014C55.1574 23.3077 57.6463 25.7437 61.1619 25.7437C64.0863 25.7437 65.4552 24.4952 66.1397 23.1554H66.4508V25.5001H69.7797Z" fill="#1890FF"/>
    <path d="M72.1052 14.599C72.1052 17.4613 74.5319 18.5879 77.643 19.136L78.7008 19.3187C80.723 19.6841 81.7497 20.1713 81.7497 21.3284C81.7497 22.4855 80.6608 23.3381 78.7008 23.3381C76.7097 23.3381 75.0919 22.5464 74.6563 20.08L71.483 20.8412C72.0119 24.1907 74.7497 25.9264 78.7008 25.9264C82.5586 25.9264 85.0474 24.1603 85.0474 21.0848C85.0474 18.0094 82.5274 17.1263 79.1674 16.5173L78.1097 16.3346C76.4297 16.0301 75.403 15.5429 75.403 14.3858C75.403 13.2896 76.3986 12.6197 78.0786 12.6197C79.7586 12.6197 80.9719 13.3201 81.3452 15.1471L84.4563 14.2336C83.7719 11.7062 81.5319 10.0619 78.0786 10.0619C74.4697 10.0619 72.1052 11.7062 72.1052 14.599Z" fill="#1890FF"/>
    <path d="M86.7267 4.18506V25.5001H90.0556V23.2772H90.3667C91.3001 24.6779 92.8556 25.9264 95.6867 25.9264C99.4512 25.9264 102.718 22.8814 102.718 17.9789C102.718 13.1069 99.4512 10.0619 95.6867 10.0619C92.8556 10.0619 91.3001 11.3104 90.4289 12.5893H90.1178V4.18506H86.7267ZM94.6912 12.9242C97.3667 12.9242 99.2956 14.8121 99.2956 17.9789C99.2956 21.1762 97.3667 23.0336 94.6912 23.0336C92.0467 23.0336 90.0867 21.1457 90.0867 17.9789C90.0867 14.8121 92.0467 12.9242 94.6912 12.9242Z" fill="#1890FF"/>
    <path d="M103.415 17.9789C103.415 22.8509 106.807 25.9264 111.411 25.9264C116.015 25.9264 119.375 22.8509 119.375 17.9789C119.375 13.1374 116.015 10.0619 111.411 10.0619C106.807 10.0619 103.415 13.1374 103.415 17.9789ZM106.838 17.9789C106.838 14.9035 108.704 12.9851 111.411 12.9851C114.087 12.9851 115.953 14.873 115.953 17.9789C115.953 21.0848 114.087 23.0032 111.411 23.0032C108.704 23.0032 106.838 21.1153 106.838 17.9789Z" fill="#1890FF"/>
  </g>
  <defs>
    <clipPath id="clip0_6790_103664">
      <rect width="30.6513" height="30" fill="white" transform="translate(0 3)"/>
    </clipPath>
    <clipPath id="clip1_6790_103664">
      <rect width="82.6054" height="36" fill="white" transform="translate(37.3945)"/>
    </clipPath>
  </defs>
</svg>
