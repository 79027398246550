import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class SubscriberComponent implements OnDestroy {
  private readonly isComponentDestroyed: Subject<boolean>
    = new Subject();

  protected readonly isComponentDestroyed$: Observable<boolean>
    = this.isComponentDestroyed.asObservable();

  takeTillDestroyed<T>(): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>): Observable<T> => source
      .pipe(
        takeUntil(this.isComponentDestroyed$),
      );
  }

  ngOnDestroy(): void {
    this.isComponentDestroyed.next(true);
    this.isComponentDestroyed.complete();
  }
}
