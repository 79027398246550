<div class="tab-content">
<app-info-message
  [infoMessageTab]="CURRENT_TAB"
></app-info-message>

<h1 class="title">
  {{ 'settings.channel_details.widget_channels.title' | i18next }}
</h1>
<p class="instruction-text">
  {{ 'settings.channel_details.widget_channels.description' | i18next }}
</p>
<app-widget-channels-image></app-widget-channels-image>

<form nz-form class="form-block" *ngIf="formGroup" [formGroup]="formGroup">
  <div *ngFor="let field of fields$ | async">
    <ng-container [ngSwitch]="field.type">

      <div class="field-header" *ngSwitchCase="'COMING_SOON'">
        <span class="field-title">{{field.title}}</span>
        <nz-tag nzColor="blue">
          {{ 'settings.channel_details.widget_channels.coming_soon' | i18next }}
        </nz-tag>
      </div>

      <div class="field-header" *ngSwitchCase="'SWITCH_ONLY'">
        <span class="field-title">{{field.title}}</span>
        <nz-switch [formControlName]="field.name">
        </nz-switch>
      </div>

      <div class="field-block" *ngSwitchCase="'NO_OPTION'">
        <div class="field-header">
          <span class="field-title">{{field.title}}</span>
        </div>
        <div>
          {{ 'settings.channel_details.widget_channels.channel_not_connected' | i18next: { name: field.title} }}
          <div>
            <a class="field-block__add-channel-button" nz-button nzType="link" (click)="addChannel(field.name)">
              <span nz-icon nzType="plus"></span>
              {{ 'settings.channel_details.widget_channels.add_channel' | i18next }}
            </a>
          </div>
        </div>
      </div>

      <div class="field-block" *ngSwitchDefault>
        <div class="field-header" data-testid="widget-enable-channel-header">
          <span class="field-title">{{field.title}}</span>
          <nz-switch #fieldSwitch [formControlName]="field.switch.name"></nz-switch>
        </div>
        <nz-select class="select-input"
                   [formControlName]="field.name"
                   [nzPlaceHolder]="field.placeHolder"
                   [nzDisabled]="!fieldSwitch.isChecked">
          <nz-option *ngFor="let option of field.channelOptions"
                     nzCustomContent
                     [nzLabel]="option.name"
                     [nzValue]="option.id">
            <div class="option-row">
              <span>{{option.name}}</span>
            </div>
          </nz-option>
        </nz-select>
      </div>

    </ng-container>
  </div>
</form>
</div>
