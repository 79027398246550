<ng-container>
  <ng-container *ngIf="paymentTransaction$ | async as paymentTransaction">
    <app-loading></app-loading>
    <nz-layout class="payment-page" *ngIf="this.merchantData$ | async as merchantInfo">
      <nz-header class="header">
        <app-logotype height="40" width="130"></app-logotype>
        <div class="header__title">
          {{ paymentTransaction.description }}
        </div>
        <div class="header__close-button">
          <app-circle-button (click)="closePayment()">
            <span nz-icon nzType="close" nzTheme="outline"></span>
          </app-circle-button>
        </div>
      </nz-header>
      <nz-content>
        <div nz-row nzJustify="center">
          <div nz-col [nzXs]="22" [nzLg]="8" [nzXXl]="8">
            <nz-steps *ngIf="!isPaymentMethodTransaction(paymentTransaction)" [nzCurrent]="currentStepIndex" (nzIndexChange)="onIndexChange($event)">
              <nz-step [nzTitle]="'settings.billing.subscription_payment.first_step' | i18next"></nz-step>
              <nz-step
                [nzTitle]="'settings.billing.subscription_payment.second_step' | i18next"
                [nzDisabled]="!hasAllBillingDetails"
              >
              </nz-step>
            </nz-steps>
            <ng-container *ngIf="!isPaymentMethodTransaction(paymentTransaction); else cardManagingBlock">
              <ng-container *ngIf="currentStepIndex === 0">
                <div class="form-box">
                  <div class="billing-details-action">
                    <app-billing-details-form
                      [isBillingDetailsEditorPage]="false"
                      (formGroupEmitter)="setFormGroup($event)"
                      (billingDetailsEmitter)="setBillingDetails($event)"
                    >
                    </app-billing-details-form>
                  </div>
                  <div class="billing-payment-details">
                    <app-payment-details
                      [paymentTransaction]="paymentTransaction"
                      [recalculationInfo]="recalculationInfo"
                    ></app-payment-details>
                    <button
                      class="add-payment-method-button"
                      nz-button
                      nzType="primary"
                      (click)="moveToPaymentMethod()"
                    >
                      {{ 'billing_payment_details.save_and_continue' | i18next }}
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="currentStepIndex === 1">
                <ng-container *ngIf="false; else cardManagingBlock"></ng-container>
              </ng-container>
            </ng-container>
            <ng-template #cardManagingBlock>
              <div class="form-box">
                <div class="payment-details">
                  <app-payment-details
                    [paymentTransaction]="paymentTransaction"
                    [recalculationInfo]="recalculationInfo"
                  ></app-payment-details>
                </div>
                <div class="payment-action">
                  <ng-container *ngIf="showCardManagingBlock$ | async">
                    <div (click)="useExistingCard()"
                         *ngIf="companyPaymentCard$ | async as userCard"
                         [class.active-card]="isExistingCardActive"
                         class="card-option">
                      <app-payment-method
                        [paymentMethodInfo]="paymentMethodService.getUserCardPaymentInfo(userCard)"></app-payment-method>
                      <input [checked]="isExistingCardActive" type="radio">
                    </div>
                    <div (click)="useNewCard()" class="card-option" [class.active-card]="isNewCardActive">
                      <img class="user-card-icon"
                           [src]="'../../assets/images/icons/' + defaultCardProviderPath"
                           [alt]="'default'">
                      <div class="user-card-info-box">
                        <div
                          class="user-card-info-box__title">{{ 'settings.payment.another_payment_method_title' | i18next }}</div>
                        <div
                          class="user-card-info-box__details">{{ 'settings.payment.another_payment_method_details' | i18next }}</div>
                      </div>
                      <input [checked]="isNewCardActive" type="radio">
                    </div>
                    <div *ngIf="isExistingCardActive">
                      <button class="add-payment-method-button"
                              nz-button
                              nzType="primary"
                              (click)="payInOneClick(paymentTransaction.id)">
                        Pay
                      </button>
                    </div>
                  </ng-container>
                  <div id="solid-payment-form-container" *ngIf="!isExistingCardActive">
                    <ngx-solid-payment *ngIf="isFormShown"
                                       [merchantData]="merchantInfo"
                                       [formParams]="formParams"
                                       [styles]="formStyles"
                                       (success)="success()"
                                       (fail)="fail()"
                                       width="100%"
                    ></ngx-solid-payment>
                  </div>
                </div>
              </div>
              <div class="disclaimer" *ngIf="disclaimer$ | async as disclaimer">
                <p>
                  {{ disclaimer }}
                </p>
              </div>
            </ng-template>
          </div>
        </div>
      </nz-content>
    </nz-layout>
  </ng-container>
</ng-container>
