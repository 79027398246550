import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { I18NextModule } from 'angular-i18next';
import { TimePipe } from '@app/pipe/time.pipe';
import { CircleButtonComponent } from '@app/custom-elements/botton/circle-button/circle-button.component';
import { DateTimePipe } from '@app/pipe/date-time.pipe';
import { FullNamePipe } from '@app/pipe/full-name.pipe';
import { CapitalLetterPipe } from '@app/pipe/capital-letter.pipe';
import { CapitalWord } from '@app/pipe/capital-word.pipe';
import { MessageSenderNamePipe } from '@app/pipe/message-sender-name.pipe';
import { BadgeComponent } from '@app/badge/badge.component';
import { ChannelViewComponent } from '@app/common/channel-view/channel-view.component';
import { MessengerIconModule } from '@app/messenger-icon/messenger-icon.module';
import { LongNameModule } from '@app/common/long-name/long-name.module';
import { CopyButtonComponent } from '@app/common/copy-button/copy-button.component';
import { FilterPipe } from '@app/pipe/filter.pipe';
import { PasswordInputComponent } from '@app/common/password-input/password-input.component';
import { CurrencySymbolPipe } from '@app/pipe/currency-symbol.pipe';
import { PercentFromPipe } from '@app/pipe/percent-from.pipe';
import { ReadonlyInputsDirective } from '@app/common/util/input/readonly-inputs.directive';
import { ToDatePipe } from '@app/pipe/to-date.pipe';
import { LongTextModule } from '@app/common/long-text/long-text.module';
import { ParametrizedTranslationPipe } from '@app/util/translations/parametrized-translation.pipe';
import { AutofocusDirective } from '@app/common/util/input/autofocus.directive';
import { HtmlToPlaintextPipe } from '@app/pipe/html-to-plain-text.pipe';
import { ChannelIconUrlPipe } from '@app/pipe/channel-icon-url.pipe';
import { ButtonComponent } from '@app/custom-elements/botton/button.component';
import { TranslateParametrizedMessagePipe } from '@app/pipe/message-translation/translate-parametrized-message.pipe';
import { FillPipe } from './pipe/fill.pipe';

@NgModule({
  declarations: [
    FillPipe,
    FilterPipe,
    TimePipe,
    ToDatePipe,
    CapitalWord,
    PercentFromPipe,
    CurrencySymbolPipe,
    DateTimePipe,
    FullNamePipe,
    CapitalLetterPipe,
    MessageSenderNamePipe,
    CircleButtonComponent,
    ButtonComponent,
    BadgeComponent,
    ChannelViewComponent,
    CopyButtonComponent,
    PasswordInputComponent,
    ReadonlyInputsDirective,
    ParametrizedTranslationPipe,
    TranslateParametrizedMessagePipe,
    AutofocusDirective,
    HtmlToPlaintextPipe,
    ChannelIconUrlPipe,
  ],
  imports: [
    CommonModule,
    MessengerIconModule,
    LongNameModule,
    LongTextModule,
    NzButtonModule,
    NzIconModule,
    FormsModule,
    NzInputModule,
    I18NextModule,
  ],
  exports: [
    FillPipe,
    FilterPipe,
    TimePipe,
    ToDatePipe,
    CapitalWord,
    DateTimePipe,
    FullNamePipe,
    CapitalLetterPipe,
    CircleButtonComponent,
    MessageSenderNamePipe,
    BadgeComponent,
    ChannelViewComponent,
    CopyButtonComponent,
    PasswordInputComponent,
    CurrencySymbolPipe,
    PercentFromPipe,
    ReadonlyInputsDirective,
    ParametrizedTranslationPipe,
    TranslateParametrizedMessagePipe,
    AutofocusDirective,
    HtmlToPlaintextPipe,
    ChannelIconUrlPipe,
    ButtonComponent,
  ],
  providers: [
    HtmlToPlaintextPipe,
    DateTimePipe,
  ],
})
export class AppUtilModule {
}
