import { Component, Inject, OnDestroy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingService } from '@app/gql-service/billing.service';
import { ERROR_SLUG } from '@src/constants/error-slug.constants';

@Component({
  selector: 'app-confirm-delete-payment-method-modal',
  templateUrl: './confirm-delete-payment-method-modal.component.html',
  styleUrls: ['./confirm-delete-payment-method-modal.component.scss'],
})
export class ConfirmDeletePaymentMethodModalComponent implements OnDestroy {
  loading = false;

  errorMessage: string;

  hideErrorMessage = true;

  private componentIsDestroyed = new Subject<boolean>();

  constructor(
    private modal: NzModalService,
    private billingService: BillingService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  showDeletePaymentMethodConfirmationModal() {
    this.modal.closeAll();
    this.modal.create({
      nzTitle: this.translationService.t('settings.billing.delete_payment_method_modal.title'),
      nzWidth: '40%',
      nzMaskClosable: true,
      nzContent: ConfirmDeletePaymentMethodModalComponent,
      nzClosable: true,
    });
  }

  onYesClick() {
    this.loading = true;
    this.billingService.deletePaymentMethod()
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe(
        () => {
          this.loading = false;
          this.modal.closeAll();
          window.location.reload();
        }, () => {
          this.onError();
        },
      );
  }

  onNoClick() {
    this.modal.closeAll();
  }

  private onError() {
    this.loading = false;
    this.errorMessage = this.translationService
      .t(ERROR_SLUG.somethingWentWrong);
    this.hideErrorMessage = false;
  }

  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.unsubscribe();
  }
}
