<div class="card">
  <i
    nz-icon
    [nzType]="getIconType()"
    nzTheme="fill"
    class="icon"
    data-testid="info-message-card">
  </i>
  <ng-content class="message-text"></ng-content>
  <a *ngIf="buttonText"
    nz-button
    nzType="default"
    (click)="this.buttonClick.emit()">
    {{buttonText}}
  </a>
</div>

