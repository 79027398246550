import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  map, Observable, tap,
} from 'rxjs';
import { File, MessengerType } from '@app/graphql/graphql';
import { AttachmentViewImageRotation } from '@app/attachment-view/model/attachment-view-image-rotation';
import { AttachmentViewImageScale } from '@app/attachment-view/model/attachment-view-image-scale';
import { AttachmentViewModification } from '@app/attachment-view/model/attachment-view-modification';
import { Position } from '@app/common/model/position';
import { AttachmentSize } from '@app/common/model/attachment-size';
import { S3FileService } from '@app/s3-file.service';
import { CurrentChatGqlService } from '@app/current-chat-gql.service';

const INSTAGRAM_FILE_DOMAIN = 'lookaside.fbsbx.com';
const LINK_TAG_NAME = 'a';
const INITIAL_POSITION = { x: 0, y: 0 };
const ROTATION_OPTIONS: AttachmentViewImageRotation[] = [
  AttachmentViewImageRotation.DEGREES_0,
  AttachmentViewImageRotation.DEGREES_90,
  AttachmentViewImageRotation.DEGREES_180,
  AttachmentViewImageRotation.DEGREES_270,
];
const SCALE_OPTIONS: AttachmentViewImageScale[] = [
  AttachmentViewImageScale.SCALE_25,
  AttachmentViewImageScale.SCALE_50,
  AttachmentViewImageScale.SCALE_75,
  AttachmentViewImageScale.SCALE_100,
  AttachmentViewImageScale.SCALE_150,
  AttachmentViewImageScale.SCALE_250,
  AttachmentViewImageScale.SCALE_350,
  AttachmentViewImageScale.SCALE_450,
];
const INITIAL_IMAGE_MODIFICATION: AttachmentViewModification = {
  scale: AttachmentViewImageScale.SCALE_100,
  rotation: AttachmentViewImageRotation.DEGREES_0,
  position: INITIAL_POSITION,
};

@Injectable({
  providedIn: 'root',
})
export class AttachmentViewUtil {
  private readonly modification
    : BehaviorSubject<AttachmentViewModification>
    = new BehaviorSubject<AttachmentViewModification>(
      INITIAL_IMAGE_MODIFICATION,
    );

  readonly modification$: Observable<AttachmentViewModification>
    = this.modification.asObservable();

  constructor(
    private currentChatGqlService: CurrentChatGqlService,
    private httpClient: HttpClient,
    private s3FileService: S3FileService,
  ) {
  }

  getFileSize(url: string): Observable<AttachmentSize> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.head(url, {
      headers,
      observe: 'response',
      responseType: 'text',
    }).pipe(
      map((response) => parseInt(response.headers.get('content-length'), 10)),
      map((fileSizeInBytes) => {
        const fileSizeInKilobytes = fileSizeInBytes / 1024;
        const fileSizeInMegabytes = fileSizeInKilobytes / 1024;

        return {
          b: fileSizeInBytes.toFixed(2),
          kb: fileSizeInKilobytes.toFixed(2),
          mb: fileSizeInMegabytes.toFixed(2),
        };
      }),
    );
  }

  resetModification(): void {
    this.modification.next(INITIAL_IMAGE_MODIFICATION);
  }

  move(imagePosition: Position): void {
    const currentModification: AttachmentViewModification
      = this.modification.getValue();

    this.modification.next(
      {
        ...currentModification,
        position: imagePosition,
      },
    );
  }

  zoomIn(): void {
    const currentModification: AttachmentViewModification
      = this.modification.getValue();
    const indexOfCurrentScale: number = SCALE_OPTIONS.indexOf(
      currentModification.scale,
    );
    const newIndexOfScale: number = indexOfCurrentScale + 1;

    this.modification.next({
      ...currentModification,
      scale: SCALE_OPTIONS[
        newIndexOfScale === SCALE_OPTIONS.length
          ? SCALE_OPTIONS.length - 1
          : newIndexOfScale
      ],
    });
  }

  zoomOut(): void {
    const currentModification: AttachmentViewModification
      = this.modification.getValue();
    const indexOfCurrentScale: number = SCALE_OPTIONS.indexOf(
      currentModification.scale,
    );
    const newIndexOfScale: number = indexOfCurrentScale - 1;

    this.modification.next({
      ...currentModification,
      scale: SCALE_OPTIONS[
        newIndexOfScale === -1
          ? 0
          : newIndexOfScale
      ],
    });
  }

  rotateRight(): void {
    const currentModification: AttachmentViewModification
      = this.modification.getValue();
    const indexOfCurrentRotation: number = ROTATION_OPTIONS.indexOf(
      currentModification.rotation,
    );
    const newIndexOfRotation: number = indexOfCurrentRotation + 1;

    this.modification.next({
      ...currentModification,
      rotation: ROTATION_OPTIONS[
        newIndexOfRotation === ROTATION_OPTIONS.length
          ? 0
          : newIndexOfRotation
      ],
    });
  }

  rotateLeft(): void {
    const currentModification: AttachmentViewModification
      = this.modification.getValue();
    const indexOfCurrentRotation: number = ROTATION_OPTIONS.indexOf(
      currentModification.rotation,
    );
    const newIndexOfRotation: number = indexOfCurrentRotation - 1;

    this.modification.next({
      ...currentModification,
      rotation: ROTATION_OPTIONS[
        newIndexOfRotation === -1
          ? ROTATION_OPTIONS.length - 1
          : newIndexOfRotation
      ],
    });
  }

  downloadFile(file: File): void {
    switch (this.currentChatGqlService.getCurrentChat()?.messenger.type) {
      case MessengerType.Instagram:
        this.downloadFileContentByFile(file);
        break;
      default:
        this.getFileContent(file).pipe(
          tap(this.downloadFileContent),
        ).subscribe();
    }
  }

  private downloadFileContent(
    imageContentResponse: ImageContentResponse,
  ): void {
    const downloadLink: HTMLAnchorElement
      = document.createElement(LINK_TAG_NAME);
    const blobUrl: string = URL.createObjectURL(imageContentResponse.content);

    downloadLink.href = blobUrl;
    downloadLink.download = imageContentResponse.fileName;
    downloadLink.click();
    URL.revokeObjectURL(blobUrl);
    downloadLink.remove();
  }

  private downloadFileContentByFile(
    file: File,
  ): void {
    if (file.fileUrl.includes(INSTAGRAM_FILE_DOMAIN)) {
      const downloadLink: HTMLAnchorElement
        = document.createElement(LINK_TAG_NAME);

      downloadLink.target = '_blank';
      downloadLink.href = file.fileUrl;
      downloadLink.download = file.fileName;
      downloadLink.click();
      downloadLink.remove();
    } else {
      this.getFileContent(file).pipe(
        tap(this.downloadFileContent),
      ).subscribe();
    }
  }

  private getFileContent(file: File): Observable<ImageContentResponse> {
    return this.s3FileService.getContent(file.id).pipe(
      map((content) => ({
        fileName: file.fileName,
        content,
      })),
    );
  }
}

interface ImageContentResponse {
  fileName: string;
  content: Blob;
}
