let commitHash;

try {
  commitHash = require('./releaseParams.json').commitHash;
// eslint-disable-next-line no-empty
} catch (e) {
}

module.exports = {
  commitHash,
};
