<div class="payment-method-message" *ngIf="paymentMethodMessage; else otherOperations">
  {{ paymentMethodMessage }}
</div>
<ng-template #otherOperations>
  <div class="details-row" *ngFor="let paymentDetail of paymentDetails">
    <p class="details-row__name">{{ paymentDetail.name }}</p>
    <div class="details-row__price">
      {{ paymentDetail.price }} / {{ paymentDetail.period }}
    </div>
  </div>
  <div class="details-row" *ngIf="recalculationInfo">
    <nz-tag
      nz-tooltip
      nzTooltipPlacement="left"
      [nzTooltipTitle]="getSubscriptionChangingMessage(recalculationInfo)"
      [nzColor]="'blue'"
      style="cursor: default;"
    >
      <span nz-icon nzType="calculator" nzTheme="twotone"></span>
      <span>
        {{ 'payment_details.number_of_additional_days' | i18next: { number_of_additional_days: recalculationInfo.newAdditionalDays } }}
      </span>
    </nz-tag>
    <p class="details-row__price">{{ 'payment_details.days_from_current_plan' | i18next }}</p>
  </div>
  <div class="summary-row">
    <p class="summary-row__name">{{ 'payment_details.summary.total_due' | i18next }}</p>
    <div class="summary-row__price">
      {{ paymentTransaction.currency.code | currencySymbol }} {{ paymentTransaction.amount }}
      <span class="summary-row__period">{{ totalPeriod }}</span>
    </div>
  </div>
</ng-template>
