import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  private isSentryEnabled = environment.sentry.enabled === 'true';

  info(message: string): void {
    if (this.isSentryEnabled) {
      Sentry.addBreadcrumb(
        {
          level: 'info',
          message,
        },
      );
    } else {
      // eslint-disable-next-line no-console
      console.log('INFO', message);
    }
  }

  error(message: string): void {
    if (this.isSentryEnabled) {
      Sentry.captureMessage(
        message,
        {
          level: 'error',
        },
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('ERROR', message);
    }
  }

  warn(message: string): void {
    if (this.isSentryEnabled) {
      Sentry.captureMessage(
        message,
        {
          level: 'warning',
        },
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn('WARN', message);
    }
  }
}
