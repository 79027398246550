import {
  Component, ElementRef, HostListener,
  Input, OnChanges, SimpleChanges, ViewChild,
} from '@angular/core';
import { File } from '@app/graphql/graphql';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnChanges {
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  @Input() file: File;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file.previousValue) {
      this.reloadVideo();
    }
  }

  @HostListener('document:keydown.space', ['$event'])
  togglePlay($event: KeyboardEvent): void {
    $event.stopPropagation();
    $event.preventDefault();

    const video = this.video.nativeElement;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  private reloadVideo(): void {
    this.video.nativeElement.load();
  }
}
