import { Injectable } from '@angular/core';
import { isArray } from '@apollo/client/cache/inmemory/helpers';
import { ChatPreviewFilter, ChatStatusName } from '@app/graphql/graphql';
import { MultiselectValue } from '@app/common/model/multiselect.value';
import { toEndOfTheDate, toStartOfTheDate } from '@app/util/date.util';
import { ChatCategoryOptionValue } from './model/chat-category-option';
import { CHAT_FILTER_CONTROL_NAME } from './filter-section.config';
import { ChatStatusOptionValue } from './model/chat-status-option';
import { ChatPreviewFilterInputParameters } from './model/chat-preview-filter-input-parameters';

const FROM_DATE_INDEX = 0;
const TO_DATE_INDEX = 1;

@Injectable({ providedIn: 'root' })
export class ChatPreviewFilterMapper {
  private readonly chatStatusOptionValueMap
    = new Map<ChatStatusOptionValue, ChatStatusName[]>([
      [ChatStatusOptionValue.ALL, [
        ChatStatusName.New,
        ChatStatusName.Processing,
        ChatStatusName.Archived,
      ]],
      [ChatStatusOptionValue.NEW, [
        ChatStatusName.New,
      ]],
      [ChatStatusOptionValue.MY_TASK, [
        ChatStatusName.Processing,
      ]],
      [ChatStatusOptionValue.COMPLETED, [
        ChatStatusName.Archived,
      ]],
      [ChatStatusOptionValue.SPAM, [
        ChatStatusName.Spam,
      ]],
    ]);

  private readonly multiselectValueMap
    = new Map<MultiselectValue, string[]>([
      [MultiselectValue.ALL, null],
      [MultiselectValue.NONE, []],
    ]);

  private readonly chatCategoryOptionValueMap
    = new Map<ChatCategoryOptionValue, string[]>([
      [ChatCategoryOptionValue.ALL, null],
      [ChatCategoryOptionValue.NONE, []],
    ]);

  toChatPreviewFilter(
    parameters: ChatPreviewFilterInputParameters,
  ): ChatPreviewFilter {
    const createDates: Date[] = parameters[CHAT_FILTER_CONTROL_NAME.createDate];

    return {
      chatStatusNames: this.chatStatusOptionValueMap
        .get(parameters.chatStatus),
      responsibleOperatorIds: this.toIdsValue(parameters.chatOperatorIds),
      categoryIds: this.getChatCategoryIds(parameters.chatCategories),
      channelIds: this.toIdsValue(parameters.channelIds),
      createTimeFrom: createDates?.length
        ? toStartOfTheDate(createDates[FROM_DATE_INDEX])
        : null,
      createTimeTo: createDates?.length
        ? toEndOfTheDate(createDates[TO_DATE_INDEX])
        : null,
    };
  }

  toIdsValue(
    value: string[] | MultiselectValue,
  ): string[] {
    if (!isArray(value)) {
      return this.multiselectValueMap.get(value);
    }

    return value;
  }

  private getChatCategoryIds(
    value: string,
  ): string[] {
    if (!value) {
      return null;
    }

    const parsedValue = JSON.parse(value);

    return typeof parsedValue === 'string'
      ? this.chatCategoryOptionValueMap.get(
        parsedValue as ChatCategoryOptionValue,
      )
      : parsedValue;
  }
}
