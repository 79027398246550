import {
  Directive, ElementRef, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import {
  animate, AnimationBuilder, style,
} from '@angular/animations';
import { AttachmentViewModification } from '@app/attachment-view/model/attachment-view-modification';
import { AttachmentViewImageRotation } from '@app/attachment-view/model/attachment-view-image-rotation';

const DEFAULT_ANIMATION_TIME = '100ms';

@Directive({
  selector: '[appAttachmentViewModifiable]',
})
export class AttachmentViewModifiableDirective implements OnChanges {
  @Input() modification: AttachmentViewModification;

  constructor(
    private image: ElementRef,
    private animationBuilder: AnimationBuilder,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modification) {
      if (changes.modification.previousValue?.position
        === changes.modification.currentValue.position) {
        this.animate(changes.modification.currentValue);

        return;
      }

      this.animate(changes.modification.currentValue, '0ms');
    }
  }

  private toTransform(modification: AttachmentViewModification): string {
    return `translate(${modification.position.x}px, ${modification.position.y}px) scale(${modification.scale}) rotate(${modification.rotation}deg)`;
  }

  private animate(
    modification: AttachmentViewModification,
    time: string = DEFAULT_ANIMATION_TIME,
  ): void {
    this.animationBuilder
      .build([
        animate(
          time,
          style({
            transform: this.toTransform(modification),
            maxWidth: isSideRotation(modification.rotation)
              ? '75vh'
              : '75%',
          }),
        ),
      ])
      .create(this.image.nativeElement)
      .play();
  }
}

function isSideRotation(rotation: AttachmentViewImageRotation): boolean {
  return rotation === AttachmentViewImageRotation.DEGREES_90
    || rotation === AttachmentViewImageRotation.DEGREES_270;
}
