import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  NotificationSettings,
  NotificationSettingsGQL, NotificationSettingsInput,
  UpdateNotificationSettingsGQL,
} from '../graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsGqlService {
  constructor(
    private notificationSettingsGQL: NotificationSettingsGQL,
    private updateNotificationSettingsGQL: UpdateNotificationSettingsGQL,
  ) {}

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.notificationSettingsGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((value) => value.data.notificationSettings as NotificationSettings),
    );
  }

  updateNotificationSettings(
    input: NotificationSettingsInput,
  ): Observable<NotificationSettings> {
    return this.updateNotificationSettingsGQL.mutate(
      {
        input,
      },
    ).pipe(
      map((value) => value.data
        .updateNotificationSettings as NotificationSettings),
    );
  }
}
