import { Injectable } from '@angular/core';
import {
  FormControl, FormGroup, ValidatorFn,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AbstractInputField } from '@app/settings/channels-page/models';
import { CustomValidators } from './util/custom-validators';
import { FormValidationService } from './form-validation-service';

const DEFAULT_OPTIONS: Options = {
  required: true,
  validators: [],
};

@Injectable({ providedIn: 'root' })
export class SettingsFieldsValidationService {
  private readonly isActive = new BehaviorSubject<boolean>(false);

  readonly isActive$ = this.isActive.asObservable();

  formGroup!: FormGroup;

  isInvalid(): boolean {
    return this.formGroup.invalid || this.formGroup.disabled;
  }

  constructor(private formValidationService: FormValidationService) {
  }

  setFormGroup(formGroup: FormGroup): void {
    this.formGroup = formGroup;
    this.isActive.next(true);
  }

  init(
    fields: AbstractInputField[],
    options: Options = DEFAULT_OPTIONS,
  ): FormGroup {
    this.formGroup = new FormGroup({});
    this.formGroup.disable();

    fields = fields.filter((field) => !!field.name);

    const validators = options.required
      ? [this.formValidationService.notBlank()]
      : [];

    fields.forEach((field) => {
      SettingsFieldsValidationService.initOptionalFields(field);

      const control = new FormControl(
        field.sourceValue,
        [...field.validators, ...validators],
      );

      if (field.disabled) {
        control.disable();
      }

      this.formGroup.setControl(field.name, control);
    });

    this.formGroup.setValidators(CustomValidators.atLeastOneChanged(fields));
    options.validators?.forEach(
      (validator) => this.formGroup.addValidators(validator(fields)),
    );

    fields
      .filter((field) => !field.disabled)
      .forEach((field) => {
        this.formGroup.controls[field.name].enable();
      });

    this.isActive.next(true);

    return this.formGroup;
  }

  destroy() {
    this.isActive.next(false);
  }

  private static initOptionalFields(field: AbstractInputField): void {
    field.validators = field.validators
      ? field.validators
      : [];
  }
}

interface Options {
  required?: boolean;
  validators?: ((any)=>ValidatorFn)[];
}
