export class FeatureFlag {
  name: string;

  status: string;
}

export enum FeatureStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}
