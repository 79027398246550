import { Injectable } from '@angular/core';
import { countries } from 'countries-list';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countriesList: Country[] = [];

  constructor() {
    this.initCountries();
  }

  private initCountries() {
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in countries) {
      this.countriesList.push({
        name: countries[key].name,
        emoji: countries[key].emoji,
        label: `${countries[key].emoji} ${countries[key].name}`,
      });
    }

    this.countriesList.sort((a, b) => {
      const countryA = a.name.toLowerCase();
      const countryB = b.name.toLowerCase();

      // eslint-disable-next-line no-nested-ternary
      return countryA < countryB
        ? -1
        // eslint-disable-next-line ternary/nesting
        : countryA > countryB
          ? 1
          : 0;
    });
  }

  public getCountries(): Country[] {
    return this.countriesList;
  }
}

export interface Country {
  name: string;
  emoji: string;
  label: string;
}
