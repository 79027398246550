import { Router } from '@angular/router';
import { Component } from '@angular/core';
import {
  WidgetInstallationType,
} from '@app/settings/channels-page/channel-details-page/installation-page/widget-instalation-page/widget-installation-type';

@Component({
  templateUrl: './shopify-installation-proxy.component.html',
})
export class ShopifyInstallationProxyComponent {
  constructor(
    private router: Router,
  ) {
    const currentUrl = this.router.url;
    const urlWithoutLastSlug = currentUrl.substring(0, currentUrl.lastIndexOf('/'));

    const redirectUrlTree = this.router.parseUrl(urlWithoutLastSlug);

    redirectUrlTree.queryParams.type = WidgetInstallationType.SHOPIFY;
    router.navigateByUrl(redirectUrlTree);
  }
}
