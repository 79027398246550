import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { LOCAL_STORAGE_KEY } from '@src/constants/local_storage.constants';
import { SORT_ORDER } from '@src/constants/sort-type.constants';
import { Tab } from '@src/constants/tab-info.constants';
import { ChatSortMode } from '@app/graphql/graphql';
import { SideWidgetStorage } from '@app/side-widget/side-widget.storage';

const DELIMITER = ',';
const SORT_MODE_KEY_PART = '_tab_sort_mode';
const DEFAULT_SORT_MODE = ChatSortMode.NewestLastMessageFirst;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly localeMap = new Map([
    ['ua', 'uk'],
  ]);

  constructor(
    private sideWidgetStorage: SideWidgetStorage,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  getCurrentOperatorId() {
    return Number(this.get(LOCAL_STORAGE_KEY.operator));
  }

  getCurrentOperatorIdString() {
    return this.get(LOCAL_STORAGE_KEY.operator);
  }

  isCurrentOperator(operatorId: string | number) {
    if (typeof operatorId === 'number') {
      return Number(this.get(LOCAL_STORAGE_KEY.operator))
        === operatorId;
    }

    return this.get(LOCAL_STORAGE_KEY.operator) === operatorId;
  }

  isSkippedSignUpChannels(): boolean {
    return this.get(LOCAL_STORAGE_KEY.skipSignUpChannels) === 'true';
  }

  setSkippedSignUpChannels() {
    return this.set(LOCAL_STORAGE_KEY.skipSignUpChannels, 'true');
  }

  getChatPreviewSortingOrder() {
    return this.get(LOCAL_STORAGE_KEY.newTabChatOrder)
      || SORT_ORDER.desc;
  }

  saveChatPreviewSortingOrder(sortType: string) {
    this.set(LOCAL_STORAGE_KEY.newTabChatOrder, sortType);
  }

  saveChatPreviewSortMode(tab: Tab, sortModes: ChatSortMode[]) {
    this.set(`${tab.toLowerCase()}${SORT_MODE_KEY_PART}`, sortModes.join(DELIMITER));
  }

  getChatPreviewSortModes(tab: Tab): ChatSortMode[] {
    const stringSortModes = this.get(`${tab.toLowerCase()}${SORT_MODE_KEY_PART}`);

    if (stringSortModes) {
      return stringSortModes.split(DELIMITER)
        .map((sortMode) => sortMode as ChatSortMode);
    }

    return [DEFAULT_SORT_MODE];
  }

  getCompanyId() : string {
    return this.get(LOCAL_STORAGE_KEY.companyId);
  }

  hasRole(role: string): boolean {
    return this.get(LOCAL_STORAGE_KEY.roles)?.includes(role);
  }

  getRoles(): string[] {
    const roles = this.get(LOCAL_STORAGE_KEY.roles);

    if (roles) {
      return roles.split(DELIMITER);
    }

    return [];
  }

  setLanguage(language: string): void {
    this.set(LOCAL_STORAGE_KEY.language, language);
  }

  getLanguage(): string {
    const language = this.get(LOCAL_STORAGE_KEY.language)
      || this.translationService.language;

    return this.localeMap.get(language) || language || 'en';
  }

  remove(key: string): void {
    if (this.sideWidgetStorage.isInitialized()) {
      this.sideWidgetStorage.removeLocalStorageValue(key);
    } else {
      localStorage.removeItem(key);
    }
  }

  set(key: string, value: string): void {
    if (this.sideWidgetStorage.isInitialized()) {
      this.sideWidgetStorage.setLocalStorageValue({ [key]: value });
    } else {
      localStorage.setItem(key, value);
    }
  }

  setValues(values: { [key: string]: string }): void {
    if (this.sideWidgetStorage.isInitialized()) {
      this.sideWidgetStorage.setLocalStorageValue(values);
    } else {
      Object.entries(values).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    }
  }

  get(key: string): string {
    return this.sideWidgetStorage.isInitialized()
      ? this.sideWidgetStorage.getLocalStorageValue(key)
      : localStorage.getItem(key);
  }

  clear(keys: string[]): void {
    if (this.sideWidgetStorage.isInitialized()) {
      this.sideWidgetStorage.clearLocalStorage(keys);
    } else {
      keys.forEach((key) => {
        localStorage.removeItem(key);
      });
    }
  }
}
