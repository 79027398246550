import { MessengerType, WidgetChannelType } from '@app/graphql/graphql';

export class WidgetProperties {
  title: string;

  introductionText?: string;

  language: string;

  channels: WidgetChannel[];

  liveChatEnabled: boolean;

  view?: WidgetViewProperties;

  preChatForm?: PreChatFormOld;

  preChatFormOnline?: PreChatForm;

  preChatFormOffline?: PreChatForm;
}

export interface PreChatFormOld {
  message: string;
  fields: PreChatFormFieldOld[];
}

export interface PreChatForm {
  enabled: boolean;
  department: PreChatFormField;
  name: PreChatFormField;
  email: PreChatFormField;
  phone: PreChatFormField;
}

export interface PreChatFormField {
  options?: (OptionView | null)[];
  enabled: boolean;
  required: boolean;
}

export interface OptionView {
  value: number;
  label: string;
}

export enum PreChatFormFieldOld {
  NAME='NAME',
  EMAIL='EMAIL',
  PHONE_NUMBER='PHONE_NUMBER'
}

export interface WidgetViewProperties {
  backgroundColor: string;
  textColor: WidgetTextColor;
  position: WidgetPosition;
}

export interface WidgetChannel {
  id?: number;

  messenger: MessengerType;

  type?: WidgetChannelType;

  link: string;

  active: boolean;
}

export enum WidgetTextColor {
  LIGHT='LIGHT',
  DARK='DARK'
}

export enum WidgetPosition {
  BOTTOM_LEFT='BOTTOM_LEFT',
  BOTTOM_RIGHT='BOTTOM_RIGHT'
}
