import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import imageCompression from 'browser-image-compression';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { S3File } from '@app/model/s3-file';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class S3FileService {
  private readonly baseUrl = environment.apiBaseUrl;

  private readonly fileAppendix = 'files/s3';

  private readonly contentPostfix = '/content';

  private readonly urlPostfix = '/url';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  generateLink(fileType: string, fileName: string): Observable<S3File> {
    return this.httpClient.get<S3File>(
      `${this.baseUrl}${this.fileAppendix}${this.urlPostfix}`,
      {
        params: new HttpParams()
          .append('name', fileName)
          .append('type', fileType),
      },
    );
  }

  uploadNzFileToS3(url: string, file: NzUploadFile): Observable<any> {
    return this.httpClient.put(
      url,
      file,
      {
        headers: {
          'Content-Type': file.type,
        },
      },
    );
  }

  async uploadFileToS3WithProgress(
    url: string,
    file: File,
  ): Promise<Observable<any>> {
    const compressedFile = await this.compressFileIfNecessary(file);

    return this.httpClient.put(url, compressedFile, {
      headers: {
        'Content-Type': file.type,
      },
      reportProgress: true,
      observe: 'events',
    });
  }

  getContent(fileId: string): Observable<Blob> {
    return this.httpClient.get(
      `${this.baseUrl}${this.fileAppendix}/${fileId}${this.contentPostfix}`,
      { responseType: 'blob' },
    );
  }

  private async compressFileIfNecessary(file: File): Promise<File> {
    const extensions = ['png', 'jpg', 'jpeg'];
    const byteToMbSize = 1000000;

    if (file.size > byteToMbSize
      && extensions.includes(file.type.split('/')[1])) {
      const options = {
        maxSizeMB: 1,
      };

      await imageCompression(file, options)
        .then((compressedFile) => {
          file = compressedFile;
        });
    }

    return file;
  }
}
