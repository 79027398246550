import {
  defer, EMPTY, first, Observable, of,
} from 'rxjs';

export const TRUE_OBSERVABLE = of(true);
export const FALSE_OBSERVABLE = of(false);
export const EMPTY_OBSERVABLE = EMPTY;
export const NULL_OBSERVABLE = of(null);
export const EMPTY_LIST_OBSERVABLE = of([]);

export function doOnSubscribe<T>(
  onSubscribe: () => void,
): (source: Observable<T>) => Observable<T> {
  return function inner(source: Observable<T>): Observable<T> {
    return defer(() => {
      onSubscribe();

      return source;
    });
  };
}

export function firstNoNull<T>(): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source
    .pipe(
      first((value) => !!value),
    );
}
