<div class="select_list">
  <div *ngFor="let page of pages; last as isLast"
       [ngClass]="'list_item' + (isLast ? ' last_list_item' : '')">
    <div class="item_info">
      <div class="item_image_replacer">{{ getFirstCharacter(page.name) }}</div>
      <div class="item_name_box">
        <div class="item_name">{{ page.name }}</div>
        <div class="item_connected" *ngIf="page.isConnected">{{ 'fb_channel_page_is_already_connected_info' | i18nextCap }}</div>
      </div>
    </div>
    <span nz-tooltip [nzTooltipTitle]="page.isConnected ? pageIsConnectedTooltip : null">
      <input class="page_radio_button" type="radio" [name]="page.isConnected ? 'page_group_connected' : 'page_group'" [value]="page" [checked]="page.isConnected" (click)="selectPage(page)" [disabled]="page.isConnected">
    </span>
  </div>
</div>
