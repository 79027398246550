import { Inject, Pipe, PipeTransform } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ParametrizedTranslation } from '@app/model/parametrized-translation';

@Pipe({
  name: 'parametrizedTranslation',
})
export class ParametrizedTranslationPipe implements PipeTransform {
  constructor(
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  transform(text: string): string {
    try {
      const parametrizedTranslation
        = JSON.parse(text) as ParametrizedTranslation;

      if (this.translationService.exists(parametrizedTranslation.code, {})) {
        return this.translationService.t(
          parametrizedTranslation.code,
          parametrizedTranslation.params,
        );
      }

      return text;
    } catch (e) {
      if (this.translationService.exists(text, {})) {
        return this.translationService.t(text);
      }

      return text;
    }
  }
}
