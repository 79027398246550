import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chat, ChatOperator, ChatStatusName } from './graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class CurrentChatGqlService {
  private readonly chat: BehaviorSubject<Chat> = new BehaviorSubject(null);

  readonly chat$: Observable<Chat> = this.chat.asObservable();

  setCurrentChat(chat: Chat): void {
    this.chat.next(chat);
  }

  getCurrentChat(): Chat {
    return this.chat.getValue();
  }

  removeCurrentChat(): void {
    this.chat.next(null);
  }

  updateStatus(status: ChatStatusName) {
    this.chat.next(
      {
        ...this.getCurrentChat(),
        status,
      },
    );
  }

  setChatStatusProcessing(chatOperator: ChatOperator): void {
    const chat: Chat = this.chat.getValue();

    if (
      !chat
      || chat.status === ChatStatusName.Processing
      || !!chat.chatOperator
    ) {
      return;
    }

    this.chat.next({
      ...chat,
      status: ChatStatusName.Processing,
      chatOperator,
    });
  }
}
