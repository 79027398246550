import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FIREBASE_URL } from '@app/excluded-url.constants';
import { LOCAL_STORAGE_KEY } from '@src/constants/local_storage.constants';
import { SentryService } from '@app/sentry.service';
import { LocalStorageService } from '@app/local-storage-service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private localStorageService: LocalStorageService,
  ) {}

  sendPushNotification(
    notificationBody: string,
    redirectUrl: string,
    notificationUUID: number,
  ): Observable<void> {
    const token = sessionStorage.getItem('firebaseToken');

    if (token === null) {
      this.sentryService.warn(`Firebase push notification: token is null for operator with id - ${
        this.localStorageService.get(LOCAL_STORAGE_KEY.operator)}`);

      return EMPTY;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `key=${environment.firebaseServerKey}`,
      }),
    };

    const body = {
      notification: {
        title: 'Cusbo',
        body: notificationBody,
        icon: `${environment.ip}favicon.png`,
        click_action: redirectUrl,
      },
      data: {
        uuid: notificationUUID,
      },
      to: token,
    };

    return this.http.post<void>(FIREBASE_URL, body, httpOptions)
      .pipe(
        catchError(
          (error) => {
            this.sentryService.warn(`Firebase push notification: send error - ${JSON.stringify(error)}`);

            return throwError(error);
          },
        ),
      );
  }
}
