export enum AttachmentViewImageScale {
  SCALE_25 = '0.25',
  SCALE_50 = '0.5',
  SCALE_75 = '0.75',
  SCALE_100 = '1',
  SCALE_150 = '1.5',
  SCALE_250 = '2.5',
  SCALE_350 = '3.5',
  SCALE_450 = '4.5',
}
