import { MessageType } from '../graphql/graphql';

export const INFO_MESSAGE_TYPES = [
  MessageType.BotBlockedInfo,
  MessageType.ChatRescueInfo,
  MessageType.Info,
  MessageType.PrivateInfo,
  MessageType.PendingChatInvitationRequest,
];

export const CHAT_RESCUE_MESSAGE_TYPES_FROM_USER = [
  MessageType.Conversation,
  MessageType.ContactShare,
  MessageType.UserInformationShare,
];

export const CHAT_RESCUE_FORBIDDEN_MESSAGE_TYPES = [
  MessageType.ChatTransferredInfo,
  MessageType.ConversationStartedInfo,
  MessageType.ContactRequest,
];

export const INFO_MESSAGE_TYPES_WITH_OPERATOR_PLACEHOLDER = [
  MessageType.ChatMovedToTabInfo,
  MessageType.ChatMarkedAsSpamInfo,
];

export const ALL_INFO_MESSAGE_TYPES = [
  MessageType.Info,
  MessageType.BotBlockedInfo,
  MessageType.PrivateInfo,
  MessageType.PendingChatInvitationRequest,
  MessageType.ChatMovedToTabInfo,
  MessageType.ChatMarkedAsSpamInfo,
  MessageType.ChatTransferredInfo,
  MessageType.ConversationStartedInfo,
  MessageType.ChatRescueInfo,
];

export const INFO_MESSAGE_TYPES_WITH_START_TIME_PLACEHOLDER = [
  MessageType.ConversationStartedInfo,
];
