import { Inject, Injectable } from '@angular/core';
import {
  AbstractControl, FormControl,
  FormGroup, ValidationErrors,
  ValidatorFn, Validators,
} from '@angular/forms';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { pipe } from 'rxjs';
import { CustomValidators, PHONE_NUMBER_REGEX, toAbstractFields } from '@app/util/custom-validators';

const EMAIL_REGEX = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const VALID_URL_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([?=/\w #.-]*)*\/?$/;
const VALID_NUMBER_REGEX = new RegExp(/^(0|[1-9]\d*)$/);
const VALID_IP_ADDRESS_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
const VALID_HOSTNAME_REGEX = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
const PORT_MIN_VALUE = 1;
const PORT_MAX_VALUE = 65535;

const MAX_FIRST_NAME_LENGTH = 100;
const MAX_LAST_NAME_LENGTH = 100;

const ERROR_PROPERTY_NAME = {
  refersToAs: 'wrongRefers',
};

export const ERROR_TIP_PROPERTY_NAME = 'errorTip';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor(
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  addAtLeastOneChangedValidator(formGroup: FormGroup): void {
    formGroup.addValidators(this.getAtLeastOneChangedValidator(formGroup));
  }

  getAtLeastOneChangedValidator(
    formGroup: FormGroup,
    transform: (value: any) => any = (value) => value,
  ): ValidatorFn {
    return CustomValidators
      .atLeastOneChanged(toAbstractFields(formGroup), transform);
  }

  refersToAs(
    firstControlName: string,
    secondControlName: string,
    isValid: (
      firstValue: string | number,
      secondValue: string | number,
    ) => boolean,
    errorTipSlug?: string,
  ): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const firstControl: AbstractControl = formGroup.get(firstControlName);
      const secondControl: AbstractControl = formGroup.get(secondControlName);

      if (isValid(firstControl.value, secondControl.value)) {
        removeError(firstControl, ERROR_PROPERTY_NAME.refersToAs);
        removeError(secondControl, ERROR_PROPERTY_NAME.refersToAs);

        return null;
      }

      firstControl.setErrors({
        ...firstControl.errors,
        [ERROR_PROPERTY_NAME.refersToAs]: true,
      });
      secondControl.setErrors({
        ...secondControl.errors,
        [ERROR_PROPERTY_NAME.refersToAs]: true,
      });

      return {
        [ERROR_PROPERTY_NAME.refersToAs]: true,
        [ERROR_TIP_PROPERTY_NAME]: this.translationService.t(errorTipSlug),
      };
    };
  }

  refersToAsIfEnabled(
    enabledControlName: string,
    firstControlName: string,
    secondControlName: string,
    isValid: (
      enabled: boolean,
      firstValue: string | number | Date,
      secondValue: string | number | Date,
    ) => boolean,
    errorTipSlug?: string,
  ): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const firstControl: AbstractControl = formGroup.get(firstControlName);
      const secondControl: AbstractControl = formGroup.get(secondControlName);
      const enabledControl: AbstractControl = formGroup.get(enabledControlName);

      if (
        isValid(enabledControl.value, firstControl.value, secondControl.value)
      ) {
        removeError(firstControl, ERROR_PROPERTY_NAME.refersToAs);
        removeError(secondControl, ERROR_PROPERTY_NAME.refersToAs);

        return null;
      }

      firstControl.setErrors({
        ...firstControl.errors,
        [ERROR_PROPERTY_NAME.refersToAs]: true,
      });
      secondControl.setErrors({
        ...secondControl.errors,
        [ERROR_PROPERTY_NAME.refersToAs]: true,
      });

      return {
        [ERROR_PROPERTY_NAME.refersToAs]: true,
        [ERROR_TIP_PROPERTY_NAME]: this.translationService.t(errorTipSlug),
      };
    };
  }

  required(
    errorTipSlug = 'settings.validation.required_field',
  ): ValidatorFn {
    return pipe(
      Validators.required,
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  notBlank(
    errorTipSlug = 'settings.validation.required_field',
  ): ValidatorFn {
    return pipe(
      CustomValidators.notBlank(),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  min(
    min: number,
    errorTipSlug = 'validation.min_number.default_message',
  ): ValidatorFn {
    return pipe(
      Validators.min(min),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  max(
    max: number,
    errorTipSlug = 'validation.max_number.default_message',
  ): ValidatorFn {
    return pipe(
      Validators.max(max),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  validNumber(
    errorTipSlug = 'validation.not_valid_number.default_message',
  ): ValidatorFn {
    return pipe(
      Validators.pattern(VALID_NUMBER_REGEX),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  validUrl(
    errorTipSlug = 'validation.not_valid_url.default_message',
    isRequired = true,
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => ((!isRequired && !control.value)
      || VALID_URL_REGEX.test(control.value)
        ? null
        : { invalidEmail: control.value }),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  not<T>(
    value: T,
    errorTipSlug = 'settings.validation.required_field',
  ): ValidatorFn {
    return pipe(
      (
        control: AbstractControl,
      ): { [key: string]: any } | null => (control.value === value
        ? { invalidValue: control.value }
        : null),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  notIn<T>(
    values: T[],
    errorTipSlug = 'validation.not_in_array.default_message',
  ): ValidatorFn {
    return pipe(
      (
        control: AbstractControl,
      ): { [key: string]: any } | null => {
        const hasValue = values
          .some((value) => value === control.value);

        return hasValue
          ? { valueAlreadyExists: control.value }
          : null;
      },
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  minLength(
    minLength: number,
    errorTipSlug = 'validation.min_length.default_message',
  ): ValidatorFn {
    return pipe(
      this.minLengthPure(
        minLength,
        errorTipSlug,
      ),
      addCounterToMinLengthErrorTip(),
    );
  }

  maxLength(
    maxLength: number,
    errorTipSlug = 'validation.max_length.default_message',
  ): ValidatorFn {
    return pipe(
      this.maxLengthPure(
        maxLength,
        errorTipSlug,
      ),
      addCounterToMaxLengthErrorTip(),
    );
  }

  maxLengthPure(
    maxLength: number,
    errorTipSlug = 'validation.max_length.default_message',
  ): ValidatorFn {
    return pipe(
      Validators.maxLength(maxLength),
      addErrorTip(
        this.translationService.t(errorTipSlug),
      ),
    );
  }

  noWhitespaceValidatorFunction(
    errorTipSlug = 'validation.not_blank.default_message',
  ): ValidatorFn {
    return pipe(
      (
        control: AbstractControl,
      ): { [key: string]: any } | null => {
        const value = control.value || '';

        return value.length > 0
          && value.trim().length === 0
          ? { blank: value }
          : null;
      },
      addErrorTip(
        this.translationService.t(errorTipSlug),
      ),
    );
  }

  validPhoneNumber(
    errorTipSlug = 'validation.not_valid_phone_number.default_message',
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => (PHONE_NUMBER_REGEX.test(control.value)
        ? null
        : { invalidPhoneNumber: control.value }),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  equals(value: any): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => (control.value === value
        ? null
        : { invalidValue: control.value }),
    );
  }

  validEmail(
    errorTipSlug = 'validation.not_valid_email.default_message',
    withSpaces = false,
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => (!control.value?.length || EMAIL_REGEX.test(
        withSpaces
          ? control.value?.toLowerCase().trim()
          : control.value?.toLowerCase(),
      )
        ? null
        : { invalidEmail: control.value }),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  validHost(
    isRequired = true,
    errorTipSlug = 'validation.not_valid_host.default_message',
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => {
        if (!control.value && !isRequired) {
          return null;
        }

        return (
          VALID_HOSTNAME_REGEX.test(control.value)
          || VALID_IP_ADDRESS_REGEX.test(control.value)
            ? null
            : { invalidHost: control.value });
      },
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  validPortNumber(
    isRequired = true,
    errorTipSlug = 'validation.not_valid_port_number.default_message',
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => {
        try {
          if (!control.value && !isRequired) {
            return null;
          }

          const portNumber = Number(control.value);

          return portNumber >= PORT_MIN_VALUE && portNumber <= PORT_MAX_VALUE
            ? null
            : { invalidPortNumber: control.value };
        } catch (e) {
          return { invalidPortNumber: control.value };
        }
      },
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  notBlankIfParentEnabled(
    parent: string,
    errorTipSlug = 'validation.not_blank.default_message',
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): {
        [key: string]: any;
      } | null => (
        (control.parent as FormGroup)?.controls[parent].value === true
          && (control.value || '').trim().length === 0
          ? { blank: true }
          : null
      ),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  noWhitespaceValidator(control: FormControl) {
    const isValid = (control.value || '').trim().length !== 0;

    return isValid
      ? {}
      : { blank: true };
  }

  emailValidator = (control: FormControl):
    { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }

    if (!control.value.toLowerCase().match(EMAIL_REGEX)) {
      return {
        invalidEmail: true,
        error: true,
      };
    }

    return {};
  };

  getPasswordsMatchValidator(
    passwordFieldName: string,
    repeatPasswordFieldName: string,
  ) {
    return (control: AbstractControl) => {
      const password: string = control.get(passwordFieldName).value;
      const repeatPassword: string = control.get(repeatPasswordFieldName).value;

      if (password !== repeatPassword) {
        control.get(repeatPasswordFieldName)
          .setErrors({ confirm: true, error: true });
      } else {
        control.get(repeatPasswordFieldName).setErrors(null);
      }
    };
  }

  getPasswordsMatchValidatorFn(
    passwordFieldName: string,
    repeatPasswordFieldName: string,
    errorTipSlug = 'failed_password_comparison_message',
  ): ValidatorFn {
    return pipe(
      (control: AbstractControl): ValidationErrors | null => {
        const password: string = control.get(passwordFieldName).value;
        const repeatPassword: string = control
          .get(repeatPasswordFieldName).value;

        return password !== repeatPassword
          ? { confirm: true }
          : null;
      },
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  getTruePatternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid
        ? null
        : error;
    };
  }

  getFalsePatternValidator(regex: RegExp, error: ValidationErrors):
    ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid
        ? error
        : null;
    };
  }

  minLengthPure(
    minLength: number,
    errorTipSlug = 'validation.min_length.default_message',
  ): ValidatorFn {
    return pipe(
      Validators.minLength(minLength),
      addErrorTip(this.translationService.t(errorTipSlug)),
    );
  }

  getOperatorFirstNameValidators(): ValidatorFn[] {
    return [
      this.notBlank(),
      this.maxLength(MAX_FIRST_NAME_LENGTH),
    ];
  }

  getOperatorLastNameValidators(): ValidatorFn[] {
    return [
      this.notBlank(),
      this.maxLength(MAX_LAST_NAME_LENGTH),
    ];
  }

  orValidator(validator1: ValidatorFn, validator2: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const result1 = validator1(control);
      const result2 = validator2(control);

      if (result1 === null || result2 === null) {
        return null;
      }

      return { ...result1, ...result2 };
    };
  }

  atLeastOneExistsValidator(controlNames: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = controlNames.some((name) => !!control.get(name).value);

      return result
        ? null
        : { noValues: true };
    };
  }
}

export function addErrorTip(errorTip: string): ValidatorFn {
  return (errors: ValidationErrors) => {
    if (errors) {
      errors[ERROR_TIP_PROPERTY_NAME] = errorTip;
    }

    return errors;
  };
}

function removeError(control: AbstractControl, errorKey: string) {
  if (control && control.errors && control.errors[errorKey]) {
    const updatedErrors: any = { ...control.errors };

    delete updatedErrors[errorKey];
    control.setErrors(
      Object.keys(updatedErrors).length
        ? updatedErrors
        : null,
      { emitEvent: false },
    );
  }
}

function addCounterToMaxLengthErrorTip(): ValidatorFn {
  return (errors: ValidationErrors) => {
    if (errors) {
      errors[ERROR_TIP_PROPERTY_NAME]
        += ` ${errors.maxlength.actualLength}/${errors.maxlength.requiredLength}`;
    }

    return errors;
  };
}

function addCounterToMinLengthErrorTip(): ValidatorFn {
  return (errors: ValidationErrors) => {
    if (errors) {
      errors[ERROR_TIP_PROPERTY_NAME]
        += ` ${errors.minlength.actualLength}/${errors.minlength.requiredLength}`;
    }

    return errors;
  };
}
