<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_13283_369732)">
    <path d="M22.6368 3.89196L21.1261 5.07321C19.0663 2.44018 15.8627 0.75 12.2654 0.75C6.05379 0.75 1.02611 5.77232 1.01808 11.9866C1.01004 18.2063 6.04843 23.25 12.2654 23.25C17.1216 23.25 21.26 20.1696 22.835 15.8545C22.8752 15.742 22.8163 15.6161 22.7038 15.5786L21.185 15.0563C21.1321 15.0381 21.0741 15.0414 21.0236 15.0654C20.973 15.0894 20.9339 15.1323 20.9145 15.1848C20.8663 15.3188 20.8127 15.4527 20.7565 15.5839C20.2931 16.6821 19.6288 17.6679 18.7824 18.5143C17.9428 19.3555 16.9487 20.0267 15.8547 20.4911C14.7216 20.9705 13.5136 21.2143 12.2708 21.2143C11.0252 21.2143 9.81986 20.9705 8.68683 20.4911C7.59176 20.0286 6.59733 19.3572 5.75915 18.5143C4.91727 17.6748 4.24678 16.6795 3.78504 15.5839C3.30558 14.4482 3.06183 13.2429 3.06183 11.9973C3.06183 10.7518 3.30558 9.54643 3.78504 8.41072C4.24843 7.3125 4.91272 6.32679 5.75915 5.48036C6.60558 4.63393 7.59129 3.96964 8.68683 3.50357C9.81986 3.02411 11.0279 2.78036 12.2708 2.78036C13.5163 2.78036 14.7216 3.02411 15.8547 3.50357C16.9497 3.96601 17.9442 4.63746 18.7824 5.48036C19.0475 5.74554 19.2966 6.02679 19.527 6.32143L17.9145 7.58036C17.8826 7.60504 17.8583 7.63822 17.8444 7.6761C17.8305 7.71397 17.8276 7.75499 17.836 7.79444C17.8444 7.8339 17.8638 7.87018 17.8919 7.89912C17.92 7.92806 17.9557 7.94848 17.9949 7.95804L22.6984 9.10982C22.8324 9.14196 22.9636 9.04018 22.9636 8.90357L22.985 4.05804C22.9824 3.88125 22.7761 3.78214 22.6368 3.89196Z" fill="#F5F5F5"/>
  </g>
  <defs>
    <clipPath id="clip0_13283_369732">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
