/* eslint-disable no-restricted-globals */
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SideWidgetEventType } from '@app/side-widget/model/side-widget-event-type';

declare function getCusboSideWidgetExtensionStorageEventData(): any;

const TARGET_ORIGIN = '*';

@Injectable({
  providedIn: 'root',
})
export class SideWidgetService {
  private readonly isLoadedInExtension: boolean
    = !!this.getStorageData();

  constructor(
    private router: Router,
  ) {
  }

  getStorageData(): any {
    return getCusboSideWidgetExtensionStorageEventData();
  }

  isInExtension(): boolean {
    return this.isLoadedInExtension;
  }

  shareRouteChanges(): Observable<any> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event): void => this.sendEventToExtension(
        SideWidgetEventType.ROUTE_CHANGED,
        event,
      )),
    );
  }

  reload(url?: string): void {
    this.sendEventToExtension(
      SideWidgetEventType.RELOAD_WIDGET,
      { url },
    );
  }

  sendEventToExtension(type: SideWidgetEventType, payload?: any): void {
    if (this.isLoadedInExtension) {
      parent.postMessage({ type, payload }, TARGET_ORIGIN);
    }
  }
}
