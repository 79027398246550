import { Injectable } from '@angular/core';
import { CHAT_FILTER_CONTROL_NAME } from '../filter-section.config';

@Injectable({ providedIn: 'root' })
export class FiltrationParameterMapper {
  private readonly toFormValueFunctions: Map<string, (value: string) => any>
    = new Map([
      [CHAT_FILTER_CONTROL_NAME.createDate, toDates],
      [CHAT_FILTER_CONTROL_NAME.channelIds, JSON.parse],
      [CHAT_FILTER_CONTROL_NAME.chatOperatorIds, JSON.parse],
    ]);

  private readonly toStringValueFunctions: Map<string, (value: any) => string>
    = new Map([
      [CHAT_FILTER_CONTROL_NAME.createDate, JSON.stringify],
      [CHAT_FILTER_CONTROL_NAME.channelIds, JSON.stringify],
      [CHAT_FILTER_CONTROL_NAME.chatOperatorIds, JSON.stringify],
    ]);

  toFormValue(
    inputParameterName: string,
    stringValue: string,
  ): any {
    const mapFunction = this.toFormValueFunctions.get(inputParameterName);

    return mapFunction
      ? mapFunction(stringValue)
      : stringValue;
  }

  toStringValue(
    inputParameterName: string,
    formValue: any,
  ): string {
    const mapFunction = this.toStringValueFunctions.get(inputParameterName);

    return mapFunction
      ? mapFunction(formValue)
      : String(formValue);
  }
}

function toDates(
  value: string,
): Date[] {
  return JSON.parse(value)
    .map((date) => new Date(Date.parse(date)));
}
