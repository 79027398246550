import { Component } from '@angular/core';

@Component({
  selector: 'app-no-wifi-icon',
  templateUrl: './no-wifi-icon.component.html',
  styleUrls: ['./no-wifi-icon.component.scss'],
})
export class NoWifiIconComponent {

}
