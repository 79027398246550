import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionStatus } from '@app/amplitude-event-service/action.status';
import { MessengerType } from '@app/graphql/graphql';
import { AmplitudeService } from '../amplitude.service';
import { LocalStorageService } from '../local-storage-service';
import { MessengerName } from '../model/old-messenger-name';

@Injectable({
  providedIn: 'root',
})
export class ChannelEventService {
  constructor(
    private amplitudeService: AmplitudeService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) { }

  events = {
    channelAddingStarted: 'channel_adding_started',
    channelAddingCanceled: 'channel_adding_canceled',
    channelAddingCompleted: 'channel_adding_completed',
    signUpChannelsRemoveChannel: 'sign_up_channels_remove_channel',
  };

  sendChannelAddingStartedEvent(messengerType: MessengerType) {
    this.amplitudeService.sendAmplitudeEvent(
      this.events.channelAddingStarted.valueOf(),
      {
        operator_id: this.localStorageService.getCurrentOperatorId(),
        date: new Date(),
        route: this.router.url,
        messenger_type: messengerType,
      },
    );
  }

  sendChannelAddingCanceledEvent(messengerType: MessengerType) {
    this.amplitudeService.sendAmplitudeEvent(
      this.events.channelAddingCanceled.valueOf(),
      {
        operator_id: this.localStorageService.getCurrentOperatorId(),
        date: new Date(),
        route: this.router.url,
        messenger_type: messengerType,
      },
    );
  }

  sendChannelAddingCompletedEvent(
    messengerType: MessengerType,
    status: ActionStatus,
  ): void {
    this.amplitudeService.sendAmplitudeEvent(
      this.events.channelAddingCompleted.valueOf(),
      {
        operator_id: this.localStorageService.getCurrentOperatorId(),
        date: new Date(),
        route: this.router.url,
        messenger_type: messengerType,
        status: status.valueOf(),
        company_id: this.localStorageService.getCompanyId(),
      },
    );
  }

  sendRemoveChannelEvent(messengerType: MessengerName) {
    this.amplitudeService.sendAmplitudeEvent(
      this.events.signUpChannelsRemoveChannel.valueOf(),
      {
        operator_id: this.localStorageService.getCurrentOperatorId(),
        date: new Date(),
        messenger_type: messengerType,
      },
    );
  }

  sendRemoveChannelEventNew(messengerType: MessengerType) {
    this.amplitudeService.sendAmplitudeEvent(
      this.events.signUpChannelsRemoveChannel.valueOf(),
      {
        operator_id: this.localStorageService.getCurrentOperatorId(),
        date: new Date(),
        messenger_type: messengerType,
      },
    );
  }
}
