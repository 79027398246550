import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  distinctUntilChanged, fromEvent, map, Observable,
} from 'rxjs';
import { EVENT_NAME } from '@app/common/util/constants/event-name';
import { Breakpoint } from '../model/breakpoint';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  readonly virtualHeight$: Observable<number>
    = this.getVirtualHeight();

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  get virtualHeight(): number {
    return visualViewport.height;
  }

  isMobile(): boolean {
    return this.isMatched(Breakpoint.MOBILE);
  }

  isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
      && !window.MSStream;
  }

  isMatched(breakpoints: Breakpoint | Breakpoint[]): boolean {
    return this.breakpointObserver.isMatched(breakpoints);
  }

  isMatched$(breakpoints: Breakpoint | Breakpoint[]): Observable<boolean> {
    return this.observe$(breakpoints).pipe(
      map((state: BreakpointState) => state.matches),
      distinctUntilChanged(),
    );
  }

  observe$(
    breakpoints: Breakpoint | Breakpoint[],
  ): Observable<BreakpointState> {
    return this.breakpointObserver.observe(breakpoints);
  }

  private getVirtualHeight(): Observable<number> {
    return fromEvent(visualViewport, EVENT_NAME.resize).pipe(
      map((event: Event) => (event.target as VisualViewport).height),
    );
  }
}
