import {
  Component, Inject,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  combineLatestWith, Observable, switchMap, throwError,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { LoadingService } from '@app/loading/loading.service';
import { FacebookPage } from '@app/model/facebook-page';
import { FacebookUserService } from '@app/facebook-user.service';
import { MessengerName } from '@app/model/old-messenger-name';
import { MetaPermissionService } from '@app/meta-permission.service';
import { ModalWindow } from '@app/sign-up-channels-page/channels/create-channel-buttons/modal-window';
import { ChannelEventService } from '@app/amplitude-event-service/channel-event.service';
import { ActionStatus } from '@app/amplitude-event-service/action.status';
import { ChannelsService } from '@app/settings/channels-page/channels.service';
import {
  ChannelFragment, MessengerType,
} from '@app/graphql/graphql';
import { ErrorService } from '@app/error.service';
import { SentryService } from '@app/sentry.service';
import { BOT_FEATURES } from '@app/model/bot-feature';

@Component({
  selector: 'app-create-instagram-channel',
  templateUrl: './create-instagram-channel.component.html',
  styleUrls: ['./create-instagram-channel.component.scss'],
  providers: [
    LoadingService,
  ],
})
export class CreateInstagramChannelComponent implements ModalWindow {
  pages$: Observable<FacebookPage[]>;

  selectedPage: FacebookPage = null;

  assignToAllOperators = true;

  currentStep = 0;

  savedChannel: ChannelFragment;

  isLoading = false;

  isDisplayCommentsEnabled = true;

  private socialUser = null;

  private facebookUserId: number;

  constructor(
    private authService: SocialAuthService,
    private notificationService: NzNotificationService,
    private facebookUserService: FacebookUserService,
    private metaPermissionService: MetaPermissionService,
    private loadingService: LoadingService,
    private modal: NzModalService,
    private nzMessageService: NzMessageService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private channelEventService: ChannelEventService,
    private channelPageService: ChannelsService,
    private sentryService: SentryService,
    private errorService: ErrorService,
  ) {
  }

  showModal(): void {
    this.modal.closeAll();
    this.modal.create({
      nzTitle: this.translationService.t('connecting-instagram'),
      nzWidth: '52%',
      nzMaskClosable: false,
      nzOnCancel: () => this.closeModal(),
      nzContent: CreateInstagramChannelComponent,
    });
    this.channelEventService.sendChannelAddingStartedEvent(
      MessengerType.Instagram,
    );
  }

  nextStep(): void {
    this.currentStep += 1;
  }

  previousStep(): void {
    this.currentStep -= 1;
  }

  openFacebookWindow() {
    this.loadingService.loadingOn();
    this.metaPermissionService
      .getPermissionsByMessenger(MessengerName.Instagram.toString())
      .subscribe((permissions) => {
        const fbLoginOptions = {
          scope: this.metaPermissionService
            .joinPermissions(permissions),
        };

        this.authService.signIn(
          FacebookLoginProvider.PROVIDER_ID,
          fbLoginOptions,
        ).then((user) => {
          if (user != null) {
            this.socialUser = user;
            this.currentStep += 1;
            this.addPages(user);
          }
        })
          .catch((error) => {
            this.notificationService.create('error', this.translationService.t('connecting-instagram'), error);
            this.loadingService.loadingOff();
            this.channelEventService.sendChannelAddingCompletedEvent(
              MessengerType.Instagram,
              error.status < 500
                ? ActionStatus.BAD_REQUEST
                : ActionStatus.API_ERROR,
            );
          });
      });
  }

  setSelectedPage(page: FacebookPage): void {
    this.selectedPage = page;
  }

  connect(): void {
    const selectedPages = [this.selectedPage];
    const channelToCreate = {
      facebookUserId: this.facebookUserId,
      messengerType: MessengerType.Instagram,
      pageIds: selectedPages.map((page) => String(page.id)),
    };
    const loadingBots$ = this.channelPageService.addMetaChannel(
      channelToCreate,
      this.assignToAllOperators,
    );

    this.loadingService
      .showLoaderUntilCompleted<ChannelFragment[]>(loadingBots$)
      .subscribe({
        next: ([savedChannel]) => {
          this.savedChannel = savedChannel;
          this.currentStep += 1;
          this.channelEventService.sendChannelAddingCompletedEvent(
            MessengerType.Instagram,
            ActionStatus.SUCCESS,
          );
        },
        error: this.handleError.bind(this),
      });
  }

  finish(): void {
    this.closeModal();
    this.nzMessageService.success(this.translationService.t('instagram-channel-created'));
  }

  updateDisplayCommentsFeature() {
    this.isLoading = true;

    let featureSlugs: string[];

    if (this.isDisplayCommentsEnabled) {
      featureSlugs = this.savedChannel.features.map((feature) => feature.slug);
      featureSlugs.push(BOT_FEATURES.displayComments);
    } else {
      featureSlugs = this.savedChannel.features.filter(
        (feature) => feature.slug !== BOT_FEATURES.displayComments,
      ).map((feature) => feature.slug);
    }

    this.channelPageService.updateChannelFeatures(
      {
        channelId: this.savedChannel.id,
        featureSlugs,
      },
    ).pipe(
      catchError((error) => {
        this.nzMessageService.error(this.translationService.t('something-went-wrong'));

        return throwError(error);
      }),
    ).subscribe((features) => {
      this.savedChannel = {
        ...this.savedChannel,
        features,
      };

      this.channelPageService.changeChannelProperties(this.savedChannel);
      this.isLoading = false;
    });
  }

  private handleError(error): void {
    this.sentryService.warn(`Could not create Instagram channel: ${error}`);
    const parsedValue = this.errorService.isJson(error?.message)
      ? JSON.parse(error?.message)
      : error?.message;
    const content = parsedValue?.value || this.translationService.t('something-went-wrong');
    const title = this.translationService.t('unexpected-error');

    this.modal.closeAll();
    this.notificationService.create('error', title, content);
    this.channelEventService.sendChannelAddingCompletedEvent(
      MessengerType.Instagram,
      parsedValue?.status < 500
        ? ActionStatus.BAD_REQUEST
        : ActionStatus.API_ERROR,
    );
  }

  // TODO: move this logic to a separate service
  private addPages(user): void {
    const facebookUserInfo = {
      email: user.email,
      name: user.name,
      firstName: user.firstName,
      lastName: user.lastName,
      avatarUrl: user.photoUrl,
      shortLivedAccessToken: user.authToken,
      userId: user.id,
    };

    this.socialUser = facebookUserInfo;

    const facebookUserId$ = this.facebookUserService.create(facebookUserInfo)
      .pipe(
        catchError((err: Error) => {
          this.currentStep = 3;
          this.notificationService.create('error',
            this.translationService.t('unexpected-error'), this.translationService.t('something-went-wrong'));

          return throwError(() => err);
        }),
      );

    const loadedPages$ = facebookUserId$.pipe(
      tap((fbId: number) => {
        this.facebookUserId = fbId;
      }),
      switchMap((id) => this.facebookUserService.getPages(id)),
    );

    const loadedChannels$ = facebookUserId$.pipe(
      switchMap((id) => this.facebookUserService.getChannels(id, 'ACTIVE', MessengerName.Instagram)),
    );

    const formattedPages$ = loadedPages$.pipe(
      combineLatestWith(loadedChannels$),
      map(([pages, channels]) => pages.map((page) => {
        const correctChannel = channels
          .find(
            (channel) => channel.pageId === page.id.toString(),
          );

        page.isConnected = !!correctChannel;

        return page;
      })),
    );

    this.pages$ = this.loadingService
      .showLoaderUntilCompleted<FacebookPage[]>(formattedPages$);
  }

  private closeModal(): void {
    this.signOutFacebook();
    this.modal.closeAll();
    this.currentStep = 0;
    this.channelEventService.sendChannelAddingCanceledEvent(
      MessengerType.Instagram,
    );
  }

  private signOutFacebook(): void {
    if (this.socialUser) {
      this.authService.signOut();
      this.socialUser = null;
    }
  }
}
