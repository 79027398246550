import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { I18NextModule } from 'angular-i18next';
import { NotEnoughPermissionsComponent } from './not-enough-permissions.component';

@NgModule({
  declarations: [
    NotEnoughPermissionsComponent,
  ],
  imports: [
    CommonModule,
    NzButtonModule,
    I18NextModule,
  ],
  exports: [
    NotEnoughPermissionsComponent,
  ],
})
export class NotEnoughPermissionsModule {}
