import {
  Inject, Injectable, OnDestroy,
} from '@angular/core';
import {
  BehaviorSubject, mergeMap, Observable, Subject,
} from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from '@app/loading/loading.service';
import { ChannelStorageService } from '@app/channel-storage.service';
import { WidgetProperties } from '@app/model/widget-properties';
import { ErrorService } from '@app/error.service';
import { ChannelFragment, WidgetProperty } from '@app/graphql/graphql';
import { ChannelsService } from '../../channels.service';

@Injectable({ providedIn: 'root' })
export class ChannelDetailsService implements OnDestroy {
  private readonly widgetProperties
    = new BehaviorSubject<WidgetProperties>(null);

  private readonly channelFragment
    = new BehaviorSubject<ChannelFragment>(null);

  readonly widgetProperties$ = this.widgetProperties.asObservable();

  channelFragment$: Observable<ChannelFragment>;

  readonly onSaveButtonClickedEvent: Subject<ChannelFragment> = new Subject();

  loadingService: LoadingService;

  private readonly componentIsDestroyed = new Subject<boolean>();

  constructor(
      private channelStorageService: ChannelStorageService,
      private errorService: ErrorService,
      private messageService: NzMessageService,
      @Inject(I18NEXT_SERVICE)
      private translationService: ITranslationService,
      private notificationService: NzNotificationService,
      private channelPageService: ChannelsService,
  ) {
  }

  initNew(
    loadingService: LoadingService,
    initialUrlParams: Observable<Params>,
  ): void {
    this.loadingService = loadingService;
    this.channelFragment$ = initialUrlParams
      .pipe(map((params) => params.id))
      .pipe(
        mergeMap((id) => this.loadChannelByIdNew(id)),
        tap((channel) => {
          if (channel?.widgetProperty) {
            this.widgetProperties.next(
              this.mapToWidgetProperties(channel.widgetProperty),
            );
          }
        }),
      );

    this.channelFragment$ = this.channelFragment.asObservable();
    initialUrlParams.pipe(
      takeUntil(this.componentIsDestroyed),
      map((params) => params.id),
      mergeMap((id) => this.loadChannelByIdNew(id)),
    ).subscribe((channel) => {
      this.channelFragment.next(channel);
      if (channel?.widgetProperty) {
        this.widgetProperties.next(
          this.mapToWidgetProperties(channel.widgetProperty),
        );
      }
    });
  }

  reset(): void {
    this.widgetProperties.next(null);
    this.channelFragment.next(null);
  }

  updateLocalChannel(channel: ChannelFragment): void {
    this.channelFragment.next(channel);
  }

  updateLocalWidgetProperties(properties: WidgetProperties): void {
    this.widgetProperties.next(properties);
  }

  getWidgetProperties(): WidgetProperties {
    return this.widgetProperties.getValue();
  }

  onSaveError(err): void {
    const errorSlug = this.errorService.getErrorInfoSlugs(err);

    this.notificationService.create(
      'error',
      this.translationService.t(errorSlug.title),
      this.translationService.t(errorSlug.description),
    );
  }

  onSaveSuccess(): void {
    this.messageService.success(this.translationService.t('channel.settings.save.success_message'));
  }

  private loadChannelByIdNew(id: string): Observable<ChannelFragment> {
    return this.channelPageService.getAllChannels()
      .pipe(map((channels) => channels
        .find((channel) => channel.id === id)));
  }

  private mapToWidgetProperties(
    widgetProperty: WidgetProperty,
  ): WidgetProperties {
    return JSON.parse(widgetProperty.properties) as WidgetProperties;
  }

  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }
}
