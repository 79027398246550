import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeService } from '@app/amplitude.service';
import { LocalStorageService } from '@app/local-storage-service';

@Injectable({
  providedIn: 'root',
})
export class NetworkRefreshEventService {
  constructor(
    private amplitudeService: AmplitudeService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {
  }

  events = {
    networkRefreshPage: 'network_refresh_page',
  };

  sendNetworkRefreshPageEvent() {
    this.amplitudeService.sendAmplitudeEvent(
      this.events.networkRefreshPage.valueOf(),
      {
        company_id: this.localStorageService.getCompanyId(),
        route: this.router.url,
      },
    );
  }
}
