import { Injectable } from '@angular/core';
import { ROUTE_URL } from './router-url.constants';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  getBaseUrl(activeUrl: string): string {
    return Object.keys(ROUTE_URL).map((key) => ROUTE_URL[key])
      .find((key) => activeUrl.includes(key));
  }
}
