import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  Observable, of,
  Subject, throwError,
} from 'rxjs';
import { Location } from '@angular/common';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  BillingDetail,
  CompanySubscription, PaymentTransaction,
  ShopifyStore, ShopifySubscriptionCreation,
  SubscriptionPlan, SubscriptionRecalculationInfo, SubscriptionStatusType,
} from '@app/graphql/graphql';
import { LoadingService } from '@app/loading/loading.service';
import { ROUTE_URL } from '@app/router-url.constants';
import { ShopifyGqlService } from '@app/gql-service/shopify-gql.service';
import { BillingService } from '@app/gql-service/billing.service';
import { BillingDetailService } from '@app/gql-service/billing-detail.service';

const INITIAL_STEP_INDEX = 0;
const PAYMENT_METHOD_STEP_INDEX = 1;

@Component({
  selector: 'app-shopify-payment-page',
  templateUrl: './shopify-payment-page.component.html',
  styleUrls: ['./shopify-payment-page.component.scss'],
  providers: [LoadingService],
})
export class ShopifyPaymentPageComponent implements OnInit, OnDestroy {
  private componentIsDestroyed = new Subject<boolean>();

  shopifyStores: ShopifyStore[];

  subscriptionPlan: SubscriptionPlan;

  paymentTransaction: PaymentTransaction;

  currentStepIndex: number = INITIAL_STEP_INDEX;

  hasAllBillingDetails = false;

  selectedStore: ShopifyStore;

  switchMessage: Observable<string>;

  recalculationInfo: SubscriptionRecalculationInfo;

  private companySubscription: CompanySubscription;

  private formGroup: FormGroup;

  private billingDetails: BillingDetail[] = [];

  constructor(
    private router: Router,
    private location: Location,
    private loadingService: LoadingService,
    private shopifyGqlService: ShopifyGqlService,
    private billingService: BillingService,
    private billingDetailService: BillingDetailService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private nzMessageService: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.billingDetailService.hasAllBillingDetails()
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe((hasAllBillingDetails) => {
        this.hasAllBillingDetails = hasAllBillingDetails;
        if (this.hasAllBillingDetails) {
          this.currentStepIndex = PAYMENT_METHOD_STEP_INDEX;
        }
      });

    const state = this.location.getState() as PageState;

    if (!state?.subscriptionPlan) {
      this.router.navigate([ROUTE_URL.pageNotFound]);
    }

    this.subscriptionPlan = state.subscriptionPlan;
    const shopifyStoresResult$ = this.shopifyGqlService.getShopifyStores()
      .pipe(
        takeUntil(this.componentIsDestroyed),
        catchError((err) => {
          this.router.navigate([ROUTE_URL.pageNotFound]);

          return throwError(err);
        }),
      );

    this.loadingService.loadingOn();
    shopifyStoresResult$.subscribe((shopifyStores) => {
      if (!shopifyStores || shopifyStores.length === 0) {
        this.router.navigate([ROUTE_URL.pageNotFound]);
      } else {
        this.shopifyStores = shopifyStores;
        this.loadingService.loadingOff();
      }
    });

    this.billingService.getCompanySubscription()
      .subscribe((companySubscription) => {
        this.companySubscription = companySubscription;
      });

    this.paymentTransaction = state.paymentTransaction;
    if (this.paymentTransaction) {
      this.switchMessage = this.loadingService.showLoaderUntilCompleted(
        this.getSubscriptionChangingMessage(
          this.billingService.getSubscriptionRecalculationInfo(
            this.paymentTransaction.id,
          ),
        ),
      );
      this.billingService
        .getSubscriptionRecalculationInfo(this.paymentTransaction.id)
        .pipe(takeUntil(this.componentIsDestroyed))
        .subscribe((value) => {
          this.recalculationInfo = value;
        });
    }
  }

  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.unsubscribe();
    this.loadingService.loadingOff();
  }

  closePayment() {
    this.location.back();
  }

  activate(subscriptionPlanId: string, shopifyStoreId: string) {
    this.loadingService.showLoaderUntilCompleted(
      this.activateSubscription(
        subscriptionPlanId,
        shopifyStoreId,
      ).pipe(takeUntil(this.componentIsDestroyed)),
    ).subscribe((data: ShopifySubscriptionCreation) => {
      window.location.href = data.confirmationUrl;
    });
  }

  activateSubscription(
    subscriptionPlanId: string,
    shopifyStoreId: string,
  ): Observable<ShopifySubscriptionCreation> {
    if (this.canRenewSubscription()) {
      return this.shopifyGqlService.renewShopifySubscription(shopifyStoreId);
    }

    return this.shopifyGqlService.createShopifySubscription(
      this.paymentTransaction.subscription.id,
      shopifyStoreId,
    );
  }

  moveToPaymentMethod() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });

      return;
    }

    this.loadingService.loadingOn();
    this.billingDetailService.createOrUpdateBillingDetails(
      this.formGroup,
      this.billingDetails,
    ).pipe(
      catchError(() => {
        this.nzMessageService.error(
          this.translationService.t('settings.billing.details.update_failed'),
        );

        this.loadingService.loadingOff();

        return of([]);
      }),
    ).subscribe(() => {
      this.hasAllBillingDetails = true;
      this.currentStepIndex = PAYMENT_METHOD_STEP_INDEX;
      this.loadingService.loadingOff();
    });
  }

  onIndexChange(index: number) {
    if (index === PAYMENT_METHOD_STEP_INDEX && !this.hasAllBillingDetails) {
      return;
    }

    this.currentStepIndex = index;
  }

  setFormGroup(formGroup: FormGroup) {
    this.formGroup = formGroup;
  }

  setBillingDetails(billingDetails: BillingDetail[]) {
    this.billingDetails = billingDetails;
  }

  setSelectedStore(shopifyStore: ShopifyStore) {
    this.selectedStore = shopifyStore;
  }

  canRenewSubscription(): boolean {
    return this.companySubscription?.currentStatus?.status
      === SubscriptionStatusType.Active
      && this.companySubscription?.lastStatus?.status
      === SubscriptionStatusType.Cancelled
      && this.companySubscription?.subscriptionPlan?.id
      === this.subscriptionPlan.id;
  }

  getSubscriptionChangingMessage(
    recalculationInfo: Observable<SubscriptionRecalculationInfo>,
  ): Observable<string> {
    return recalculationInfo.pipe(
      map((info: SubscriptionRecalculationInfo) => {
        if (info) {
          return this.translationService.t(
            'settings.billing.subscription_payment.changing_message',
            {
              previous_unused_days: info.previousUnusedDays,
              previous_subscription_plan_name: info.previousSubscriptionPlan
                .name,
              new_additional_days: info.newAdditionalDays,
              new_subscription_plan_name: info.newSubscriptionPlan.name,
            },
          );
        }

        return null;
      }),
    );
  }
}

interface PageState {
  subscriptionPlan: SubscriptionPlan;
  paymentTransaction: PaymentTransaction;
}
