import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { LogotypeComponent } from './logotype.component';

@NgModule({
  declarations: [
    LogotypeComponent,
  ],
  imports: [
    CommonModule,
    NzTypographyModule,
  ],
  exports: [
    LogotypeComponent,
  ],
})
export class LogotypeModule { }
