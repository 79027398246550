import { Injectable } from '@angular/core';
import {
  BehaviorSubject, Observable, tap,
} from 'rxjs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';

export interface FragmentState {
  open: string;
  close: string;
}

@Injectable()
export abstract class OpenCloseOnFragmentChange {
  private readonly isOpen: BehaviorSubject<boolean>
    = new BehaviorSubject(false);

  readonly isOpen$: Observable<boolean>
    = this.isOpen.asObservable();

  private handleOpen: () => void;

  private handleClose: () => void;

  protected constructor(
    private router: Router,
    private readonly fragmentState: FragmentState,
  ) {
  }

  setOpenHandler(handler: () => void): void {
    this.handleOpen = handler;
  }

  setCloseHandler(handler: () => void): void {
    this.handleClose = handler;
  }

  setInitialState(initialState: boolean): void {
    this.isOpen.next(initialState);
  }

  processQueryFragment(
    activatedRoute: ActivatedRoute,
  ): Observable<string> {
    return activatedRoute.fragment
      .pipe(
        tap((fragment: string): void => (fragment === this.fragmentState.open
          ? (this.handleOpen || this.open.bind(this))()
          : (this.handleClose || this.close.bind(this))()
        )),
      );
  }

  switchState(): void {
    if (this.isOpen.value) {
      this.close();

      return;
    }

    this.open();
  }

  open(): void {
    if (!this.isOpen.getValue()) {
      this.updateFragmentByState(true);
    }
  }

  close(): void {
    if (this.isOpen.getValue()) {
      this.updateFragmentByState(false);
    }
  }

  private updateFragmentByState(isOpen: boolean): void {
    const currentUrl: UrlTree = this.router.parseUrl(this.router.url);

    currentUrl.fragment = isOpen
      ? this.fragmentState.open
      : this.fragmentState.close;

    this.router
      .navigateByUrl(currentUrl)
      .finally(() => this.isOpen.next(isOpen));
  }
}
