import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelegramIconComponent } from './telegram-icon.component';

@NgModule({
  declarations: [
    TelegramIconComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TelegramIconComponent,
  ],
})
export class TelegramIconModule { }
