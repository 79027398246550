import { Injectable } from '@angular/core';
import { forkJoin, Observable, tap } from 'rxjs';
import { WebsocketIoService } from '@app/websocket-io.service';
import { WsEventBody } from '@app/model/ws-event-body';

export type WsEventHandler = (body?: WsEventBody) => void;
export interface WsEventHandlers {
  [key: string]: WsEventHandler;
}

@Injectable({
  providedIn: 'root',
})
export class WsEventHandleService {
  constructor(
    private websocketIoService: WebsocketIoService,
  ) {
  }

  handleEvents(handlers: WsEventHandlers): Observable<any> {
    return forkJoin(
      Object.entries(handlers).map(this.toHandlingObservable.bind(this)),
    );
  }

  private toHandlingObservable([eventName, handler]): Observable<any> {
    return this.websocketIoService.listen(eventName).pipe(tap(handler));
  }
}
