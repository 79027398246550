<nz-form-item class="stores-form">
  <nz-radio-group (ngModelChange)="selectStoreOption($event)" [(ngModel)]="selectedStore">
    <div
      class="card"
      *ngFor="let store of stores"
      (click)="selectStoreOption(store)"
    >
      <div class="card-content">
        <div class="store-content">
          <div class="shopify-icon">
            <img src="assets/images/icons/shopify.svg" alt="shopify icon">
          </div>
          <div class="store-info">
            <span nz-typography class="store-name">{{ store.name }}</span>
            <span nz-typography class="store-description">{{ store.domain }}</span>
          </div>
        </div>
        <div class="radio" nz-radio [nzValue]="store"></div>
      </div>
    </div>
    <button
      *ngIf="buttonText"
      class="button"
      nz-button
      nzType="primary"
      (click)="onButtonClickEvent()"
    >
      {{ buttonText }}
    </button>
  </nz-radio-group>
</nz-form-item>
