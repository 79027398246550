import { Injectable } from '@angular/core';
import { SideWidgetEventType } from '@app/side-widget/model/side-widget-event-type';
import { SideWidgetService } from '@app/side-widget/side-widget.service';

@Injectable({
  providedIn: 'root',
})
export class SideWidgetStorage {
  private readonly sideWidgetCookies: Map<string, string>
    = new Map();

  private readonly sideWidgetLocalStorage: Map<string, string>
    = new Map();

  private readonly isLoadedInExtension: boolean
    = this.sideWidgetService.isInExtension();

  constructor(
    private sideWidgetService: SideWidgetService,
  ) {
    if (this.isLoadedInExtension) {
      this.init(this.sideWidgetService.getStorageData());
    }
  }

  saveCookies(cookies: { [key: string]: string }): void {
    Object.entries(cookies).forEach(([key, value]) => {
      this.sideWidgetCookies.set(key, value);
    });

    this.sideWidgetService.sendEventToExtension(
      SideWidgetEventType.SET_COOKIES,
      cookies,
    );
  }

  setLocalStorageValue(localStorage: { [key: string]: string }): void {
    Object.entries(localStorage).forEach(([key, value]) => {
      this.sideWidgetLocalStorage.set(key, value);
    });

    this.sideWidgetService.sendEventToExtension(
      SideWidgetEventType.SET_LOCAL_STORAGE,
      localStorage,
    );
  }

  clearCookie(keys: string[]): void {
    keys.forEach((key) => {
      this.sideWidgetCookies.delete(key);
    });

    this.sideWidgetService.sendEventToExtension(
      SideWidgetEventType.CLEAR_COOKIES,
      { keys },
    );
  }

  clearLocalStorage(keys: string[]): void {
    keys.forEach((key: string): void => {
      this.sideWidgetCookies.delete(key);
    });

    this.sideWidgetService.sendEventToExtension(
      SideWidgetEventType.CLEAR_LOCAL_STORAGE,
      { keys },
    );
  }

  isInitialized(): boolean {
    return this.isLoadedInExtension;
  }

  getCookie(key: string): string {
    return this.sideWidgetCookies.get(key);
  }

  getLocalStorageValue(key: string): string {
    return this.sideWidgetLocalStorage.get(key);
  }

  removeLocalStorageValue(key: string): void {
    this.sideWidgetLocalStorage.delete(key);
    this.sideWidgetService.sendEventToExtension(
      SideWidgetEventType.REMOVE_LOCAL_STORAGE_VALUE,
      { key },
    );
  }

  private init(data: any): void {
    if (data.cookies) {
      Object.entries(data.cookies).forEach(
        ([key, value]) => {
          this.sideWidgetCookies.set(key, value as string || '');
        },
      );
    }

    if (data.localStorage) {
      Object.entries(data.localStorage).forEach(
        ([key, value]) => {
          this.sideWidgetLocalStorage.set(key, value as string || '');
        },
      );
    }
  }
}
