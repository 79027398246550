import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WEBSOCKET_EVENT_TYPE } from '@src/constants/websocket-event-type.constants';
import { WebsocketIoService } from '../websocket-io.service';
import { WsEventBody } from '../model/ws-event-body';
import { OperatorUpdatedWsDto } from '../model/dto/operator/operator-updated-ws-dto';

@Injectable({
  providedIn: 'root',
})
export class OperatorWsService {
  constructor(
    private websocketService: WebsocketIoService,
  ) {
  }

  onOperatorUpdated(): Observable<OperatorUpdatedWsDto> {
    const event = this.websocketService
      .listen(WEBSOCKET_EVENT_TYPE.operatorUpdated);

    return event.pipe(
      map((dto: WsEventBody) => dto.data),
    );
  }
}
