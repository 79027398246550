import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChatTransferStatus } from '@app/model/chat-transfer';
import { ChatTransferInfo } from '@app/model/chat-transfer-info';
import { environment } from '../environments/environment';
import { ChatTransferCreationData } from './model/chat-transfer-creation-data';
import { ChatTransferUpdateData } from './model/chat-transfer-update-data';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class ChatTransferService {
  private baseUrl = environment.apiBaseUrl;

  private chatTransfersUrl = 'chat-transfers';

  private byStatusUrl = '/by-status';

  constructor(private http: HttpClient) {
  }

  create(chatTransfer: ChatTransferCreationData): Observable<ChatTransferInfo> {
    return this.http.post<ChatTransferInfo>(
      this.baseUrl + this.chatTransfersUrl,
      chatTransfer,
      httpOptions,
    );
  }

  update(chatTransferUpdateData: ChatTransferUpdateData): Observable<void> {
    return this.http.patch<void>(
      this.baseUrl + this.chatTransfersUrl,
      chatTransferUpdateData,
      httpOptions,
    );
  }

  getAllByStatusIn(statuses: ChatTransferStatus[]):
      Observable<ChatTransferInfo[]> {
    return this.http.get<ChatTransferInfo[]>(
      this.baseUrl + this.chatTransfersUrl + this.byStatusUrl,
      {
        params: new HttpParams()
          .set('statuses', `${statuses}`),
      },
    );
  }
}
