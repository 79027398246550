export class ChatTransferOperator {
  id: number;

  operatorId: number;

  departmentId: number;

  constructor(
    id: number,
    operatorId: number,
    departmentId: number,
  ) {
    this.id = id;
    this.operatorId = operatorId;
    this.departmentId = departmentId;
  }
}

export enum ChatTransferOperatorStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}
