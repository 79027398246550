import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logotype',
  templateUrl: './logotype.component.html',
  styleUrls: ['./logotype.component.css'],
})
export class LogotypeComponent {
  @Input() width = 120;

  @Input() height = 36;
}
