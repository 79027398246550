import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCode } from '@app/graphql/graphql';

const currencyCodeToSymbolMap: Record<CurrencyCode, string> = {
  [CurrencyCode.Usd]: '$',
};

@Pipe({
  name: 'currencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(currencyCode: CurrencyCode): any {
    return toCurrencySymbol(currencyCode);
  }
}

export function toCurrencySymbol(
  currencyCode: CurrencyCode,
): string {
  return currencyCodeToSymbolMap[currencyCode];
}
