import { ChatTransferOperator } from '@app/model/chat-transfer-operator';
import { ChatStatusName, Operator } from '@app/graphql/graphql';

export class ChatTransfer {
  id: number;

  chatTransferOperators: ChatTransferOperator[];

  transferOperatorId: number;

  status: ChatTransferStatus;

  chatId: number;

  createTime: Date;

  comment: string;

  chatStatusName: ChatStatusName;

  unreadMessagesCounter: number;

  operator: Operator;

  constructor(
    id: number,
    chatTransferOperators: ChatTransferOperator[],
    operatorId: number,
    chatId: number,
    status: ChatTransferStatus,
    createTime: Date,
    operator: Operator,
    chatStatusName = undefined,
    unreadMessagesCounter = 0,
  ) {
    this.id = id;
    this.chatTransferOperators = chatTransferOperators;
    this.transferOperatorId = operatorId;
    this.chatId = chatId;
    this.status = status;
    this.createTime = createTime;
    this.operator = operator;
    this.chatStatusName = chatStatusName;
    this.unreadMessagesCounter = unreadMessagesCounter;
  }
}

export enum ChatTransferStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}
