export function valueify(obj): any[] {
  return Object.values(obj).reduce<any>((res: any[], el: any): any[] => {
    if (Array.isArray(el)) {
      return [...res, ...el.map((item) => valueify(item))];
    }

    if (typeof el === 'object' && el !== null) {
      return [
        ...res,
        ...valueify(
          el,
        ),
      ];
    }

    return [...res, el];
  }, []);
}

export function exists(value: any): boolean {
  return value !== null && value !== undefined;
}

export function orElse<T>(value: T, defaultValue: T): T {
  return exists(value)
    ? value
    : defaultValue;
}
