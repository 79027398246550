import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SolidPaymentModule } from '@solidgate/angular-sdk';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { HttpClientModule } from '@angular/common/http';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { I18NextModule } from 'angular-i18next';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { LogotypeModule } from '@app/logotype/logotype.module';
import { PaymentPageRoutingModule } from '@app/payment/payment-page/payment-page.routing.module';
import { AppUtilModule } from '@app/app-util.module';
import { LoadingModule } from '@app/loading/loading.module';
import { PaymentMethodModule } from '@app/user-card/payment-method.module';
import { InfoMessageCardModule } from '@app/info-message-card/info-message-card.module';
import { SettingsBillingPageModule } from '@app/settings/billing/settings-billing-page.module';
import { PaymentDetailsComponent } from '@app/payment/payment-page/payment-details/payment-details.component';
import { PaymentPageComponent } from './payment-page.component';

@NgModule({
  declarations: [
    PaymentPageComponent,
    PaymentDetailsComponent,
  ],
  imports: [
    HttpClientModule,
    PaymentPageRoutingModule,
    CommonModule,
    NzLayoutModule,
    LogotypeModule,
    NzGridModule,
    NzTypographyModule,
    SolidPaymentModule,
    NzDividerModule,
    NzIconModule,
    AppUtilModule,
    LoadingModule,
    NzButtonModule,
    PaymentMethodModule,
    InfoMessageCardModule,
    I18NextModule,
    NzStepsModule,
    SettingsBillingPageModule,
    NzTagModule,
    NzToolTipModule,
  ],
  exports: [
    PaymentDetailsComponent,
  ],
})
export class PaymentPageModule { }
