import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { I18NextModule } from 'angular-i18next';
import { AttachmentViewMessageComponent } from '@app/attachment-view/message/attachment-view-message.component';
import { AttachmentViewButtonComponent } from '@app/attachment-view/button/attachment-view-button.component';
import {
  AttachmentViewMovableDirective,
} from '@app/attachment-view/directive/attachment-view-movable.directive';
import { AttachmentViewModifiableDirective } from '@app/attachment-view/directive/attachment-view-modifiable.directive';
import { VideoPlayerComponent } from '@app/attachment-view/video-player/video-player.component';
import { LongNameModule } from '@app/common/long-name/long-name.module';
import { ResponsiveModule } from '@app/responsive/responsive.module';
import { LongTextModule } from '@app/common/long-text/long-text.module';
import { AttachmentViewComponent } from './attachment-view.component';

@NgModule({
  declarations: [
    AttachmentViewComponent,
    VideoPlayerComponent,
    AttachmentViewMessageComponent,
    AttachmentViewButtonComponent,
    AttachmentViewMovableDirective,
    AttachmentViewModifiableDirective,
  ],
  exports: [
    AttachmentViewComponent,
    AttachmentViewMessageComponent,
  ],
  imports: [
    CommonModule,
    NzIconModule,
    NzToolTipModule,
    LongNameModule,
    I18NextModule,
    ResponsiveModule,
    LongTextModule,
  ],
})
export class AppAttachmentViewModule { }
