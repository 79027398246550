import { Inject, Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { first, mergeWith } from 'rxjs';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { WebsocketIoMessageService } from '@app/websocket/websocket-io-message.service';
import { SoundNotificationService } from '@app/service/sound-notification.service';
import { InnerOperatorRequestService } from '@app/gql-service/inner-operator-request/inner-operator-request.service';
import { NotificationCreationService } from '@app/service/notification-creation.service';
import { MessageUtilService } from '@app/util/message-util.service';
import { LocalStorageService } from '@app/local-storage-service';
import { WEBSOCKET_EVENT_TYPE } from '@src/constants/websocket-event-type.constants';
import { WsEventBody } from '@app/model/ws-event-body';
import { WebsocketIoService } from '@app/websocket-io.service';
import { ChatPreviewServiceGql } from '@app/gql-service/chat-preview-service-gql.service';
import { PageRouteService } from '../page-route.service';
import { ResponsiveService } from '../responsive/service/responsive.service';
import {
  ChatPreviewLightFragment, Message, MessageType, Tab,
} from '../graphql/graphql';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private chatPreviewService: ChatPreviewServiceGql,
    private websocketService: WebsocketIoService,
    private messageUtilService: MessageUtilService,
    private pageRouteService: PageRouteService,
    private responsiveService: ResponsiveService,
    private websocketIoMessageService: WebsocketIoMessageService,
    private soundNotificationService: SoundNotificationService,
    private innerOperatorRequestService: InnerOperatorRequestService,
    private notificationCreationService: NotificationCreationService,
    private localStorageService: LocalStorageService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  subscribeOnInvitedChatsNotification(): void {
    this.websocketIoMessageService.onProcessingMessageAll().pipe(
      mergeWith(
        this.websocketIoMessageService.onProcessingMessage(),
        this.websocketIoMessageService.onInboxMessageAll(),
      ),
      filter(this.isValidNotification.bind(this)),
      tap(this.processMessage.bind(this)),
    ).subscribe();

    this.websocketService.listen(
      WEBSOCKET_EVENT_TYPE.chatInvitationRequested,
    ).pipe(
      tap(this.processChat.bind(this)),
    ).subscribe();
  }

  private isValidNotification(message: Message): boolean {
    return !this.pageRouteService.isCurrentChatPage(message.chat.id)
      && this.isMessageValidType(message.messageType);
  }

  private processChat(body: WsEventBody): void {
    this.chatPreviewService.findChatPreviewLightByChatId(body.data.chat.id)
      .subscribe(
        (
          chatPreview: ChatPreviewLightFragment,
        ): void => this.notifyAboutChat(chatPreview, Tab.New),
      );
  }

  private processMessage(message: Message): void {
    const chatId = String(message.chat.id);

    if (
      this.messageUtilService.isPrivateMessage(message)
      && !this.messageUtilService.isMessageFromCurrentOperator(message)
      && this.localStorageService.isCurrentOperator(
        message.chat.chatOperator.operator.id,
      )
    ) {
      this.notify(message, Tab.MyTasks);

      return;
    }

    this.innerOperatorRequestService.isRequestedChat(chatId).pipe(
      first(),
      filter((isRequestedChat: boolean): boolean => isRequestedChat),
    ).subscribe((): void => this.notify(message, Tab.New));

    this.innerOperatorRequestService.isAcceptedChat(chatId).pipe(
      first(),
      filter((isAcceptedChat: boolean): boolean => isAcceptedChat),
    ).subscribe((): void => this.notify(message, Tab.MyTasks));
  }

  private notify(message: Message, tab: Tab): void {
    this.soundNotificationService.playNewMessageSoundIfEnabled();
    this.notificationCreationService.showMessage(message, tab);
  }

  private notifyAboutChat(chat: ChatPreviewLightFragment, tab: Tab): void {
    this.soundNotificationService.playNewChatSoundIfEnabled();
    this.notificationCreationService.show({
      title: chat.title,
      content: this.translationService.t('notification-text.chat-invitation'),
      chatId: chat.id,
    }, tab);
  }

  private isMessageValidType(messageType: MessageType): boolean {
    return messageType === MessageType.Conversation;
  }
}
