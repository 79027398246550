import { Inject, Injectable } from '@angular/core';
import {
  AbstractControl, FormBuilder, FormGroup, ValidatorFn,
} from '@angular/forms';
import { pipe, tap } from 'rxjs';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { addErrorTip, FormValidationService } from '@app/form-validation-service';
import { ErrorTipProviderService } from '@app/service/error-tip-provider.service';
import { ChannelFragment, MessengerProviderName, MessengerType } from '@app/graphql/graphql';
import { ChannelsService } from '@app/settings/channels-page/channels.service';
import { toLowerCaseWithCapitalFirstLetter } from '@app/util/text.util';

export const MAX_CHANNEL_NAME_LENGTH = 100;

@Injectable({
  providedIn: 'root',
})
export class ChannelValidationService {
  private channels: ChannelFragment[] = [];

  constructor(
    private channelsService: ChannelsService,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private errorTipProviderService: ErrorTipProviderService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
    this.initChannels();
  }

  createBaseFormGroupForChannelCreation(
    messengerType: MessengerType,
  ): FormGroup {
    return this.formBuilder.group({
      channelName: [null, [
        this.formValidationService.notBlank(),
        this.formValidationService
          .maxLength(
            MAX_CHANNEL_NAME_LENGTH,
            'sign_up_channels.channel_name.too_long',
          ),
        this.uniqueNameValidator(messengerType),
      ]],
    });
  }

  getErrorMessage(formGroup: FormGroup, controlName: string) {
    return this.errorTipProviderService
      .getErrorTip(formGroup, controlName);
  }

  uniqueNameValidator(
    messengerType: MessengerType,
    messengerProviderName?: MessengerProviderName,
  ): ValidatorFn {
    return pipe(
      (
        control: AbstractControl,
      ): { [key: string]: any } | null => {
        if (
          this.isChannelExists(
            control.value?.trim() || '',
            messengerType,
            messengerProviderName,
          )
        ) {
          return { not_unique: true, error: true };
        }

        return null;
      },
      addErrorTip(
        this.translationService.t(
          'settings.channel.name.not_unique',
          { messenger: toLowerCaseWithCapitalFirstLetter(messengerType) },
        ),
      ),
    );
  }

  uniqueNameValidatorForExistingChannel(
    channelId: string,
    messengerType: MessengerType,
    messengerProviderName?: MessengerProviderName,
  ): ValidatorFn {
    return pipe(
      (
        control: AbstractControl,
      ): { [key: string]: any } | null => {
        if (
          this.isChannelExistsExcludingChannelId(
            channelId,
            control.value?.trim() || '',
            messengerType,
            messengerProviderName,
          )
        ) {
          return { not_unique: true, error: true };
        }

        return null;
      },
      addErrorTip(
        this.translationService.t(
          'settings.channel.name.not_unique',
          { messenger: toLowerCaseWithCapitalFirstLetter(messengerType) },
        ),
      ),
    );
  }

  private isChannelExists(
    name: string,
    messengerType: MessengerType,
    messengerProviderName: MessengerProviderName,
  ): boolean {
    return !!this.channels.find(
      (channel) => channel.name.toLowerCase() === name.toLowerCase()
        && channel.messenger.type === messengerType
        && channel.messengerProvider?.name === messengerProviderName,
    );
  }

  private isChannelExistsExcludingChannelId(
    channelId: string,
    name: string,
    messengerType: MessengerType,
    messengerProviderName: MessengerProviderName,
  ): boolean {
    return !!this.channels.find(
      (channel) => channel.name.toLowerCase() === name.toLowerCase()
        && channel.messenger.type === messengerType
        && channel.messengerProvider?.name === messengerProviderName
      && channel.id !== channelId,
    );
  }

  private initChannels(): void {
    this.channelsService.getAllChannels()
      .pipe(
        tap(
          (channels) => {
            this.channels = channels;
          },
        ),
      ).subscribe();
  }
}
