<app-loading></app-loading>
<nz-steps [nzCurrent]="currentStep">
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
</nz-steps>

<ng-container *ngIf="currentStep === 0">
  <p class="subtitle">{{ 'fb_channel_link_account_title' | i18nextCap }}</p>
  <p>{{ 'fb_channel_link_account_instruction' | i18nextCap }}</p>
</ng-container>

<ng-container *ngIf="currentStep === 1">
  <p class="subtitle">{{ 'fb_channel_select_page_title' | i18nextCap }}</p>
  <p>{{ 'fb_channel_select_page_instruction' | i18nextCap }}</p>
  <app-select-from-list [pages]="pages$ | async"
                        (selectedElement)="setSelectedPage($event)">
  </app-select-from-list>
  <label
    class="assign-all-box"
    nz-checkbox
    [(ngModel)]="assignToAllOperators">
    {{'assign.all.members.to.this.channel' | i18nextCap}}
  </label>
</ng-container>

<ng-container *ngIf="currentStep === 2">
  <div class="subtitle">
    {{ 'instagram-step6-first-subtitle' | i18next }}
  </div>
  <div class="instruction">
    <p>{{ 'instagram-step6-first-instruction-title' | i18next }}</p>
  </div>
  <div class="subtitle subtitle--switch">
    <span>{{ 'instagram-step6-second-subtitle' | i18next }}</span>
    <nz-switch
      [ngModel]="true"
      (ngModelChange)="updateDisplayCommentsFeature($event)"
      [nzLoading]="isCommentFeatureSwitchLoading$ | async"
    >
    </nz-switch>
  </div>
  <div class="instruction">
    <p>{{ 'instagram-step6-second-instruction-title' | i18next }}</p>
  </div>
</ng-container>

<div *nzModalFooter>
  <ng-container *ngIf="currentStep === 0">
    <button class="footer_btn"
            (click)="openFacebookWindow()"
            nz-button
            nzType="primary">
      <i nz-icon nzType="facebook" nzTheme="fill"></i>{{ 'login-with-facebook' | i18nextCap }}
    </button>
  </ng-container>
  <ng-container *ngIf="currentStep === 1">
    <button class="footer_btn"
            (click)="addChannel()"
            nz-button
            nzType="primary"
            [disabled]="selectedPage === null">
      {{ 'connect' | i18nextCap }}
    </button>
  </ng-container>

  <ng-container *ngIf="currentStep === 2">
    <button
      class="footer_btn"
      nz-button
      nzType="primary"
      (click)="finish()"
    >
      {{ 'instagram-step6-finish-button' | i18next }}
    </button>
  </ng-container>
</div>
