import {
  AfterViewInit, Component, OnDestroy, OnInit,
} from '@angular/core';
import {
  BehaviorSubject, first, mergeMap,
  Observable, Subject, Subscription, tap, withLatestFrom,
} from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import {
  CreateWhatsAppChannelComponent,
} from '@app/sign-up-channels-page/channels/create-channel-buttons/meta-channels/create-whatsapp-channel/create-whats-app-channel.component';
import { WidgetChannel, WidgetProperties } from '@app/model/widget-properties';
import { SettingsFieldsValidationService } from '@app/settings-fields-validation.service';
import { ChannelLinkGenerationService } from '@app/channel-link-generation.service';
import { CanComponentDeactivate } from '@app/can-deactivate-guard';
import { getOrDefault } from '@app/util/array.util';
import {
  CreateFacebookChannelComponent,
} from '@app/sign-up-channels-page/channels/create-channel-buttons/meta-channels/create-facebook-channel/create-facebook-channel.component';
import {
  CreateInstagramChannelComponent,
} from '@app/sign-up-channels-page/channels/create-channel-buttons/meta-channels/create-instagram-channel/create-instagram-channel.component';
import { ChannelFragment, MessengerType } from '@app/graphql/graphql';
import { ModalWindow } from '@app/sign-up-channels-page/channels/create-channel-buttons/modal-window';
import { CustomValidators } from '@app/util/custom-validators';
import { AppInfoMessageStyle } from '@app/info-message-card/info-message-card.component';
import { WidgetPropertiesGqlService } from '@app/widget-properties-gql.service';
import { TRUE_OBSERVABLE } from '@app/util/rxjs.util';
import {
  CreateTelegramChannelComponent,
} from '@app/sign-up-channels-page/channels/create-channel-buttons/create-telegram-channel/create-telegram-channel.component';
import {
  CreateViberChannelComponent,
} from '@app/sign-up-channels-page/channels/create-channel-buttons/create-viber-channel/create-viber-channel.component';
import { ChannelsService } from '../../channels.service';
import { InfoMessageService } from '../service/info-message.service';
import { ChannelDetailsService } from '../service/channel-details.service';
import {
  LeaveSettingsConfirmationModal,
} from '../../modal/leave-settings-confirmation-modal.service';
import {
  WidgetPreviewService,
} from '../widget-preview/widget-preview.service';
import { InfoMessageTab } from '../channel-details-page.component';
import { WidgetChannelsMapperService } from './widget-channels-mapper.service';
import {
  AbstractInputField, ChannelOption, ChannelsPageInputField, FieldType,
} from '../../models';

export const SWITCH_CONTROL_APPENDIX = 'Switch';
export const SWITCH_CONTROL_NAME = `liveChat${SWITCH_CONTROL_APPENDIX}`;

@Component({
  selector: 'app-widget-channels-page',
  templateUrl: './widget-channels-page.component.html',
  styleUrls: ['./widget-channels-page.component.scss', '../style/channel-details-common-style.scss'],
  providers: [
    LeaveSettingsConfirmationModal,
  ],
})
export class WidgetChannelsPageComponent
implements OnInit, OnDestroy, CanComponentDeactivate, AfterViewInit {
  private readonly isComponentDestroyed = new Subject<boolean>();

  private readonly fields = new BehaviorSubject<ChannelsPageInputField[]>([]);

  readonly fields$ = this.fields.asObservable();

  value: boolean;

  formGroup: FormGroup;

  readonly CURRENT_TAB = InfoMessageTab.CHANNELS;

  // TODO: replace string values with translates
  private readonly messengersNameViewMap: Map<MessengerType, string> = new Map([
    [MessengerType.Telegram, 'Telegram'],
    [MessengerType.Viber, 'Viber'],
    [MessengerType.Instagram, 'Instagram'],
    [MessengerType.Facebook, 'Facebook'],
    [MessengerType.Whatsapp, 'Whatsapp'],
  ]);

  private readonly messengerCreationModalMap = new Map<string, ModalWindow>([
    [MessengerType.Telegram, this.createTelegramChannel],
    [MessengerType.Viber, this.createViberChannel],
    [MessengerType.Facebook, this.createFacebookChannel],
    [MessengerType.Instagram, this.createInstagramChannel],
    [MessengerType.Whatsapp, this.createWhatsAppChannel],
  ]);

  private readonly FORM_GROUP_INITIALISATION_OPTIONS = {
    required: true,
    validators: [CustomValidators.atLeastOneSwitchOn],
  };

  private widgetPropertiesSubscription: Subscription;

  private switchesValidationSubscription: Subscription;

  private formGroupValueChangesSubscription: Subscription;

  constructor(
    public channelDetailsService: ChannelDetailsService,
    private channelPageService: ChannelsService,
    private widgetPropertiesGqlService: WidgetPropertiesGqlService,
    private fieldsValidationService: SettingsFieldsValidationService,
    private leaveConfirmationModal: LeaveSettingsConfirmationModal,
    private channelLinkGenerationService: ChannelLinkGenerationService,
    private widgetChannelsMapper: WidgetChannelsMapperService,
    private createTelegramChannel: CreateTelegramChannelComponent,
    private createViberChannel: CreateViberChannelComponent,
    private createFacebookChannel: CreateFacebookChannelComponent,
    private createInstagramChannel: CreateInstagramChannelComponent,
    private createWhatsAppChannel: CreateWhatsAppChannelComponent,
    private widgetPreviewService: WidgetPreviewService,
    public infoMessageService: InfoMessageService,
  ) {
  }

  ngOnInit(): void {
    this.channelDetailsService.onSaveButtonClickedEvent
      .pipe(takeUntil(this.isComponentDestroyed))
      .subscribe((channel) => this.onSaveButtonClicked(channel));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.channelPageService.getAllChannels().pipe(
        takeUntil(this.isComponentDestroyed),
      ).subscribe((channels) => {
        this.widgetPropertiesSubscription?.unsubscribe();
        this.widgetPropertiesSubscription
          = this.channelDetailsService.widgetProperties$
            .pipe(
              filter((properties) => !!properties),
              withLatestFrom(this.channelDetailsService.channelFragment$),
            )
            .subscribe((
              [properties, channelFragment],
            ) => {
              const fields = this.getFields(properties, channels);

              this.fields.next(fields);
              this.formGroup = this.fieldsValidationService
                .init(
                  toFieldList(fields),
                  this.FORM_GROUP_INITIALISATION_OPTIONS,
                );

              this.widgetPreviewService.init(
                properties,
                channelFragment?.wordingSettings?.onlineWelcomeMessage?.text
              || channelFragment?.wordingSettings
                ?.offlineWelcomeMessage?.text,
              );
              this.formGroupValueChangesSubscription
              = this.updateWidgetPreviewOnChanges(this.formGroup);

              this.showErrorMessageIfThereAreNoActiveSwitches(
                this.formGroup,
              );
            });
      });
    });
  }

  canDeactivate(): Observable<boolean> {
    return !this.fieldsValidationService.isInvalid()
      ? this.leaveConfirmationModal.show()
      : TRUE_OBSERVABLE;
  }

  private updateWidgetPreviewOnChanges(formGroup: FormGroup): Subscription {
    return formGroup.valueChanges
      .pipe(
        takeUntil(this.isComponentDestroyed),
        mergeMap(() => this.channelDetailsService.loadingService
          .showLoaderUntilCompleted(this.getUpdatedProperties(
            this.widgetChannelsMapper.toUpdatedDataDto(formGroup),
          ).pipe(
            tap((updatedProperties) => this
              .widgetPreviewService.setChannels(
                updatedProperties.channels,
                updatedProperties.liveChatEnabled,
              )),
          ))),
      ).subscribe();
  }

  addChannel(fieldName: string): void {
    this.messengerCreationModalMap.get(fieldName).showModal();
  }

  ngOnDestroy(): void {
    this.widgetPropertiesSubscription?.unsubscribe();
    this.switchesValidationSubscription?.unsubscribe();
    this.isComponentDestroyed.next(true);
    this.isComponentDestroyed.unsubscribe();

    this.fieldsValidationService.destroy();
    this.infoMessageService.clearByTab(this.CURRENT_TAB);
    this.widgetPreviewService.hide();
  }

  private onSaveButtonClicked(channel: ChannelFragment) {
    this.channelDetailsService.loadingService
      .showLoaderUntilCompleted(
        this.getUpdatedProperties(
          this.widgetChannelsMapper.toUpdatedDataDto(this.formGroup),
        ).pipe(mergeMap((updatedProperties) => this
          .saveProperties(
            channel.id,
            channel.widgetProperty.slug,
            updatedProperties,
          ))),
      ).subscribe({
        next: (result) => this.onSaveSuccess(result),
        error: (err) => this.channelDetailsService.onSaveError(err),
      });
  }

  private getUpdatedProperties(
    updatedDataDto: UpdatedDataDto,
  ): Observable<WidgetProperties> {
    return this.channelLinkGenerationService.generate(updatedDataDto.channelIds)
      .pipe(
        map((link) => this.widgetChannelsMapper
          .toWidgetChannels(link, this.formGroup)),
        withLatestFrom(this.channelDetailsService.widgetProperties$),
        map(([channels, properties]) => ({
          title: properties.title,
          language: properties.language,
          liveChatEnabled: updatedDataDto.isLiveChatEnabled,
          channels,
          view: properties.view,
        })),
      );
  }

  private saveProperties(
    channelId: string,
    slug: string,
    updatedProperties: WidgetProperties,
  ): Observable<WidgetProperties> {
    return this.widgetPropertiesGqlService
      .updateWidgetProperties(
        channelId,
        slug,
        JSON.stringify(updatedProperties),
      ).pipe(map(() => updatedProperties));
  }

  private onSaveSuccess(
    properties: WidgetProperties,
  ): void {
    this.channelPageService.getAllChannels().pipe(
      first(),
    ).subscribe((channels) => {
      const fields = this.getFields(
        properties,
        channels,
      );

      this.fields.next(fields);
      this.channelDetailsService.updateLocalWidgetProperties(properties);
      this.formGroup = this.fieldsValidationService.init(
        toFieldList(fields),
        this.FORM_GROUP_INITIALISATION_OPTIONS,
      );

      this.formGroupValueChangesSubscription.unsubscribe();
      this.formGroupValueChangesSubscription
        = this.updateWidgetPreviewOnChanges(this.formGroup);

      this.showErrorMessageIfThereAreNoActiveSwitches(this.formGroup);

      this.channelDetailsService.onSaveSuccess();
    });
  }

  private getFields(
    properties: WidgetProperties,
    channels: ChannelFragment[],
  ): ChannelsPageInputField[] {
    return [
      getSwitchField(isActiveOrDefault(properties.liveChatEnabled)),
      ...this.getDisplayingChannelFields()
        .map((displayingChannel) => this.getFieldByChannel(
          displayingChannel,
          this.findWidgetChannelByField(properties.channels, displayingChannel),
          this.getChannelOptions(channels, displayingChannel),
        )),
    ];
  }

  private getDisplayingChannelFields(): DisplayingChannelField[] {
    return [
      { messenger: MessengerType.Telegram, type: FieldType.SELECT_PLUS_SWITCH },
      { messenger: MessengerType.Viber, type: FieldType.SELECT_PLUS_SWITCH },
      {
        messenger: MessengerType.Whatsapp,
        type: FieldType.SELECT_PLUS_SWITCH,
      },
      { messenger: MessengerType.Facebook, type: FieldType.SELECT_PLUS_SWITCH },
      {
        messenger: MessengerType.Instagram,
        type: FieldType.SELECT_PLUS_SWITCH,
      },
    ];
  }

  private getFieldByChannel(
    displayingChannel: DisplayingChannelField,
    widgetChannel: WidgetChannel,
    channels: ChannelFragment[],
  ): ChannelsPageInputField {
    const channelName = this.messengersNameViewMap
      .get(displayingChannel.messenger);

    const options = channels.map((option) => ({
      id: Number(option.id),
      name: option.name,
    } as ChannelOption));

    switch (displayingChannel.type) {
      case FieldType.COMING_SOON:
        return {
          title: channelName,
          name: displayingChannel.messenger,
          type: displayingChannel.type,
          sourceValue: -1,
        };
      default:
        return {
          title: channelName,
          name: displayingChannel.messenger,
          switch: {
            name: `${displayingChannel.messenger}${SWITCH_CONTROL_APPENDIX}`,
            sourceValue: widgetChannel
              ? isActiveOrDefault(widgetChannel.active)
              : false,
          },
          type: options.length === 0
            ? FieldType.NO_OPTION
            : displayingChannel.type,
          sourceValue: widgetChannel?.id || options[0]?.id || -1,
          placeHolder: 'No channel selected',
          channelOptions: options,
        };
    }
  }

  private findWidgetChannelByField(
    savedWidgetChannels: WidgetChannel[],
    field: DisplayingChannelField,
  ): WidgetChannel {
    return savedWidgetChannels
      .find((channel) => channel.messenger.valueOf()
        === field.messenger.valueOf());
  }

  private getChannelOptions(
    channels: ChannelFragment[],
    field: DisplayingChannelField,
  ): ChannelFragment[] {
    return channels
      .filter((channel) => channel.messenger.type.valueOf()
        === field.messenger.valueOf());
  }

  private showErrorMessageIfThereAreNoActiveSwitches(
    formGroup: FormGroup,
  ): void {
    this.switchesValidationSubscription?.unsubscribe();
    this.switchesValidationSubscription = formGroup.statusChanges
      .subscribe((status) => {
        if (status === 'INVALID'
          && this.formGroup.hasError('allSwitchesOff')) {
          this.infoMessageService.add({
            text: 'At least one channel must be added to the widget.',
            style: AppInfoMessageStyle.ERROR,
            tab: InfoMessageTab.CHANNELS,
          });
        } else {
          this.infoMessageService.clearByTab(this.CURRENT_TAB);
        }
      });
  }
}

function getSwitchField(isActive: boolean): ChannelsPageInputField {
  return {
    title: 'Live chat',
    isActive,
    name: SWITCH_CONTROL_NAME,
    sourceValue: isActive,
    type: FieldType.SWITCH_ONLY,
  };
}

function isActiveOrDefault(isActive: boolean) {
  return getOrDefault<boolean>(isActive, true);
}

function toFieldList(
  channelFields: ChannelsPageInputField[],
): AbstractInputField[] {
  return [
    ...channelFields,
    ...channelFields
      .filter((channelField) => !!channelField.switch)
      .map((channelField) => channelField.switch),
  ];
}

export interface DisplayingChannelField {
  messenger: MessengerType;
  type: FieldType;
}

export interface UpdatedDataDto {
  channelIds: number[];
  isLiveChatEnabled: boolean;
}
