export const LOCAL_STORAGE_KEY = {
  skipSignUpChannels: 'skipSignUpChannels',
  successRegistration: 'successRegistration',
  companyId: 'companyId',
  companyName: 'companyName',
  roles: 'roles',
  operator: 'operator',
  selectedDate: 'selectedDate',
  newTabChatOrder: 'new_tab_chat_order',
  language: 'language',
};
