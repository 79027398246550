import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailIconComponent } from './mail-icon.component';

@NgModule({
  declarations: [
    MailIconComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MailIconComponent,
  ],
})
export class MailIconModule { }
