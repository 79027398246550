import { Component } from '@angular/core';

@Component({
  selector: 'app-update-data-icon',
  templateUrl: './update-data-icon.component.html',
  styleUrls: ['./update-data-icon.component.scss'],
})
export class UpdateDataIconComponent {

}
