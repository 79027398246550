import {
  HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor,
  HttpRequest, HttpResponse, HttpStatusCode,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SentryService } from '@app/sentry.service';
import { ROUTE_URL } from '@app/router-url.constants';

@Injectable()
export class ErrorStatusInterceptor implements HttpInterceptor {
  constructor(
    private sentryService: SentryService,
    private router: Router,
    private modal: NzModalService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 404) {
          this.sentryService.warn(error);
          this.router.navigate([ROUTE_URL.pageNotFound]);
        }

        if (error.status === 402) {
          this.modal.closeAll();
          this.router.navigate([ROUTE_URL.subscriptionPlans]);

          return of(new HttpResponse({ status: HttpStatusCode.NoContent }));
        }

        return throwError(error);
      }),
    );
  }
}

export const httpStatusInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorStatusInterceptor, multi: true },
];
