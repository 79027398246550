import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {
  Observable, ReplaySubject,
} from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { FeatureFlag, FeatureStatus } from './model/feature-flag';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private baseUrl = environment.apiBaseUrl;

  private featureFlagsAppendix = 'feature-flags';

  private featureFlagsMap = new ReplaySubject<Map<string, string>>(1);

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {
    this.downloadFeatureFlags().subscribe();
  }

  isEnabled(name: string): Observable<boolean> {
    return this.checkStatus(name, FeatureStatus.ENABLED);
  }

  isDisabled(name: string): Observable<boolean> {
    return this.checkStatus(name, FeatureStatus.DISABLED);
  }

  checkStatus(name: string, checkedStatus : FeatureStatus) {
    return this.featureFlagsMap.pipe(map((featureFlags) => {
      const status = featureFlags.get(name);

      const statusFromQuery = this.activatedRoute.snapshot
        .queryParamMap
        .get(name);

      return statusFromQuery === checkedStatus
          || status === checkedStatus;
    }));
  }

  downloadFeatureFlags(): Observable<FeatureFlag[]> {
    return this.findAll()
      .pipe(tap((featureFlags) => {
        const localMap = new Map<string, string>();

        featureFlags.forEach((featureFlag) => {
          localMap.set(featureFlag.name, featureFlag.status);
        });

        this.featureFlagsMap.next(localMap);
      }));
  }

  private findAll(): Observable<FeatureFlag[]> {
    return this.http.get<FeatureFlag[]>(`${this.baseUrl}${this.featureFlagsAppendix}`)
      .pipe(shareReplay());
  }
}
