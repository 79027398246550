import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { InputNumberControlComponent } from './input-number-control.component';

@NgModule({
  declarations: [
    InputNumberControlComponent,
  ],
  exports: [
    InputNumberControlComponent,
  ],
  imports: [
    ReactiveFormsModule,
    NzButtonModule,
    FormsModule,
    NzToolTipModule,
  ],
})
export class InputNumberControlModule { }
