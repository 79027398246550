import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import {
  I18NEXT_SERVICE,
  I18NextLoadResult,
  ITranslationService,
} from 'angular-i18next';
import HttpApi from 'i18next-http-backend';
// eslint-disable-next-line camelcase
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { i18nextOptions } from './i18n.config';

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
  {
    provide: NZ_I18N,
    useValue: en_US,
  },
];

export function localeIdFactory(): string {
  return i18nextOptions.lng;
}

export function appInit(
  i18next: ITranslationService,
): () => Promise<I18NextLoadResult> {
  return () => i18next
    .use(HttpApi)
    .init(i18nextOptions);
}
