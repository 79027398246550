import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SECURITY_TOKEN } from '@src/constants/security-token.constants';
import {
  SideWidgetStorage,
} from '@app/side-widget/side-widget.storage';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(
    private cookieService: CookieService,
    private sideWidgetStorage: SideWidgetStorage,
  ) {
  }

  signOut(): void {
    if (this.sideWidgetStorage.isInitialized()) {
      this.sideWidgetStorage.clearCookie([
        SECURITY_TOKEN.access,
        SECURITY_TOKEN.refresh,
      ]);
    } else {
      this.cookieService.delete(SECURITY_TOKEN.access, '/');
      this.cookieService.delete(SECURITY_TOKEN.refresh, '/');
    }
  }

  saveTokenPair(accessToken: string, refreshToken: string): void {
    if (this.sideWidgetStorage.isInitialized()) {
      this.sideWidgetStorage.saveCookies({
        [SECURITY_TOKEN.access]: accessToken,
        [SECURITY_TOKEN.refresh]: refreshToken,
      });
    } else {
      this.cookieService.delete(SECURITY_TOKEN.access, '/');
      this.cookieService.delete(SECURITY_TOKEN.refresh, '/');
      this.cookieService.set(SECURITY_TOKEN.access, accessToken, 1, '/');
      this.cookieService.set(SECURITY_TOKEN.refresh, refreshToken, 1, '/');
    }
  }

  getAccessToken(): string {
    return this.sideWidgetStorage.isInitialized()
      ? this.sideWidgetStorage.getCookie(SECURITY_TOKEN.access)
      : this.cookieService.get(SECURITY_TOKEN.access);
  }

  getRefreshToken(): string {
    return this.sideWidgetStorage.isInitialized()
      ? this.sideWidgetStorage.getCookie(SECURITY_TOKEN.refresh)
      : this.cookieService.get(SECURITY_TOKEN.refresh);
  }

  hasRefreshToken(): boolean {
    return !!this.getRefreshToken();
  }
}
