import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChatTransferService } from '@app/chat-transfer.service';
import { ChatTransferOperator } from '@app/model/chat-transfer-operator';
import { ChatTransferInfo } from '@app/model/chat-transfer-info';
import { ChatPreviewFragment } from '@app/graphql/graphql';
import { ChatTransfer, ChatTransferStatus } from './model/chat-transfer';

@Injectable({
  providedIn: 'root',
})
export class TransferredChatsStorage {
  private transferredChatsMapSubject =
      new BehaviorSubject<Map<number, ChatTransfer>>(
        new Map<number, ChatTransfer>(),
      );

  readonly transferredChatsMap$
    = this.transferredChatsMapSubject.asObservable();

  private transferredChatsByAdminIds: string[] = [];

  constructor(
    private chatTransferService: ChatTransferService,
  ) {
  }

  loadTransferredChatsMap(): Observable<Map<number, ChatTransfer>> {
    const chatTransfers$ = this.chatTransferService.getAllByStatusIn(
      [ChatTransferStatus.PROCESSING, ChatTransferStatus.REJECTED],
    );

    return chatTransfers$.pipe(
      map((chatTransfers) => {
        const transferredChats = new Map<number, ChatTransfer>();

        chatTransfers?.forEach((chatTransferInfo) => {
          const chatTransfer = this.getChatTransfer(chatTransferInfo);

          transferredChats.set(chatTransfer.chatId, chatTransfer);
        });

        return transferredChats;
      }), tap((dataMap) => this.transferredChatsMapSubject.next(dataMap)),
    );
  }

  addChat(chatTransfer: ChatTransfer) {
    if (this.transferredChatsMapSubject?.value.get(chatTransfer.chatId)) {
      return;
    }

    const chatsMap = new Map(this.transferredChatsMapSubject.value);

    chatsMap.set(chatTransfer.chatId, chatTransfer);

    this.transferredChatsMapSubject.next(chatsMap);
  }

  removeChat(chatId: number) {
    const chatsMap = new Map(this.transferredChatsMapSubject.value);

    chatsMap.delete(chatId);

    this.transferredChatsMapSubject.next(chatsMap);
  }

  removeChatById(chatId: number) {
    const chatsMap = new Map(this.transferredChatsMapSubject.value);

    chatsMap.delete(chatId);

    this.transferredChatsMapSubject.next(chatsMap);
  }

  updateChatTransferStatus(chatTransfer: ChatTransfer) {
    const chatsMap = new Map(this.transferredChatsMapSubject.value);

    const oldChatTransfer = chatsMap.get(chatTransfer.chatId);

    oldChatTransfer.status = chatTransfer.status;

    this.transferredChatsMapSubject.next(chatsMap);
  }

  getChatTransferByChatId(chatId: number): ChatTransfer {
    return this.transferredChatsMapSubject.value.get(chatId);
  }

  getObservableChatTransferByChatId(chatId: number): Observable<ChatTransfer> {
    return this.transferredChatsMap$.pipe(
      map((transferredChats) => transferredChats.get(chatId)),
    );
  }

  isChatTransferredObservable(chatId: number): Observable<boolean> {
    return this.transferredChatsMap$.pipe(
      map((transferredChats) => this.isChatTransferredInMap(
        transferredChats,
        chatId,
      )),
    );
  }

  isChatTransferred(chatId: number): boolean {
    return this.transferredChatsMapSubject.value.has(chatId);
  }

  isChatTransferredToOperator(chatId: number, operatorId: number): boolean {
    if (!this.isChatTransferred(chatId)) {
      return false;
    }

    return this.transferredChatsMapSubject.value.get(chatId)
      .chatTransferOperators
      .some((chatTransferOperator) => chatTransferOperator
        .operatorId === operatorId);
  }

  isChatTransferredToOperatorObservable(
    chatId: number,
    operatorId: number,
  ): Observable<boolean> {
    return this.transferredChatsMap$.pipe(
      map(
        (transferredChats) => transferredChats.get(chatId)
          ?.chatTransferOperators
          .some((chatTransferOperator) => chatTransferOperator
            .operatorId === operatorId) || false,
      ),
    );
  }

  getLastIndexOfTransferredChatPreview(
    chatPreviews: ChatPreviewFragment[],
  ): number {
    return chatPreviews
      .map((chatPreview) => this.isChatTransferredInMap(
        this.transferredChatsMapSubject.getValue(),
        Number(chatPreview.id),
      )).lastIndexOf(true);
  }

  private isChatTransferredInMap(
    transferredChats: Map<number, ChatTransfer>,
    chatId: number,
  ): boolean {
    return transferredChats.get(chatId)?.status
      === ChatTransferStatus.PROCESSING;
  }

  isChatRejected(chatId: number): Observable<boolean> {
    return this.transferredChatsMap$.pipe(
      map((transferredChats) => transferredChats.get(chatId)?.status
          === ChatTransferStatus.REJECTED),
    );
  }

  isChatTransferredByOperator(
    chatId: number, operatorId: number,
  ): Observable<boolean> {
    return this.transferredChatsMap$.pipe(
      map(
        (transferredChats) => {
          const chatTransfer = transferredChats.get(chatId);

          return chatTransfer
            && chatTransfer.transferOperatorId === operatorId
            && !chatTransfer.chatTransferOperators
              .some((chatTransferOperator) => chatTransferOperator
                .operatorId === operatorId);
        },
      ),
    );
  }

  moveChatByAdmin(chatId: string): void {
    this.transferredChatsByAdminIds.push(chatId);
  }

  isMovedChatByAdmin(chatId: string): boolean {
    return this.transferredChatsByAdminIds
      .some((currentChatId) => currentChatId === chatId);
  }

  removeChatFromMovedByAdmin(chatId: string): void {
    this.transferredChatsByAdminIds = this.transferredChatsByAdminIds
      .filter((currentChatId) => currentChatId !== chatId);
  }

  private getChatTransfer(chatTransferInfo: ChatTransferInfo): ChatTransfer {
    const chatTransferOperators = chatTransferInfo.transferOperators.map(
      (chatTransferOperatorInfo) => new ChatTransferOperator(
        chatTransferOperatorInfo.id,
        chatTransferOperatorInfo.operatorId,
        chatTransferOperatorInfo.departmentId,
      ),
    );

    return new ChatTransfer(
      chatTransferInfo.id,
      chatTransferOperators,
      chatTransferInfo.operatorId,
      chatTransferInfo.chatId,
      chatTransferInfo.status,
      chatTransferInfo.createTime,
      chatTransferInfo.operator,
      chatTransferInfo.unreadMessagesCounter,
    );
  }
}
