import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { MetaPermission } from './model/meta-permission';

@Injectable({
  providedIn: 'root',
})
export class MetaPermissionService {
  private baseUrl = environment.apiBaseUrl;

  private metaPermissionAppendix = 'meta-permissions';

  constructor(private http: HttpClient) {}

  getPermissionsByMessenger(
    messengerName: string,
  ): Observable<Array<MetaPermission>> {
    return this.http.get<Array<MetaPermission>>(`${this.baseUrl}${this.metaPermissionAppendix}`,
      { params: new HttpParams().set('messengerType', `${messengerName}`) });
  }

  joinPermissions(metaPermissions: Array<MetaPermission>): string {
    return metaPermissions
      .map((metaPermission) => metaPermission.name)
      .join(',');
  }
}
