import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing } from '@sentry/tracing';
import { init, routingInstrumentation } from '@sentry/angular-ivy';
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';
import './extensions';

if (environment.production) {
  enableProdMode();
}

if (environment.sentry.enabled === 'true') {
  init({
    environment: environment.env,
    dsn: environment.sentry.dsn,
    sampleRate: Number(environment.sentry.sampleRate),
    tracesSampleRate: Number(environment.sentry.tracesSampleRate),
    release: environment.sentry.release,
    maxBreadcrumbs: 40,
    maxValueLength: 10000,
    beforeSend(event) {
      event.user = {
        id: localStorage.getItem('operator') || undefined,
      };

      return event;
    },
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [environment.ip, /^\//],
        routingInstrumentation,
      }),
    ],
    ignoreErrors: [
      'An error occurred while retrieving token: FirebaseError',
      /Firebase push notification: token is null for operator with id.*/,
      /connect_error due to xhr poll error.*/,
      /connect_error due to undefined.*/,
      /Can't verify code.*/,
      /An error occurred while retrieving token: ReferenceError: Can't find variable: Notification.*/,
      /cusbo.error-message.subscription-required.title*/,
      /Не вдається підтвердити*/,
      /Невозможно проверить код*/,
      /Non-Error promise rejection captured with keys*/,
    ],
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
});
