import { Component, Input } from '@angular/core';
import { PaymentMethodInfo } from '@app/model/payment-method-info';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent {
  @Input() paymentMethodInfo: PaymentMethodInfo;
}
