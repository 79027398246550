export const EMPTY_STRING = '';

export function isNumber(value: string): boolean {
  return typeof value === 'number' || !Number.isNaN(Number(value));
}

export function nonBlank(value: string): boolean {
  return value?.length > 0;
}

export function truncate(value: string, maxLength: number): string {
  return value?.length > maxLength
    ? `${value.slice(0, maxLength)}...`
    : value;
}

export function toLowerCaseWithCapitalFirstLetter(value: string) {
  return value?.length > 0
    ? `${value.slice(0, 1).toUpperCase()}${value.slice(1).toLowerCase()}`
    : '';
}
