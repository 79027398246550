import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

@Component({
  selector: 'app-info-message-card',
  templateUrl: './info-message-card.component.html',
  styleUrls: ['./info-message-card.component.scss'],
})
export class InfoMessageCardComponent implements OnInit {
  @Input() style: AppInfoMessageStyle = AppInfoMessageStyle.WARNING;

  @Input() buttonText: string;

  @Output() buttonClick = new EventEmitter<any>();

  private readonly iconTypeMap = new Map<AppInfoMessageStyle, string>();

  constructor(private card: ElementRef) {
    this.iconTypeMap.set(AppInfoMessageStyle.ERROR, 'close-circle');
    this.iconTypeMap.set(AppInfoMessageStyle.WARNING, 'info-circle');
    this.iconTypeMap.set(AppInfoMessageStyle.INFO, 'info-circle');
  }

  ngOnInit(): void {
    this.card.nativeElement.firstChild
      .classList.add(this.style);
  }

  getIconType(): string {
    return this.iconTypeMap.get(this.style);
  }
}

export enum AppInfoMessageStyle {
  WARNING='warning', ERROR='error', INFO='info'
}
