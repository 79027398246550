<div *ngIf="(connectionData$ | async ) as connectionData" class="wifi-box">
  <div class="wifi-icon">
      <app-no-wifi-icon *ngIf="connectionData.isOffline"></app-no-wifi-icon>
      <app-update-data-icon *ngIf="!connectionData.isOffline"></app-update-data-icon>
  </div>
  <div class="wifi-text-box">
    <p class="wifi-header">{{ connectionData.titleSlug | i18next }}</p>
    <p class="wifi-text">{{ connectionData.detailsSlug | i18next }}</p>
  </div>
  <div *ngIf="!connectionData.isOffline" class="refresh-button">
    <a nz-button (click)="refreshPage()" nzType="link">{{ 'internet_status_indicator.refresh' | i18next }}</a>
  </div>
</div>
