import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SubscriptionPlan, SubscriptionPlanPeriod } from '../../graphql/graphql';
import {
  PERIOD_COST_TO_TRANSLATION_CODE, PLAN_DURATION_TO_PLURAL_TRANSLATION_CODE,
  PLAN_DURATION_TO_SINGULAR_TRANSLATION_CODE,
} from './billing.constants';

@Injectable({
  providedIn: 'root',
})
export class TranslationUtilService {
  constructor(
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
  }

  getPeriodText(period: SubscriptionPlanPeriod): string {
    return this.translationService.t(PERIOD_COST_TO_TRANSLATION_CODE[period]);
  }

  getSubscriptionPlanDurationText(subscriptionPlan: SubscriptionPlan): string {
    if (subscriptionPlan.duration === 1) {
      return this.translationService.t(
        PLAN_DURATION_TO_SINGULAR_TRANSLATION_CODE[subscriptionPlan.period],
        { duration: subscriptionPlan.duration },
      );
    }

    return this.translationService.t(
      PLAN_DURATION_TO_PLURAL_TRANSLATION_CODE[subscriptionPlan.period],
      { duration: subscriptionPlan.duration },
    );
  }
}
