export enum SideWidgetEventType {
  STORAGE_DATA = 'STORAGE_DATA',
  GET_STORAGE_DATA = 'GET_STORAGE_DATA',
  SET_LOCAL_STORAGE = 'SET_LOCAL_STORAGE',
  SET_COOKIES = 'SET_COOKIES',
  CLEAR_COOKIES = 'CLEAR_COOKIES',
  CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE',
  REMOVE_LOCAL_STORAGE_VALUE = 'REMOVE_LOCAL_STORAGE_VALUE',

  UPDATE_UNREAD_CHATS_COUNTER = 'UPDATE_UNREAD_CHATS_COUNTER',
  ROUTE_CHANGED = 'ROUTE_CHANGED',
  RELOAD_WIDGET = 'RELOAD_WIDGET',
}
