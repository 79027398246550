import { Injectable } from '@angular/core';
import { ERROR_SLUG } from '@src/constants/error-slug.constants';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  readonly titleDescriptionMap = new Map<string, string>(
    [
      [ERROR_SLUG.channelDuplicateTitle, 'cusbo.error-message.channel-duplicate.description'],
      [ERROR_SLUG.channelInvalidBotToken, 'cusbo.error-message.invalid-bot-token.description'],
      [ERROR_SLUG.eChatChannelCreationError, 'e_chat.channel_creation.error'],
    ],
  );

  getErrorInfoSlugByValue(value: string): string {
    return this.titleDescriptionMap.get(value) || 'something-went-wrong';
  }

  getErrorInfoSlugs(err): {title: string; description: string} {
    const defaultError = { title: 'unexpected-error', description: 'something-went-wrong' };

    if (err.error.value instanceof Array) {
      const errorTitle = err.error.value[0];

      return this.titleDescriptionMap.has(errorTitle)
        ? {
          title: errorTitle,
          description: this.titleDescriptionMap.get(errorTitle),
        }
        : defaultError;
    }

    return defaultError;
  }

  getErrorInfoSlugsGql(errorInfo): { title: string; description: string } {
    const defaultError = { title: 'unexpected-error', description: 'something-went-wrong' };
    const errorSlug = errorInfo.value;

    return this.titleDescriptionMap.has(errorSlug)
      ? {
        title: errorSlug,
        description: this.titleDescriptionMap.get(errorSlug),
      }
      : defaultError;
  }

  public getOutboundErrorStatus(error): string {
    return error.graphQLErrors[0]?.extensions?.status;
  }

  public isOutboundError(error) {
    return error.graphQLErrors
      && error.graphQLErrors[0]?.extensions?.status;
  }

  public isOutboundErrorWithStatus(error, status) {
    return this.isOutboundError(error)
      && this.getOutboundErrorStatus(error) === status;
  }

  public isJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }
}
