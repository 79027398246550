import { Injectable } from '@angular/core';
import { combineLatest, first, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  InnerOperatorRequest,
  InnerOperatorRequestFilter,
  InnerOperatorRequestsGQL,
  InnerOperatorRequestStatus,
  InviteToChatGQL,
  InviteToChatInput,
  ResetAcceptedInviteCounterGQL,
  UpdateInvitationStatusGQL,
  UpdateInvitationStatusInput,
} from '@app/graphql/graphql';
import { exists } from '@app/util/object.util';

@Injectable({
  providedIn: 'root',
})
export class InnerOperatorRequestService {
  constructor(
    private inviteToChatGQL: InviteToChatGQL,
    private updateInvitationStatusGQL: UpdateInvitationStatusGQL,
    private innerOperatorRequestGQL: InnerOperatorRequestsGQL,
    private resetAcceptedInviteCounterGQL: ResetAcceptedInviteCounterGQL,
  ) {
  }

  isRequestedOrAcceptedChat(chatId: string): Observable<boolean> {
    return combineLatest([
      this.isRequestedChat(chatId),
      this.isAcceptedChat(chatId),
    ]).pipe(
      map(([isRequested, isAccepted]) => isRequested || isAccepted),
    );
  }

  isRequestedChat(chatId: string): Observable<boolean> {
    return this.getInnerOperatorRequests(
      InnerOperatorRequestStatus.Requested,
    ).pipe(
      map((
        innerOperatorRequests: InnerOperatorRequest[],
      ) => innerOperatorRequests.some(
        (request: InnerOperatorRequest): boolean => request.chat.id === chatId,
      )),
    );
  }

  isAcceptedChat(chatId: string): Observable<boolean> {
    return this.getInnerOperatorRequests(
      InnerOperatorRequestStatus.Accepted,
    ).pipe(
      map((
        innerOperatorRequests: InnerOperatorRequest[],
      ) => innerOperatorRequests.some(
        (request: InnerOperatorRequest): boolean => request.chat.id === chatId,
      )),
    );
  }

  findRequestedChat(chatId: string): Observable<InnerOperatorRequest> {
    return this.getInnerOperatorRequests(
      InnerOperatorRequestStatus.Requested,
    ).pipe(
      map((
        innerOperatorRequests: InnerOperatorRequest[],
      ) => innerOperatorRequests.find(
        (request: InnerOperatorRequest): boolean => request.chat.id === chatId,
      )),
      filter(exists),
    );
  }

  findAcceptedChat(chatId: string): Observable<InnerOperatorRequest> {
    return this.getInnerOperatorRequests(
      InnerOperatorRequestStatus.Accepted,
    ).pipe(
      map((
        innerOperatorRequests: InnerOperatorRequest[],
      ) => innerOperatorRequests.find(
        (request: InnerOperatorRequest): boolean => request.chat.id === chatId,
      )),
    );
  }

  getInnerOperatorRequests(
    status: InnerOperatorRequestStatus,
  ): Observable<InnerOperatorRequest[]> {
    return this.innerOperatorRequestGQL.watch({ status }).valueChanges.pipe(
      map(
        (
          result,
        ) => result.data.innerOperatorRequests as InnerOperatorRequest[],
      ),
    );
  }

  getInnerOperatorRequestsFiltered(
    status: InnerOperatorRequestStatus,
    innerOperatorRequestFilter: InnerOperatorRequestFilter,
  ): Observable<InnerOperatorRequest[]> {
    return this.innerOperatorRequestGQL.fetch(
      {
        status,
        filter: innerOperatorRequestFilter,
      },
      { fetchPolicy: 'no-cache' },
    ).pipe(
      map(
        (
          result,
        ) => result.data.innerOperatorRequests as InnerOperatorRequest[],
      ),
    );
  }

  inviteToChat(
    input: InviteToChatInput,
  ): Observable<InnerOperatorRequest[]> {
    return this.inviteToChatGQL.mutate({ input }).pipe(
      map((result) => result.data.inviteToChat as InnerOperatorRequest[]),
    );
  }

  updateInvitationStatus(
    input: UpdateInvitationStatusInput,
  ): Observable<InnerOperatorRequest[]> {
    return this.updateInvitationStatusGQL.mutate(
      { input },
    ).pipe(
      map((result) => result.data
        .updateInvitationStatus as InnerOperatorRequest[]),
    );
  }

  resetAcceptedInviteCounter(chatId: string): Observable<boolean> {
    return this.resetAcceptedInviteCounterGQL.mutate(
      { chatId },
    ).pipe(
      map((result) => result.data.resetAcceptedInviteCounter as boolean),
    );
  }

  onLoaded(
    status: InnerOperatorRequestStatus,
  ): Observable<InnerOperatorRequest[]> {
    return this.innerOperatorRequestGQL.watch(
      { status },
      { fetchPolicy: 'cache-only' },
    ).valueChanges.pipe(
      map((result) => result.data
        .innerOperatorRequests as InnerOperatorRequest[]),
      filter(exists),
      first(),
    );
  }
}
