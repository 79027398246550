<div data-testid="payment-method-info" class="payment-method">
  <img class="payment-method___icon"
       nz-tooltip
       [src]="paymentMethodInfo.iconPath"
       [alt]="paymentMethodInfo.iconAlt">
  <div class="payment-method__info-box">
    <div class="payment-method__info-box__title">
      <span data-testid="payment-method-info-title">{{ paymentMethodInfo.title }}</span>
    </div>
    <div class="payment-method__info-box__details">
      <span data-testid="payment-method-info-details">
        {{ paymentMethodInfo.details }}
      </span>
    </div>
  </div>
</div>
