<button
  nz-button
  class="copy-code-button"
  nzType="primary"
  [class.copy-code-button--copied]="isCopied$ | async"
  (click)="copy()"
>
  <span nz-icon nzTheme="outline" [nzType]="iconName$ | async"></span>
  {{ (buttonText$ | async) | i18next }}
</button>
