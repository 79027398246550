import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

const CLICK_EVENT_NAME = 'click';
const ACTIVE_CLASS_NAME = 'active';
const HOVER_DISABLED_CLASS_NAME = 'hover-disabled';
const ENABLED_CLASS_NAME = 'enabled';
const DISABLED_CLASS_NAME = 'disabled';

@Component({
  selector: 'app-circle-button',
  styleUrls: [
    '../button.component.scss',
    'circle-button.component.scss',
  ],
  template: '<ng-content></ng-content>',
})
export class CircleButtonComponent implements OnInit {
  @Output() appClick = new EventEmitter<MouseEvent>();

  @Input() appDisabled = false;

  @Input() set isHooverDisabled(isHooverDisabled: boolean) {
    if (isHooverDisabled) {
      this.host.nativeElement.classList.add(HOVER_DISABLED_CLASS_NAME);
    } else {
      this.host.nativeElement.classList.remove(HOVER_DISABLED_CLASS_NAME);
    }
  }

  @Input() set isDisabled(isDisabled: boolean) {
    if (isDisabled) {
      this.host.nativeElement.classList.remove(ENABLED_CLASS_NAME);
      this.host.nativeElement.classList.add(DISABLED_CLASS_NAME);
    } else {
      this.host.nativeElement.classList.remove(DISABLED_CLASS_NAME);
      this.host.nativeElement.classList.add(ENABLED_CLASS_NAME);
    }
  }

  @Input() set isActive(isActive: boolean) {
    if (isActive) {
      this.host.nativeElement.classList.add(ACTIVE_CLASS_NAME);
    } else {
      this.host.nativeElement.classList.remove(ACTIVE_CLASS_NAME);
    }
  }

  constructor(private host: ElementRef) {}

  ngOnInit(): void {
    this.host.nativeElement
      .addEventListener(
        CLICK_EVENT_NAME,
        this.propagateClickEvent.bind(this),
      );
  }

  private propagateClickEvent(event: MouseEvent): void {
    if (this.appDisabled) {
      return;
    }

    this.appClick.emit(event);
  }
}
