import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { ShopifyStore } from '@app/graphql/graphql';

@Component({
  selector: 'app-shopify-store-list',
  templateUrl: './shopify-store-list.component.html',
  styleUrls: ['./shopify-store-list.component.scss'],
})
export class ShopifyStoreListComponent implements OnInit {
  @Input() stores: ShopifyStore[];

  @Input() buttonText: string;

  @Input() selectedStore: ShopifyStore;

  @Output() selectedStoreEmitter: EventEmitter<ShopifyStore>
    = new EventEmitter<ShopifyStore>();

  @Output() selectedStoreToActivateEmitter: EventEmitter<ShopifyStore>
    = new EventEmitter<ShopifyStore>();

  ngOnInit() {
    const selectedStore = this.selectedStore
      ? this.selectedStore
      : this.stores.find(Boolean);

    this.selectStoreOption(selectedStore);
  }

  selectStoreOption(store: ShopifyStore) {
    this.selectedStore = store;
    this.selectedStoreEmitter.emit(store);
  }

  onButtonClickEvent() {
    this.selectedStoreToActivateEmitter.emit(this.selectedStore);
  }
}
